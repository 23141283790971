import { Button, Modal, Spin } from "antd";
import NiceModal, { antdModal, useModal } from "@ebay/nice-modal-react";
import { useEffect, useState } from "react";
import httpclient from "../service/httpclient";
import TencentCloudDrmVideoPlayer from "../components/PlayVideo/TencentCloudDrmVideoPlayer";

const VIDEO_PROVIDER_TENCENT_CLOUD_DRM = "tencent-cloud-drm";

export default NiceModal.create(({ title, videoAccessId }) => {
  const [videoInfo, setVideoInfo] = useState(null);
  const modal = useModal();
  const CloseModalButton = (
    <Button
      onClick={() => {
        return modal.hide();
      }}
    >
      关闭
    </Button>
  );

  useEffect(() => {
    httpclient.getVideoPlayInfo(videoAccessId).then((res) => {
      setVideoInfo(res.video);
    });
  }, []);

  function renderVideoPlayer(videoInfo) {
    switch (videoInfo.provider) {
      case VIDEO_PROVIDER_TENCENT_CLOUD_DRM:
        return <TencentCloudDrmVideoPlayer {...videoInfo} />;
      default:
        throw new Error(`unsupported video provider ${videoInfo.provider}`);
    }
  }

  return (
    <Modal
      {...antdModal(modal)}
      title={title}
      centered
      width={1000}
      maskClosable={false}
      footer={[CloseModalButton]}
    >
      {!!videoInfo ? renderVideoPlayer(videoInfo) : <Spin />}
    </Modal>
  );
});
