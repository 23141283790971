import { createContext, useEffect, useState, useMemo } from "react";
import httpclient from "../../../../service/httpclient";
import { notification } from 'antd';

const ResumeContext = createContext({})

function ResumeProvider(props) {
  const [data, setData] = useState({});
  const info = data.personal_info ? JSON.parse(data.personal_info) : null;
  const education = data.education_background ? JSON.parse(data.education_background) : null;
  const project = data.project_experience ? JSON.parse(data.project_experience) : null;
  const assessment = data.self_assessment ? JSON.parse(data.self_assessment) : null;

  /*
  实现效果：
  1)点击'完善简历'，将会 *遍历* 所有还 *未填写* 的简历模块对应的表单
  2) *已填写* 的模块右上角将出现'编辑'，点击后 *仅* 出现相应模块的表单
  实现方法：
  1)有内容的模块，表单提交后应返回view页
  2)无内容的模块，表单提交后应跳转至下一个无内容的模块，若不存在，则返回view页
  根据上述规则构造每个表单页的urlAfterSave
  将首个无内容模块对应表单的url额外存入first字段中，作为'完善简历'按钮的跳转url
  */
  const resumeBaseUrl = '/user/center/my-resume/'
  const urlList = [
    {endpoint: 'info', hasContent: !!info},
    {endpoint: 'education', hasContent: !!education},
    {endpoint: 'project', hasContent: !!project},
    {endpoint: 'assessment', hasContent: !!assessment},
    {endpoint: 'view', hasContent: true},
  ];
  const urlInfo = useMemo(()=>{
    const result = {};
    urlList.reduceRight((accumulator, currentValue) => {
      const name = currentValue.endpoint;
      const infoItem = {};
      infoItem.selfUrl = resumeBaseUrl + name;
      infoItem.urlAfterSave = resumeBaseUrl + (currentValue.hasContent ? 'view' : accumulator.endpoint);
      result[name] = infoItem;
      return currentValue.hasContent ? accumulator : currentValue;
    })
    result.first = resumeBaseUrl + urlList.find(item => !item.hasContent)?.endpoint;
    return result;
  }, [!!info, !!education, !!project, !!assessment] );

  const getData = async () => {
    const res = await httpclient.getUserResume();
    setData(res);
  }

  const postData = async (fieldName, values) => {
    await httpclient.updateUserResume(fieldName, values);
    notification.success({message: '成功', description: '信息保存成功', key: 'form success'});
    await getData();
  }

  const value = { info, education, project, assessment, postData, urlInfo };

  useEffect(() => {
    getData();
  }, [])

  return <ResumeContext.Provider value={value}>{props.children}</ResumeContext.Provider>
}

export { ResumeProvider, ResumeContext }