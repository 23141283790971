import { Form, Input, Button, notification, Select, Space, InputNumber } from 'antd';
import { NavLink, useHistory } from 'react-router-dom';
import useResumeService from '../../../../../../service/hooks/useResumeService'

function SelfAssessmentForm() {
  const history = useHistory();
  const service = useResumeService();
  const data = service.assessment;
  const urlInfo = service.urlInfo.assessment;

  const onFinish = (values) => {
    console.log('Success:', values);
    service.postData('self_assessment', values).then(() => {
      history.location.search === '?type=complete' ? history.push(urlInfo.urlAfterSave) : history.push('/user/center/my-resume/view');
    })
  };

  return(
    <Form
      labelCol={{ span: 4 }}
      wrapperCol={{ span: 16 }}
      key="assessment"
      name="assessment"
      initialValues={data}
      onFinish={onFinish}
      onFinishFailed={()=>notification.warn({ message: '格式错误', description: '请检查表单内容', key: 'form error'})}
    >

      <Form.Item
        label="英语水平"
        required
        style={{marginBottom: '0'}}
      >
        <Space>

          <Form.Item
            name="english"
            rules={[{ required: true, message: '请选择英语水平!'}]}
          >
            <Select style={{width: '120px'}}>
              <Select.Option value="四级">四级</Select.Option>
              <Select.Option value="六级">六级</Select.Option>
              <Select.Option value="雅思">雅思</Select.Option>
              <Select.Option value="托福">托福</Select.Option>
              <Select.Option value="GRE">GRE</Select.Option>
            </Select>
          </Form.Item>

          <Form.Item
            label="成绩"
            name="score"
            rules={[
              { required: true, message: '请输入相应成绩!'},
              { type: 'number', message: '请输入数字!' }
            ]}
          >
            <InputNumber />
          </Form.Item>
          
        </Space>
      </Form.Item>

      <Form.Item
        label="擅长软件"
        name="software"
        rules={[{ required: true, message: '请填写擅长软件!'}]}
      >
        <Input.TextArea
          showCount
          maxLength={300}
          autoSize
        />
      </Form.Item>

      <Form.Item
        label="个人技能"
        name="skills"
        rules={[{ required: true, message: '请填写个人技能!'}]}
      >
        <Input.TextArea
          showCount
          maxLength={300}
          autoSize
        />
      </Form.Item>

      <Form.Item wrapperCol={{ offset: 7, span: 16 }}>
        <Space>
          <NavLink to='/user/center/my-resume/view'>
            <Button>
              取消
            </Button>
          </NavLink>
          <Button type="primary" htmlType="submit">
            保存{history.location.search === '?type=complete' ? '并进入下一项' : '并返回'}
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
}

export default SelfAssessmentForm;