import { Button, Space, Table, Tag } from "antd";
import { useEffect, useState } from "react";
import httpclient from "../../../service/httpclient";
import useTableColumnSearchProps from "../../../hooks/useTableColumnSearchProps";
import "./RecruitmentQuestionAllQuestionsTable.scss";
import { get } from "lodash";
import {
  CommentOutlined,
  EditOutlined,
  EyeOutlined,
  SyncOutlined,
} from "@ant-design/icons";

function getRandomNIndexes(arrLen, n) {
  const selectedIndexes = new Set();
  while (selectedIndexes.size < n) {
    const randomIndex = Math.floor(Math.random() * arrLen);
    selectedIndexes.add(randomIndex);
  }
  return Array.from(selectedIndexes);
}

function RecruitmentQuestionAllQuestionsTable() {
  const getSearchProps = useTableColumnSearchProps();
  const fixValuesFilterProps = (
    namePath,
    values,
    texts,
    cmp = (a, b) => a === b
  ) => ({
    filters: values.map((v, i) => ({ text: texts ? texts[i] : v, value: v })),
    onFilter: (value, record) => cmp(get(record, namePath), value),
  });

  const navigate = (id) => {
    window.open(
      `/recruitment-questions/${id}`,
      "_blank",
      "noopener,noreferrer"
    );
  };

  const [questionsData, setQuestionsData] = useState(null);
  const [randomNIndexes, setRandomNIndexes] = useState([]);
  const randomSize = 5;
  const fetchQuestionData = () =>
    httpclient.getAllVisibleRecruitmentQuestion().then((res) => {
      setRandomNIndexes(getRandomNIndexes(res.length, randomSize));
      setQuestionsData(res);
    });
  useEffect(() => {
    fetchQuestionData();
  }, []);
  const sortedQuestionsData = questionsData && [
    ...randomNIndexes.map((i) => ({ random: true, ...questionsData[i] })),
    ...questionsData.filter((_, i) => !randomNIndexes.includes(i)),
  ];

  const [allTags, setAllTags] = useState([]);
  useEffect(() => {
    httpclient.getAllKnowledgeTags().then((res) => setAllTags(res));
  }, []);

  const columns = [
    {
      title: "状态",
      dataIndex: "status",
      key: "status",
      width: 60,
      render: (statusList) => (
        <Space>
          {statusList.includes("viewed") && <EyeOutlined />}
          {statusList.includes("answered") && <EditOutlined />}
          {statusList.includes("discussed") && <CommentOutlined />}
        </Space>
      ),
      ...fixValuesFilterProps(
        "status",
        ["discussed", "answered", "viewed", "null"],
        [
          <Space>
            <CommentOutlined />
            参与讨论
          </Space>,
          <Space>
            <EditOutlined />
            有笔记
          </Space>,
          <Space>
            <EyeOutlined />
            已浏览
          </Space>,
          "无",
        ],
        (statusList, status) =>
          status === "null"
            ? statusList.length === 0
            : statusList.includes(status)
      ),
    },
    // {
    //   title: "题号",
    //   dataIndex: "id",
    //   key: "id",
    //   width: 80,
    //   render: (id) => `${id}.`,
    //   ...getSearchProps("id"),
    // },
    {
      title: (
        <Space>
          题目
          <Button
            shape="round"
            size="small"
            onClick={() =>
              setRandomNIndexes(
                getRandomNIndexes(questionsData.length, randomSize)
              )
            }
          >
            <SyncOutlined />
            换一批
          </Button>
        </Space>
      ),
      dataIndex: "title",
      key: "title",
      render: (title, record) => (
        <span>
          {record.id}. <strong>{title}</strong>
        </span>
      ),
      width: 180,
      ellipsis: true,
      ...getSearchProps("title"),
    },
    {
      title: "难度",
      dataIndex: "difficulty",
      key: "difficulty",
      width: 80,
      ...fixValuesFilterProps("difficulty", ["简单", "中等", "困难"]),
    },
    {
      title: "类型",
      dataIndex: "type",
      key: "type",
      width: 80,
      ...fixValuesFilterProps("type", ["笔试", "面试"]),
    },
    {
      title: "题型",
      dataIndex: "format",
      key: "format",
      width: 80,
      ...fixValuesFilterProps("format", ["单选", "多选", "解答"]),
    },
    {
      title: "知识点标签",
      dataIndex: "tags",
      key: "tags",
      width: 240,
      render: (tags) => (
        <Space wrap size={[0, 2]}>
          {tags.map((tag) => (
            <Tag key={tag.id}>{tag.name}</Tag>
          ))}
        </Space>
      ),
      filters: allTags.map((tag) => ({ text: tag.name, value: tag.name })),
      onFilter: (value, record) =>
        record.tags.some((tag) => tag.name === value),
    },
  ];

  return (
    <div className="recruitment-question-all-question-table">
      <Table
        rowKey="id"
        rowClassName={(record) =>
          "question-row" + (record.random ? " random-selected" : "")
        }
        loading={sortedQuestionsData === null}
        onRow={(record) => ({ onClick: () => navigate(record.id) })}
        columns={columns}
        dataSource={sortedQuestionsData}
      />
    </div>
  );
}

export default RecruitmentQuestionAllQuestionsTable;
