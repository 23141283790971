import "./user_center.scss";
import General from "./contents/general/general";
import MyCourses from "./contents/my_courses/my_courses";
import MyQuestions from "./contents/my_questions/my_questions";
import { Route, Switch, useParams } from "react-router";
import { NavLink } from "react-router-dom";
import Messages from "./contents/messages/messages";
import MyResume from "./contents/my_resume/MyResume";
import MyCoupons from "./contents/my_coupons/my-coupons";
import MyDiscuss from "./contents/my_discuss/my_discuss";
import ResumeForm from "./contents/my_resume/forms/resume_form/ResumeForm";
import { ResumeProvider } from "./contents/my_resume/ResumeProvider";
import Gifts from "./contents/my_gifts/my_gifts";
import {useContext, useEffect, useState} from "react";
import httpclient from "../../service/httpclient";
import {MessageContext, UserContext} from "../../App";
import Recruit from "./contents/recruit/recruit";
import RecruitSettings from "./contents/recruit/recruit_pages/settings/recruit_settings";
import RecruitDetail from "./contents/recruit/recruit_pages/recruit_detail/recruit_detail";
import MyRights from "./contents/my_rights/my_rights";

function UserCenter(props) {
  const isOpen = localStorage.hr_key === 'UG1EpyRNpeNKJbNPlSkSCNW1WzoOxuia';
  const selected = useParams().selected;
  const {unreadMessageCnt,delCnt} = useContext(MessageContext);
  const {userInfo,updateUserInfo} = useContext(UserContext);
  const activated = {
    backgroundColor: '#f5faff',
    borderLeft: 'solid 8px #cce3fa',
  };

  return (
    <div className="user-center-wrapper">
      <div className="user-center-sidebar">
        <NavLink to="/user/center/general">
          <span className="user-center-button" style={selected == 'general' ? activated : {} }>
            个人中心
          </span>
        </NavLink>
        <div className="user-center-label">系统</div>
        <NavLink to="/user/center/messages">
          <span className="user-center-button" style={selected == 'messages' ? activated : {} }>
            站内消息
            {unreadMessageCnt !== 0 && <span className="user-center-unread-message-cnt">{unreadMessageCnt >= 99 ? '99+' : unreadMessageCnt}</span>}
          </span>
        </NavLink>
       <div className="user-center-label">权益</div>
        <NavLink to="/user/center/my-rights">
          <span className="user-center-button" style={selected == 'my-rights' ? activated : {} }>
            我的权益
          </span>
        </NavLink>
        <div className="user-center-label">学习</div>
        <NavLink to="/user/center/my-courses">
          <span className="user-center-button" style={selected == 'my-courses' ? activated : {} }>
            我的课程
          </span>
        </NavLink>
        {/*<NavLink to="/user/center/my-questions">*/}
        {/*  <span className="user-center-button" style={selected == 'my-questions' ? activated : {} }>*/}
        {/*    我的提问*/}
        {/*  </span>*/}
        {/*</NavLink>*/}
        <div className="user-center-label">招聘</div>
        {isOpen && <NavLink to="/user/center/recruit">
          <span className="user-center-button" style={selected == 'recruit' ? activated : {} }>
            我的招聘
          </span>
        </NavLink>}
        <NavLink to="/user/center/my-resume/view">
          <span className="user-center-button" style={selected == 'my-resume' ? activated : {} }>
            我的简历
          </span>
        </NavLink>
        {userInfo && (userInfo.id === 21 || userInfo.id === 17 || userInfo.id === 18) && <div className="user-center-label">讨论</div>}
        {userInfo && (userInfo.id === 21 || userInfo.id === 17 || userInfo.id === 18) && <NavLink to="/user/center/my-discuss">
          <span className="user-center-button" style={selected == 'my-discuss' ? activated : {} }>
            我的帖子
          </span>
        </NavLink>}
        <div className="user-center-label">其他</div>
        {/* <NavLink to="/user/center/my-account">
          <span className="user-center-button" style={selected == 'my-account' ? activated : {} }>
            我的账户
          </span>
        </NavLink> */}
        <NavLink to="/user/center/my-coupons">
          <span className="user-center-button" style={selected == 'my-coupons' ? activated : {} }>
            我的优惠
          </span>
        </NavLink>
      </div>
      <ResumeProvider>
        <div className="user-center-content-wrapper">
        <div className="user-center-content">
          <Switch>
            <Route path='/user/center/general' exact children={<General/>} />
            <Route path='/user/center/messages' exact children={<Messages/>} />
            <Route path='/user/center/my-courses' exact children={<MyCourses/>} />
            <Route path='/user/center/my-questions' exact children={<MyQuestions/>} />
            {/* <Route path='/user/center/my-account' exact children={<p>ma</p>} /> */}
            {isOpen && <Route path='/user/center/recruit' exact children={<Recruit />}/>}
            {/*<Route path='/user/center/recruit/settings' exact children={<RecruitSettings/>}/>*/}
            <Route path='/user/center/my-resume/view' exact children={<MyResume/>} />
            <Route path='/user/center/my-resume/:part' exact children={<ResumeForm/>} />
            <Route path='/user/center/my-gifts' exact children={<Gifts/>}/>
            <Route path='/user/center/my-coupons' exact children={<MyCoupons/>}/>
            <Route path='/user/center/my-discuss' exact children={<MyDiscuss/>}/>
            <Route path='/user/center/my-rights' exact children={<MyRights/>}/>
            {isOpen && <Route path='/user/center/recruit/detail/:recruit_id' exact children={<RecruitDetail/>}/>}
          </Switch>
        </div>
        </div>
      </ResumeProvider>
    </div>
  );

}

export default UserCenter;
