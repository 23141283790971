/* global TCPlayer:readonly */
import React, { useEffect, useMemo } from "react";

import "./TencentCloudDrmVideoPlayer.less";

export default (props) => {
  const tcPlayerId = useMemo(
    () => "tc-player-" + Math.random().toString().substr(2)
  );
  useEffect(() => {
    let tcplayerParams = {
      fileID: props.fileId,
      psign: props.psign,
      appID: props.appID,
      posterImage: false,
      autoplay: true,
      hlsConfig: { maxMaxBufferLength: 30 },
    };

    TCPlayer(tcPlayerId, tcplayerParams);
  }, []);

  return (
    <div className={"video-root"}>
      <video id={tcPlayerId} style={{ width: "100%" }} />
    </div>
  );
};
