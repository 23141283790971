import useResizeObserver from "@react-hook/resize-observer";
import { useState } from "react";

function useWindowWidth() {
  const [windowWidth, setWindowWidth] = useState(document.body.clientWidth);
  useResizeObserver(document.body, (entry) =>
    setWindowWidth(entry.contentRect.width)
  );

  const deviceType = windowWidth < 600 ? "mobile" : "pc";

  return { windowWidth, deviceType };
}

export default useWindowWidth;
