const CouponStatus = {
    AVAILABLE:'available',
    USED:'used',
    UNUSED:'unused',
    EXPIRED:'expired',
    LOCKED:'locked'
}

const CouponType = {
    MULTIPLE: 1, //抵扣券（可重复叠加使用）
    SINGE: 2 //优惠券（一次仅能使用一张）
}

const CouponEffect = {
    VOUCHER: 1, //抵扣金额
    DISCOUNT: 2 //打折
}

const RangeType = {
    ALL: 1, //全部分类
    PART: 2, //部分
}
export {CouponStatus,CouponType,CouponEffect,RangeType}
