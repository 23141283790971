import CKEditorReact from "./CKEditor";

function CKEditorAntdFormItem(props) {
  const { id, value, onChange, ...otherProps } = props;

  return (
    <CKEditorReact
      {...otherProps}
      id={id}
      onChange={(_, editor) => onChange?.(editor.getData())}
      data={value}
    />
  );
}

export default CKEditorAntdFormItem;
