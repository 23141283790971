import {Avatar, Empty, Modal, Tooltip} from 'antd';
import './messages.scss'
import React, {useContext, useEffect, useMemo, useState} from "react";
import httpclient from "../../../../service/httpclient";
import moment from "moment";
import {NavLink} from "react-router-dom";
import {MessageContext} from "../../../../App";
import {DeleteOutlined, UserOutlined} from "@ant-design/icons";
import {isSet} from "lodash/lang";

function Messages(){
  function  sortByTime(a,b){
        let timea = moment(a.created_at).format('YYYY-MM-DD HH:mm:ss');
        let timeb = moment(b.created_at).format('YYYY-MM-DD HH:mm:ss');
        if(moment(timea).isBefore(timeb)){
          return 1
        } else return -1;
  }
  const [messages,setMessages] = useState(null);
  const {unreadMessageCnt,delCnt} = useContext(MessageContext);
  useEffect(() => {
    httpclient.getMessages().then((data) => {
        if(data.messages.length){ setMessages(data.messages)}
      delCnt(data.del_cnt);
    })
    // setInterval(() => {
    //   httpclient.getMessages().then((data) => {
    //     setMessages(data);
    //   })
    // },10000);
  },[localStorage.token]);
  const messagelist = useMemo(() => {
    if(messages){
    return messages.sort(sortByTime).map((content) => {
      return <div className="message-item" onMouseOver={() => {
          const deleteDom = document.getElementsByClassName(`message-delete ${content.id}`)[0];
          deleteDom.style.display = 'block';
      }} onMouseOut={() => {
          if(document.body.offsetWidth >= 600){
              const deleteDom = document.getElementsByClassName(`message-delete ${content.id}`)[0];
              deleteDom.style.display = 'none';
          }
      }}>
          <NavLink to={content.link || '/'} style={{display:'flex'}} >
              {content.user_avatar ? <Avatar className="message-user-avatar" size={{
          xs: 32,
          sm: 40,
          md: 48,
          lg: 48,
          xl: 48,
          xxl: 48,
        }} src={content.user_avatar}/>:
                  <Avatar className="message-user-avatar" size={{
                      xs: 32,
                      sm: 40,
                      md: 48,
                      lg: 48,
                      xl: 48,
                      xxl: 48,
                  }} icon={<UserOutlined/>}/>}
        <div className="message-body">
          <div style={{display:'flex', alignItems: 'center'}}>
            <div className="message-user-name">{content.user_name || content.mobile}</div>
            <div className="message-desc">{content.desc}</div>
          </div>
         <div className="message-content">
           {content.type === 'like' && <span style={{color:'darkgery',fontSize:'12px'}}>我的评论：</span>}
           {content.content}
         </div>
          <div className="message-date">
            {moment(content.created_at).format('YYYY-MM-DD HH:mm')}
          </div>
          </div>
          </NavLink>
          <div className={`message-delete ${content.id}`} onClick={() => { Modal.confirm({
              title: "是否确认删除该通知？",
              okType: "primary",
              okText: "确定",
              cancelText: "取消",
              onOk: async function () {
                  httpclient.deleteMessage(content.id).then(() => {
                      window.location.reload();
                  })
              }
          })}}>
              <Tooltip title="删除该通知"><DeleteOutlined/></Tooltip>
          </div>
      </div>
    })}
  },[messages]);
  return (
      <div className="message">
        {messages ?
            <div>
              {messagelist}
            </div>
            : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="暂无消息"/>
        }
      </div>
  );
}

export default Messages;