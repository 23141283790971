import  {useEffect, useState} from "react";
import useResizeObserver from "@react-hook/resize-observer";
import * as React from "react";
import "./pathtree.scss";
import { NavLink } from "react-router-dom";
import routeUrls from "../../route/routeurls";
import categories from "../../pages/categories/categories";
import { Popover } from 'antd';

function useSize(target) {
    const [size, setSize] = React.useState();

    React.useLayoutEffect(() => {
        target && setSize(target.getBoundingClientRect());
    }, [target]);

    useResizeObserver(target, (entry) => setSize(entry.contentRect));
    return size;
}

function Pathtree(type, pathdata,nodeid) {
    const [target, setTarget] = React.useState();
    const size = useSize(target);
    const [judge,setJudge] = useState(null);
    useEffect(() => {
        setJudge(document.getElementById("judge"));
    },[size]);
  if (pathdata) {
    const pathtree = [...pathdata.path].reverse().map((content,index) => (
        index !== 0 && <span>
        <NavLink className="path-name" to={routeUrls("category", content.id)}>
          {content.name}
        </NavLink>{" "}
            {index !==pathdata.path.length - 1 && <span className="right-angle"> > </span>}
      </span>
    ));
    if (type === "categories") {
      return (
        <div>
          <div id="judge" className="path-tree-judge" ref={setTarget}>
              <span>
                 <NavLink
                     className="path-name"
                     to="/"
                 >
                  IC-Design
              </NavLink>
              </span>
              {pathdata.path.length !== 1 && <span className="right-angle"> > </span>}
                {pathtree}
              <span className="right-angle"> > </span>
            <span>
              <NavLink
                className="path-name"
                to={routeUrls("category", nodeid)}
              >
                {pathdata.category.name}
              </NavLink>
            </span>
          </div>
            {!!judge && (judge.offsetHeight <= 50 ?
                <div className="path-tree">
                    <span>
                 <NavLink
                     className="path-name"
                     to="/"
                 >
                  IC-Design
              </NavLink>

              </span>
                    {pathdata.path.length !== 1 && <span className="right-angle"> > </span>}
                    {pathtree}
                    <span className="right-angle"> > </span>
            <span>
              <NavLink
                className="path-name"
                to={routeUrls("category", nodeid)}
              >
                {pathdata.category.name}
              </NavLink>
            </span>
          </div>:
                <div className="path-tree">
                    <span>
                      <NavLink
                          className="path-name"
                          to="/"
                      >
                          IC-Design
                      </NavLink>
                    </span>
                    <span className="right-angle"> > </span>
                    <span className="right-angle" style={{cursor:"pointer"}}><Popover placement="bottom" content={pathtree} trigger="hover">......</Popover></span>
                    <span className="right-angle"> > </span>
                    <span>
                      <NavLink
                          className="path-name"
                          to={routeUrls("category", nodeid)}
                      >
                          {pathdata.category.name}
                      </NavLink>
                    </span>
            </div>)}
        </div>
      );
    } else if (type === "zhuanlans") {
        return (
            <div>
                <div id="judge" className="path-tree-judge" ref={setTarget}>
              <span>
                 <NavLink
                     className="path-name"
                     to="/"
                 >
                  IC-Design
              </NavLink>
              </span>
                    {pathdata.path.length !== 1 && <span className="right-angle"> > </span>}
                    {pathtree}
                    <span className="right-angle"> > </span>
                    <span>
              <NavLink
                  className="path-name"
                  to={routeUrls("zhuanlan", nodeid)}
              >
                {pathdata.zhuanlan.name}
              </NavLink>
            </span>
                </div>
                {!!judge && (judge.offsetHeight <= 50 ?
                    <div className="path-tree">
                    <span>
                 <NavLink
                     className="path-name"
                     to="/"
                 >
                  IC-Design
              </NavLink>
              </span>
                        {pathdata.path.length !== 1 && <span className="right-angle"> > </span>}
                        {pathtree}
                        <span className="right-angle"> > </span>
                        <span>
              <NavLink
                  className="path-name"
                  to={routeUrls("zhuanlan",nodeid)}
              >
                {pathdata.zhuanlan.name}
              </NavLink>
            </span>
                    </div>:
                    <div className="path-tree">
                    <span>
                      <NavLink
                          className="path-name"
                          to="/"
                      >
                          IC-Design
                      </NavLink>
                    </span>
                        <span className="right-angle"> > </span>
                        <span className="right-angle" style={{cursor:"pointer"}}><Popover placement="bottom" content={pathtree} trigger="hover">......</Popover></span>
                        <span className="right-angle"> > </span>
                        <span>
                      <NavLink
                          className="path-name"
                          to={routeUrls("zhuanlan", nodeid)}
                      >
                          {pathdata.zhuanlan.name}
                      </NavLink>
                    </span>
                    </div>)}
            </div>
        );
    }
  }
}

export default Pathtree;
