const smsCodeData = [
    {
        code: "+86",
        name: "中国",
    },
    {
        code: "+1",
        name: "美国",
    },
    {
        code: "+81",
        name: "日本",
    },
    {
        code: "+852",
        name: "中国香港",
    },
    {
        code: "+853",
        name: "中国澳门",
    },
    {
        code: "+886",
        name: "中国台湾",
    },
    {
        code: "+60",
        name: "马来西亚",
    },
    {
        code: "+61",
        name: "澳大利亚",
    },
    {
        code: "+44",
        name: "英国",
    },
    {
        code: "+65",
        name: "新加坡",
    },
    {
        code: "+49",
        name: "德国",
    },
    {
        code: "+7",
        name: "俄罗斯",
    },
    {
        code: "+20",
        name: "埃及",
    },
    {
        code: "+27",
        name: "南非",
    },
    {
        code: "+30",
        name: "希腊",
    },
    {
        code: "+31",
        name: "荷兰",
    },
    {
        code: "+32",
        name: "比利时",
    },
    {
        code: "+33",
        name: "法国",
    },
    {
        code: "+34",
        name: "西班牙",
    },
    {
        code: "+36",
        name: "匈牙利",
    },
    {
        code: "+39",
        name: "意大利",
    },
    {
        code: "+40",
        name: "罗马尼亚",
    },
    {
        code: "+41",
        name: "瑞士",
    },
    {
        code: "+43",
        name: "奥地利",
    },
    {
        code: "+45",
        name: "丹麦",
    },
    {
        code: "+46",
        name: "瑞典",
    },
    {
        code: "+47",
        name: "挪威",
    },
    {
        code: "+48",
        name: "波兰",
    },
    {
        code: "+51",
        name: "秘鲁",
    },
    {
        code: "+52",
        name: "墨西哥",
    },
    {
        code: "+53",
        name: "古巴",
    },
    {
        code: "+54",
        name: "阿根廷",
    },
    {
        code: "+55",
        name: "巴西",
    },
    {
        code: "+56",
        name: "智利",
    },
    {
        code: "+57",
        name: "哥伦比亚",
    },
    {
        code: "+58",
        name: "委内瑞拉",
    },
    {
        code: "+62",
        name: "印度尼西亚",
    },
    {
        code: "+63",
        name: "菲律宾",
    },
    {
        code: "+64",
        name: "新西兰",
    },
    {
        code: "+66",
        name: "泰国",
    },
    {
        code: "+7",
        name: "哈萨克斯坦",
    },
    {
        code: "+82",
        name: "韩国",
    },
    {
        code: "+84",
        name: "越南",
    },
    {
        code: "+90",
        name: "土耳其",
    },
    {
        code: "+91",
        name: "印度",
    },
    {
        code: "+92",
        name: "巴基斯坦",
    },
    {
        code: "+93",
        name: "阿富汗",
    },
    {
        code: "+94",
        name: "斯里兰卡",
    },
    {
        code: "+95",
        name: "缅甸",
    },
    {
        code: "+98",
        name: "伊朗",
    },
    {
        code: "+212",
        name: "摩洛哥",
    },
    {
        code: "+213",
        name: "阿尔及利亚",
    },
    {
        code: "+216",
        name: "突尼斯",
    },
    {
        code: "+218",
        name: "利比亚",
    },
    {
        code: "+220",
        name: "冈比亚",
    },
    {
        code: "+221",
        name: "塞内加尔",
    },
    {
        code: "+223",
        name: "马里",
    },
    {
        code: "+224",
        name: "几内亚",
    },
    {
        code: "+225",
        name: "科特迪瓦",
    },
    {
        code: "+226",
        name: "布基纳法索",
    },
    {
        code: "+227",
        name: "尼日尔",
    },
    {
        code: "+228",
        name: "多哥",
    },
    {
        code: "+229",
        name: "贝宁",
    },
    {
        code: "+230",
        name: "毛里求斯",
    },
    {
        code: "+231",
        name: "利比里亚",
    },
    {
        code: "+232",
        name: "塞拉利昂",
    },
    {
        code: "+233",
        name: "加纳",
    },
    {
        code: "+234",
        name: "尼日利亚",
    },
    {
        code: "+235",
        name: "乍得",
    },
    {
        code: "+236",
        name: "中非共和国",
    },
    {
        code: "+237",
        name: "喀麦隆",
    },
    {
        code: "+239",
        name: "圣多美和普林西比",
    },
    {
        code: "+241",
        name: "加蓬",
    },
    {
        code: "+243",
        name: "刚果民主共和国",
    },
    {
        code: "+244",
        name: "安哥拉",
    },
    {
        code: "+247",
        name: "阿森松岛",
    },
    {
        code: "+248",
        name: "塞舌尔",
    },
    {
        code: "+249",
        name: "苏丹",
    },
    {
        code: "+251",
        name: "埃塞俄比亚",
    },
    {
        code: "+252",
        name: "索马里",
    },
    {
        code: "+253",
        name: "吉布提",
    },
    {
        code: "+254",
        name: "肯尼亚",
    },
    {
        code: "+255",
        name: "坦桑尼亚",
    },
    {
        code: "+256",
        name: "乌干达",
    },
    {
        code: "+257",
        name: "布隆迪",
    },
    {
        code: "+258",
        name: "莫桑比克",
    },
    {
        code: "+260",
        name: "赞比亚",
    },
    {
        code: "+261",
        name: "马达加斯加",
    },
    {
        code: "+263",
        name: "津巴布韦",
    },
    {
        code: "+264",
        name: "纳米比亚",
    },
    {
        code: "+265",
        name: "马拉维",
    },
    {
        code: "+266",
        name: "莱索托",
    },
    {
        code: "+267",
        name: "博茨瓦纳",
    },
    {
        code: "+268",
        name: "斯威士兰",
    },
    {
        code: "+350",
        name: "直布罗陀",
    },
    {
        code: "+351",
        name: "葡萄牙",
    },
    {
        code: "+352",
        name: "卢森堡",
    },
    {
        code: "+353",
        name: "爱尔兰",
    },
    {
        code: "+354",
        name: "冰岛",
    },
    {
        code: "+355",
        name: "阿尔巴尼亚",
    },
    {
        code: "+356",
        name: "马耳他",
    },
    {
        code: "+357",
        name: "塞浦路斯",
    },
    {
        code: "+358",
        name: "芬兰",
    },
    {
        code: "+359",
        name: "保加利亚",
    },
    {
        code: "+370",
        name: "立陶宛",
    },
    {
        code: "+371",
        name: "拉脱维亚",
    },
    {
        code: "+372",
        name: "爱沙尼亚",
    },
    {
        code: "+373",
        name: "摩尔多瓦",
    },
    {
        code: "+374",
        name: "亚美尼亚",
    },
    {
        code: "+375",
        name: "白俄罗斯",
    },
    {
        code: "+376",
        name: "安道尔共和国",
    },
    {
        code: "+377",
        name: "摩纳哥",
    },
    {
        code: "+378",
        name: "圣马力诺",
    },
    {
        code: "+380",
        name: "乌克兰",
    },
    {
        code: "+386",
        name: "斯洛文尼亚",
    },
    {
        code: "+420",
        name: "捷克",
    },
    {
        code: "+421",
        name: "斯洛伐克",
    },
    {
        code: "+423",
        name: "列支敦士登",
    },
    {
        code: "+501",
        name: "伯利兹",
    },
    {
        code: "+502",
        name: "瓜地马拉",
    },
    {
        code: "+503",
        name: "萨尔瓦多",
    },
    {
        code: "+504",
        name: "洪都拉斯",
    },
    {
        code: "+505",
        name: "尼加拉瓜",
    },
    {
        code: "+506",
        name: "哥斯达黎加",
    },
    {
        code: "+507",
        name: "巴拿马",
    },
    {
        code: "+509",
        name: "海地",
    },
    {
        code: "+591",
        name: "玻利维亚",
    },
    {
        code: "+592",
        name: "圭亚那",
    },
    {
        code: "+593",
        name: "厄瓜多尔",
    },
    {
        code: "+594",
        name: "法属圭亚那",
    },
    {
        code: "+595",
        name: "巴拉圭",
    },
    {
        code: "+596",
        name: "马提尼克",
    },
    {
        code: "+597",
        name: "苏里南",
    },
    {
        code: "+598",
        name: "乌拉圭",
    },
    {
        code: "+673",
        name: "文莱",
    },
    {
        code: "+675",
        name: "巴布亚新几内亚",
    },
    {
        code: "+676",
        name: "汤加",
    },
    {
        code: "+677",
        name: "所罗门群岛",
    },
    {
        code: "+679",
        name: "斐济",
    },
    {
        code: "+682",
        name: "库克群岛",
    },
    {
        code: "+689",
        name: "法属波利尼西亚",
    },
    {
        code: "+855",
        name: "柬埔寨",
    },
    {
        code: "+856",
        name: "老挝",
    },
    {
        code: "+880",
        name: "孟加拉国",
    },
    {
        code: "+960",
        name: "马尔代夫",
    },
    {
        code: "+961",
        name: "黎巴嫩",
    },
    {
        code: "+962",
        name: "约旦",
    },
    {
        code: "+963",
        name: "叙利亚",
    },
    {
        code: "+964",
        name: "伊拉克",
    },
    {
        code: "+965",
        name: "科威特",
    },
    {
        code: "+966",
        name: "沙特阿拉伯",
    },
    {
        code: "+967",
        name: "也门",
    },
    {
        code: "+968",
        name: "阿曼",
    },
    {
        code: "+971",
        name: "阿拉伯联合酋长国",
    },
    {
        code: "+972",
        name: "以色列",
    },
    {
        code: "+973",
        name: "巴林",
    },
    {
        code: "+974",
        name: "卡塔尔",
    },
    {
        code: "+976",
        name: "蒙古",
    },
    {
        code: "+977",
        name: "尼泊尔",
    },
    {
        code: "+992",
        name: "塔吉克斯坦",
    },
    {
        code: "+993",
        name: "土库曼斯坦",
    },
    {
        code: "+994",
        name: "阿塞拜疆",
    },
    {
        code: "+995",
        name: "格鲁吉亚",
    },
    {
        code: "+996",
        name: "吉尔吉斯斯坦",
    },
    {
        code: "+998",
        name: "乌兹别克斯坦",
    },
    {
        code: "+1242",
        name: "巴哈马",
    },
    {
        code: "+1246",
        name: "巴巴多斯",
    },
    {
        code: "+1264",
        name: "安圭拉岛",
    },
    {
        code: "+1268",
        name: "安提瓜和巴布达",
    },
    {
        code: "+1345",
        name: "开曼群岛",
    },
    {
        code: "+1441",
        name: "百慕大群岛",
    },
    {
        code: "+1473",
        name: "格林纳达",
    },
    {
        code: "+1664",
        name: "蒙特塞拉特岛",
    },
    {
        code: "+1671",
        name: "关岛",
    },
    {
        code: "+1758",
        name: "圣露西亚",
    },
    {
        code: "+1787",
        name: "波多黎各",
    },
    {
        code: "+1809",
        name: "多明尼加共和国",
    },
    {
        code: "+1868",
        name: "特立尼达和多巴哥",
    },
    {
        code: "+1876",
        name: "牙买加",
    },
    {
        code: "+381",
        name: "塞尔维亚共和国",
    },
    {
        code: "+222",
        name: "毛里塔尼亚",
    },
];

export {smsCodeData};
