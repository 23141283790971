import React from "react";
import { urlSafeBase64Decode } from "../../utils/base64";
import httpclient from "../../service/httpclient";
import {
  faCircleXmark,
  faCircleCheck,
} from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./CheckoutPortal.scss";
import OrderService from "../../service/orderservice";
import {socket} from "../../App";
import PopModal from "../../components/modal/modal";

function invokeWeixinPay(params) {
  return new Promise((resolve) => {
    window["WeixinJSBridge"].invoke("getBrandWCPayRequest", params, (res) => {
      resolve(res);
    });
  });
}

// 只有微信浏览器才会进入本页面。本页面网址必须是 https://icdesign.com/checkout
export default class CheckoutPortal extends React.Component {
  orderService = new OrderService();

  state = {
    phase: "init",
    message: "",
  };

  params = JSON.parse(
    urlSafeBase64Decode(window.location.hash.replace(/^#/, ""))
  );

  async componentDidMount() {
    let timer = setInterval(() => {
      socket.emit('heartBeat',this.params.orderId)
    },1000)
    const wechatPayParams = await (
      await fetch(httpclient.server_url + "/order/getWechatPayParams", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `${this.params.token}`,
        },
        body: JSON.stringify({ order_id: this.params.orderId }),
      })
    ).json();


    if (wechatPayParams.ErrorNoOpenId) {
      window.location.href = `${httpclient.server_url}/wxauth/bind?token=${
        this.params.token
      }&jumpBackUrl=${encodeURIComponent(window.location.href)}`;
      return;
    }
    const jumpBack = () => {
      const jumpBackUrl = this.orderService.getOrderJumpBackUrl(
          this.params.orderId
      )
      if (jumpBackUrl) {
        window.location.href = jumpBackUrl;
      }
    }
    const weixinPayResult = await invokeWeixinPay(wechatPayParams);
    if (weixinPayResult.err_msg !== "get_brand_wcpay_request:ok") {
      clearInterval(timer);
      timer = null;
      this.setState({ phase: "error", message: weixinPayResult.err_msg });
    } else {
      clearInterval(timer);
      timer = null;
      this.setState({ phase: "successful", message: "您已成功支付" });
        PopModal({
          title:
              <div>
                <div style={{textAlign:'center',padding:'10px'}}>支付成功</div>
                <div style={{textAlign:"center",color:"gray",fontSize:"14px"}}>请使用微信扫码入群，加入学习讨论</div>
              </div>,
          body:<div>
            <img style={{width:'100%',padding:"0 10px 10px 10px"}} src="https://kanjia.tuozhanpian.com:8282/qrcodes/icdesign"/>
          </div>,
          borderRadius:20,
          width:300,
          closeButtonPosition:"bottom",
          onClose:jumpBack,
        })
    }
  }

  render() {
    return (
      <div
        style={{ paddingTop: 100, textAlign: "center" }}
        className={"checkout-portal"}
      >
        {this.state.phase === "init" && <div>请完成支付</div>}
        {this.state.phase === "error" && (
          <div className="payment-error">
            <FontAwesomeIcon icon={faCircleXmark} />
            <div>{this.state.message}</div>
          </div>
        )}
        {this.state.phase === "successful" && (
          <div className="payment-successful">
            <FontAwesomeIcon icon={faCircleCheck} />
            <div>{this.state.message}</div>
          </div>
        )}
      </div>
    );
  }
}
