import React, { useState } from "react";
import httpclient from "../../service/httpclient";
import "./user.scss";
import { notification } from "antd";
import ScopeJudge from "../../components/scopejudge";
import { Input } from "antd";
import  {UserOutlined,LockOutlined} from "@ant-design/icons";
import PubSub from "pubsub-js";


function LoginBot() {
    const [account, setAccount] = useState(null);
    const [password,setPassword] = useState(null);

    function doLogin() {
        httpclient
            .LoginBot(account,password)
            .then((response) => {
                if (response) {
                    localStorage.setItem("token",response.token);
                    httpclient.getUserInfo().then((response) => {
                        localStorage.setItem("note_token",response.note_token);
                    })
                    let msg = {"data":true}
                    PubSub.publish("loggedIn",msg);
                    notification.info({
                        description: "欢迎" ,
                        message: "登录成功",
                    });
                    setTimeout(function () {
                        if (window.loginRedirectUrl) {
                            window.location = window.loginRedirectUrl;
                            window.loginRedirectUrl = null;
                        } else {
                            window.location = "/";
                        }
                    }, 1000);
                }
            });
    }

    return (
        <div className="login-wrapper">
            <div className="logo">
                <img src="https://cdn.datongbei.com/icdesign-logo.png" alt="logo" />
            </div>
            <div className="user-frame">
                <div className="user-title">登录/注册</div>
                <Input size="large" style={{margin:"10px 0 20px 0"}} onChange={e => setAccount(e.target.value)} prefix={<UserOutlined/>} placeholder="请输入账号"/>
                <Input size="large" onChange={e => setPassword(e.target.value)} prefix={<LockOutlined/>} placeholder="请输入密码"/>
                <button  style={{marginTop:"20px"}} className="user-long-button" onClick={() => doLogin()}>
                    登录
                </button>
            </div>
        </div>
    );
}

export default LoginBot;
