import ZhuanlanRender from "zhuanlan-render";
import httpclient from "../../service/httpclient";
import "./RichText.scss";
import useKaTeX from "../../hooks/useKaTeX";
import { useEffect, useRef } from "react";
import { render } from "react-dom";
import { Image } from "antd";

function RichText({ richText, ...props }) {
  const { type, content } = richText;
  let inner = <></>;
  switch (type) {
    case "MD":
      inner = <RichTextForMD content={content} />;
      break;
    case "HTML":
      inner = <RichTextForHTML content={content} />;
      break;
    case "STRING":
      inner = <RichTextForString content={content} />;
      break;
    default:
      break;
  }
  return <div {...props}>{inner}</div>;
}

function RichTextForHTML({ content }) {
  const ref = useRef();
  useKaTeX("ck-math-tex", content, ref);

  useEffect(() => {
    if (!ref.current) return () => {};

    const originalDisplays = new Map();
    const createdDivs = [];

    const containerDom = ref.current;
    const images = containerDom.querySelectorAll("img");
    for (const img of images) {
      if (img.style.display === "none") continue;
      // 存储原始 display 样式
      originalDisplays.set(img, img.style.display);

      const imgProps = {};
      for (const attr of img.attributes) {
        if (attr.name === "style") continue;
        imgProps[attr.name] = attr.value;
      }
      if (img.style) {
        imgProps.style = {};
        for (const attr of img.style) {
          imgProps.style[attr] = img.style[attr];
        }
      }

      // 修改 img 的 display 样式
      img.style.display = "none";

      // 创建新的 div 元素并插入到 img 后面
      const newDiv = document.createElement("div");
      img.parentNode.insertBefore(newDiv, img.nextSibling);
      render(<Image {...imgProps} />, newDiv);

      // 记录创建的 div
      createdDivs.push(newDiv);
    }

    // 清理函数
    return () => {
      for (const [img, displayValue] of originalDisplays.entries()) {
        // 恢复原始 display 样式
        img.style.display = displayValue;
      }

      createdDivs.forEach((div) => {
        // 移除创建的 div 元素
        if (div.parentNode) {
          div.parentNode.removeChild(div);
        }
      });
    };
  }, []);

  return (
    <div
      ref={ref}
      className="ckeditor-display-container"
      dangerouslySetInnerHTML={{ __html: content }}
    />
  );
}
function RichTextForString({ content }) {
  return <div>{content}</div>;
}
function RichTextForMD({ content }) {
  const ref = useRef();

  useEffect(() => {
    if (!ref.current) return () => {};

    const originalDisplays = new Map();
    const createdDivs = [];

    setTimeout(() => {
      const containerDom = ref.current;
      const images = containerDom.querySelectorAll("img");

      for (const img of images) {
        if (img.style.display === "none") continue;
        // 存储原始 display 样式
        originalDisplays.set(img, img.style.display);

        // 保存img的所有属性
        const imgProps = {};
        for (const attr of img.attributes) {
          if (attr.name === "style") continue;
          imgProps[attr.name] = attr.value;
        }
        if (img.style) {
          imgProps.style = {};
          for (const attr of img.style) {
            imgProps.style[attr] = img.style[attr];
          }
        }

        // 修改 img 的 display 样式
        img.style.display = "none";

        // 创建新的 div 元素并插入到 img 后面
        const newDiv = document.createElement("div");
        img.parentNode.insertBefore(newDiv, img.nextSibling);
        render(<Image {...imgProps} />, newDiv);

        // 记录创建的 div
        createdDivs.push(newDiv);
      }
    }, 0);

    // 清理函数
    return () => {
      for (const [img, displayValue] of originalDisplays.entries()) {
        // 恢复原始 display 样式
        img.style.display = displayValue;
      }

      createdDivs.forEach((div) => {
        // 移除创建的 div 元素
        if (div.parentNode) {
          div.parentNode.removeChild(div);
        }
      });
    };
  });

  return (
    <div ref={ref}>
      <ZhuanlanRender
        markdown={{ content }}
        drmVideoConfig={{
          getVideoPlayInfo: (accessId) => httpclient.getVideoPlayInfo(accessId),
        }}
      />
    </div>
  );
}

export default RichText;
