import React, {useContext, useEffect, useMemo, useState} from "react";
import "./my-coupons.scss";
import NavigationBar from "../../../../components/NavigationBar/navigationbar";
import CouponCard from "../../../../components/CouponCard/coupon-card";
import httpclient from "../../../../service/httpclient";
import {CouponStatus} from './coupon-enums';
import {Tooltip, Typography} from "antd";
import {UserContext} from "../../../../App";

const { Paragraph } = Typography;

function MyCoupons() {
  const [activeTab, setActiveTab] = useState('available');
  const [couponsData,setCouponsData] = useState(null)
  const {userInfo,updateUserInfo} = useContext(UserContext);
  useEffect(() => {
    httpclient.getCoupons().then((res) => {
      if(res) {
        setCouponsData(res)
      }
    })
  },[])
  const handleChangeTab = (key) => {
    setActiveTab(key);
  };
  const content = useMemo(() => {
    return <CouponCard cardData={couponsData?.filter((item) => {
      if(item.status !== CouponStatus.USED && item.status !== CouponStatus.EXPIRED && item.status !== CouponStatus.LOCKED) {
        return item.status === activeTab
      } else return (activeTab === CouponStatus.USED || activeTab === CouponStatus.EXPIRED ||  activeTab === CouponStatus.LOCKED)
    })}/>
  }, [activeTab,couponsData]);
  const NavigationBarData = [
    {
      title: "可使用",
      key: CouponStatus.AVAILABLE,
      onClick: () => {
        handleChangeTab(CouponStatus.AVAILABLE);
      },
    },
    {
      title: "已过期/已使用",
      key: CouponStatus.USED,
      onClick: () => {
        handleChangeTab(CouponStatus.USED);
      },
    },
    {
      title: "待生效",
      key: CouponStatus.UNUSED,
      onClick: () => {
        handleChangeTab(CouponStatus.UNUSED);
      },
    },
  ];
  return (
    <div className="coupons-wrapper">
      <div className="coupons-title">
        <div>优惠券</div>
        <div className="recommend-code">
              <Tooltip title="分享引荐码给好友，新用户首次购买可享5折优惠，您也可获得50元抵扣券（适用于引荐人和新用户都购买成功情况）">
                <span>
                  <img
                      style={{ width: "22px",marginRight:'4px' }}
                      src={
                        "https://cdn.datongbei.com/icdesign-17014217294306b8h88a3cb07.png?imageView2/0/format/jpg/q/90|imageslim"
                      }
                  />
                </span>
                优惠引荐码:{" "}
              </Tooltip>
              <span className="code">
                <Paragraph copyable>
                  {userInfo?.note_token?.slice(0, 6)}
                </Paragraph>
              </span>
            </div>
      </div>
      {NavigationBar(NavigationBarData, activeTab)}
      {!!couponsData && <div className="coupons-content">{content}</div>}
    </div>
  );
}

export default MyCoupons;
