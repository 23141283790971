import React, {useCallback, useContext, useEffect, useState} from "react";
import "./my_discuss.scss";
import {NavLink, useLocation} from "react-router-dom";
import { EditOutlined } from "@ant-design/icons";
import httpclient from "../../../../service/httpclient";
import DiscussList from "../../../../components/DiscussList/discuss-list";
import DiscussDraftList from "../../../../components/DiscussList/discuss-draft-list";
import {UserContext} from "../../../../App";
import {cloneDeep} from "lodash";


function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

function MyDiscuss() {
  let query = useQuery();
  const selected = query.get("selected");
  const [discussData,setDiscussData] = useState(null);
  const [discussDraftData,setDiscussDraftData] = useState(null);
  const {userInfo, updateUserInfo} = useContext(UserContext);
  const [isDraft,setIsDraft] = useState(false);

  useEffect(() => {
    httpclient.getMyDiscussData().then(res => {
      if(res){
        let map = new Map();
        res.forEach(item => {
          map.set(item.id,item);
        })
        setDiscussData(map)
      }
    })
    httpclient.getMyDiscussDraftData().then(res => {
      if(res) setDiscussDraftData(res);
    })
  },[])
  useEffect(() => {
    if(selected === "draft"){
      setIsDraft(true)
    }
  },[selected])
  const updateDiscussData = useCallback((id,action) => {
    let newState = cloneDeep(discussData);
    let item = newState.get(id);
    if(action === 'like'){
      item.likes++;
      item.liked = true;
      newState.set(id,item)
      httpclient.addDiscussLikes(id);
    }else if(action === 'dislike'){
      item.likes--;
      item.liked = false;
      newState.set(id,item)
      httpclient.delDiscussLikes(id);
    }else if(action === 'comment'){
      item.showComments = !item.showComments;
      newState.set(id,item)
    }
    console.log(newState)
    setDiscussData(newState);
  },[discussData]);
  return (
    <div className="my-discuss-wrapper">
      <div className="discuss-top-action-bar">
        <div style={{ display: "flex" }}>
          <div className={`discuss-button${isDraft ? '' : '-selected'}`} onClick={() => setIsDraft(false)}>发帖</div>
          <div className={`draft-button${!isDraft ? '' : '-selected'}`} onClick={() => setIsDraft(true)}>草稿箱</div>
        </div>

          <NavLink
            to="/discuss-submit/create"
            className="submit-button"
            style={{ background: "#5189FF" }}
          >
            <span style={{ marginRight: "4px" }}>
              <EditOutlined />
            </span>
            发帖子
          </NavLink>

      </div>
      {isDraft && discussDraftData ? <div className="my-discuss-draft">
          {DiscussDraftList(discussDraftData)}
      </div> : <div className="my-discuss-list">
        {discussData && DiscussList(Array.from(discussData?.values())?.sort((a,b) => {
          const dateA = new Date(a.created_at);
          const dateB = new Date(b.created_at);
          return dateB - dateA })
        ,{showAvatar:false, showDelete:true},updateDiscussData,userInfo,updateUserInfo)}
      </div>}
    </div>
  );
}

export default MyDiscuss;
