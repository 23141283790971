import React from "react";
import { Math } from "@isaul32/ckeditor5-math";
import { AutoformatMath } from "@isaul32/ckeditor5-math";
import QiniuUploadAdapter from "./QiniuUploadAdapter";
import { FileRepository } from "@ckeditor/ckeditor5-upload";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { ClassicEditor } from "@ckeditor/ckeditor5-editor-classic";
import { Bold, Italic } from "@ckeditor/ckeditor5-basic-styles";
import { Essentials } from "@ckeditor/ckeditor5-essentials";
import { Paragraph } from "@ckeditor/ckeditor5-paragraph";
import { Heading } from "@ckeditor/ckeditor5-heading";
import { Link } from "@ckeditor/ckeditor5-link";
import {
  Image,
  ImageCaption,
  ImageStyle,
  ImageToolbar,
  ImageUpload,
  ImageResizeEditing,
  ImageResizeHandles,
} from "@ckeditor/ckeditor5-image";
import { List } from "@ckeditor/ckeditor5-list";
import {
  FontFamily,
  FontSize,
  FontColor,
  FontBackgroundColor,
} from "@ckeditor/ckeditor5-font";
import { MediaEmbed } from "@ckeditor/ckeditor5-media-embed";
import { Autoformat } from "@ckeditor/ckeditor5-autoformat";

const config = {
  plugins: [
    Paragraph,
    Bold,
    Italic,
    Essentials,
    FileRepository,
    Heading,
    Link,
    Image,
    ImageCaption,
    ImageStyle,
    ImageToolbar,
    ImageUpload,
    ImageResizeEditing,
    ImageResizeHandles,
    List,
    FontColor,
    FontBackgroundColor,
    FontSize,
    FontFamily,
    MediaEmbed,
    Math,
    AutoformatMath,
    Autoformat,
  ],
  toolbar: [
    "heading",
    "|",
    "bold",
    "italic",
    "link",
    "bulletedList",
    "numberedList",
    "|",
    "fontBackgroundColor",
    "fontColor",
    "fontSize",
    "|",
    "uploadImage",
    "mediaEmbed",
    "|",
    "undo",
    "redo",
    "|",
    "math",
  ],
  image: {
    toolbar: [
      "imageStyle:inline",
      "imageStyle:block",
      "imageStyle:side",
      "|",
      "toggleImageCaption",
      "imageTextAlternative",
    ],
  },
  math: {
    engine: "katex",
    lazyLoad: undefined,
    outputType: "span",
    className: "ck-math-tex",
    forceOutputType: false,
    enablePreview: true,
    previewClassName: [],
    popupClassName: [],
  },
};
export default class CKEditorReact extends React.Component {
  render() {
    return (
      <CKEditor
        {...this.props}
        editor={ClassicEditor}
        config={config}
        onReady={(editor) => {
          editor.plugins.get("FileRepository").createUploadAdapter = (loader) =>
            new QiniuUploadAdapter(loader);
        }}
      />
    );
  }
}
