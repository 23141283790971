import React from "react";
import { Button } from "antd";
import OrderService from "../../service/orderservice";

export class TestCheckout extends React.Component {
  orderService = new OrderService();

  placeOrder() {
    this.orderService
      .order(
        { id: 1, type: "testpaper_collection" },
        { jumpBackUrl: window.location.href }
      )
      .then(console.log);
  }

  render() {
    return (
      <div style={{ paddingTop: 100, textAlign: "center" }}>
        <Button onClick={() => this.placeOrder()}>Test place order</Button>
      </div>
    );
  }
}
