import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { UserContext } from "../../App";
import httpclient from "../../service/httpclient";
import { Input, Modal, notification } from "antd";
import _ from "lodash";

function useCommentsDataAndActions(data_type, data_id, onChange) {
  const { userInfo, updateUserInfo } = useContext(UserContext);
  const [comments, setComments] = useState(null);
  const latestRequestId = useRef(0);
  const fetchComments = useCallback(() => {
    const currentRequestId = ++latestRequestId.current;
    httpclient
      .getRelatedComments(data_type, data_id)
      .then(
        (res) =>
          currentRequestId === latestRequestId.current &&
          setComments(calcDeriveInfo(res))
      );
  }, [data_type, data_id]);
  useEffect(() => {
    fetchComments();
  }, [fetchComments]);

  const checkNotEmpty = (content) =>
    new Promise((resolve, reject) => {
      if (!!content) resolve();
      else {
        notification.error({
          message: "错误提示",
          description: "输入内容为空，请重新输入",
        });
        reject();
      }
    });
  const checkLoggedIn = () =>
    new Promise((resolve, reject) => {
      if (!!userInfo) resolve();
      else {
        notification.warn({
          message: "请登录之后再进行操作",
        });
        reject();
      }
    });
  const checkUserName = () =>
    new Promise((resolve, reject) => {
      let username = "";
      if (!!userInfo?.name) resolve();
      else
        Modal.confirm({
          content: (
            <Input
              placeholder={"请输入姓名或昵称"}
              onChange={(e) => (username = e.target.value)}
            />
          ),
          title: "首次操作前需要输入您的姓名或昵称",
          cancelText: "取消",
          okText: "确定",
          onOk: function () {
            if (username) {
              httpclient
                .updateUserName(username)
                .then(updateUserInfo)
                .then(resolve);
            } else {
              notification.error({
                message: "错误提示",
                description: "输入内容为空，请重新输入",
              });
            }
          },
          onCancel: () => reject(),
        });
    });
  const checkConfirm = (title) =>
    new Promise((resolve, reject) =>
      Modal.confirm({
        title,
        okType: "primary",
        okText: "确定",
        cancelText: "取消",
        onOk: () => resolve(),
        onCancel: () => reject(),
      })
    );
  const triggerOnChange = async (actionName) =>
    onChange?.(actionName, comments);

  const submitComment = (data_type, data_id, content) =>
    checkNotEmpty(content)
      .then(checkLoggedIn)
      .then(checkUserName)
      .then(() => _submitComment(data_type, data_id, content))
      .then(fetchComments)
      .then(() => triggerOnChange("submitComment"));
  const toggleLike = (liked, comment_id) =>
    checkLoggedIn()
      .then(checkUserName)
      .then(() => _toggleLikeLocally(liked, comment_id, comments, setComments))
      .then(() => _toggleLike(liked, comment_id))
      .then(fetchComments)
      .then(() => triggerOnChange("toggleLike"));
  const deleteComment = (comment_id) =>
    checkConfirm("是否确认删除评论？")
      .then(() => _deleteComment(comment_id))
      .then(fetchComments)
      .then(() => triggerOnChange("deleteComment"));

  return {
    comments,
    submitComment,
    deleteComment,
    toggleLike,
  };
}

function calcDeriveInfo(comments) {
  const visitAndCalcAllSubCommentsCnt = (comment) => {
    comment.all_sub_comments_cnt =
      comment.direct_sub_comments.length +
      _.sum(comment.direct_sub_comments.map(visitAndCalcAllSubCommentsCnt));

    return comment.all_sub_comments_cnt;
  };
  comments.forEach(visitAndCalcAllSubCommentsCnt);
  return comments;
}

async function _submitComment(data_type, data_id, content) {
  await httpclient.addComment(content, data_type, data_id);
  notification.success({ message: "评论成功" });
}

async function _deleteComment(comment_id) {
  await httpclient.deleteComment(comment_id);
  notification.success({ message: "删除成功" });
}

async function _toggleLikeLocally(liked, comment_id, comments, setComments) {
  const findComment = (comment) => {
    if (comment.id === comment_id) return comment;
    else
      return comment.direct_sub_comments
        .map(findComment)
        .reduce((pre, cur) => pre || cur, null);
  };
  const comment = comments
    .map(findComment)
    .reduce((pre, cur) => pre || cur, null);
  if (!comment) throw Error(`comment with id ${comment_id} not found`);
  comment.liked = !liked;
  comment.likes_cnt += liked ? -1 : 1;
  setComments([...comments]);
}

function _toggleLike(liked, comment_id) {
  return liked
    ? httpclient.addCommentDislikes(comment_id)
    : httpclient.addCommentLikes(comment_id);
}

export default useCommentsDataAndActions;
