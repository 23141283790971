import { NavLink } from 'react-router-dom';
import { Timeline, Avatar } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import './MyResume.scss'
import ResumeItem from './resume_item/ResumeItem';
import useResumeService from '../../../../service/hooks/useResumeService'
import httpclient from '../../../../service/httpclient';

function MyResume() {
  const service = useResumeService();
  const [info, education, project, assessment] = [service.info, service.education, service.project, service.assessment];

  return (
    <div className="resume-wrapper">
      <div>
        <ResumeItem title="个人信息">
          {info && (<>
            {info?.avatar &&
              <div className="resume-item-avatar">
                <img src={info.avatar} />
              </div>
            }
            <div>
              <span>姓名：</span><span>{info.name}</span>
            </div>
            <div>
              <span>性别：</span><span>{info.gender}</span>
            </div>
            <div>
              <span>出生年月：</span><span>{info.birth}</span>
            </div>
            <div>
              <span>电话：</span><span>{info.telephone}</span>
            </div>
            <div>
              <span>邮箱：</span><span>{info.email}</span>
            </div>
          </>)}
        </ResumeItem>
        <ResumeItem title="教育背景">
          {education && (
            <Timeline>
              {education.map(item => (
                <Timeline.Item>
                  <div>
                    <span>学历：</span><span>{item.education}</span>
                  </div>
                  <div>
                    <span>学校：</span><span>{item.school}</span>
                  </div>
                  <div>
                    <span>在校时间：</span><span>{item.duration[0]}至{item.duration[1]}</span>
                  </div>
                  <div>
                    <span>专业：</span><span>{item.major}</span>
                  </div>
                  {item.description && (
                    <div>
                      <span>专业描述：</span><span>{item.description}</span>
                    </div>
                  )}
                </Timeline.Item>
              ))}
            </Timeline>
          )}
        </ResumeItem>
        <ResumeItem title="项目经历">
          {project && (
            <Timeline>
              {project.map(item => (
                <Timeline.Item>
                  <div>
                    <span>项目名称：</span><span>{item.projectName}</span>
                  </div>
                  <div>
                    <span>项目时间：</span><span>{item.duration[0]}至{item.duration[1]}</span>
                  </div>
                  {item.description && (
                    <div>
                      <span>项目内容：</span><span>{item.description}</span>
                    </div>
                  )}
                </Timeline.Item>
              ))}
            </Timeline>
          )}
        </ResumeItem>
        <ResumeItem title="个人评价">
          {assessment && (<>
            <div>
              <span>英语水平：</span><span>{assessment.english}</span><span>{assessment.score}</span>
            </div>
            {assessment.software && (
              <div>
                <span>擅长软件：</span><span>{assessment.software}</span>
              </div>
            )}
            {assessment.skills && (
              <div>
                <span>个人技能：</span><span>{assessment.skills}</span>
              </div>
            )}
          </>)}
        </ResumeItem>
      </div>

      <div style={{textAlign: 'center', paddingBottom: '50px'}}>
        {(!!info && !!education && !!project && !!assessment) ?
        <>
          <NavLink to='/user/center/my-resume/view'>
            <span
              className='resume-complete-button'
              onClick={() => {
                httpclient.downloadResumePdf();
              }}
            >
              生成简历
            </span>
          </NavLink>
        </>:
        <>
          <NavLink to={service.urlInfo.first + '?type=complete'}>
            <span className='resume-complete-button'>
              完善简历
            </span>
          </NavLink>
          <div className="resume-complete-info">
            完善简历信息可以帮助HR更快找到你哦
          </div>
        </>
        }
      </div>

    </div>
  );
}

export default MyResume;