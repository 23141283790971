import React, {useEffect, useState} from "react";
import httpclient from "../../../../service/httpclient";
import {List,Avatar} from "antd";
import './my_gifts.scss';
import {NavLink} from "react-router-dom";
import PopModal from "../../../../components/modal/modal";

function Gifts(){
    const [giftsData,setGiftsData] = useState(null);

    useEffect(() => {
        httpclient.getGiftsData().then((data)=>{
                setGiftsData(data);
        })
    },[localStorage.token]);
    const src =[];
    if(giftsData){
        giftsData.map((content,index) => {
            src[index] = {
                title:content.title,
                zhuanlan_title:content.zhuanlan_title,
                zhuanlan_img:content.zhuanlan_img,
                type:content.data_type,
                id:content.data_id,
                status:content.status,
                token:content.token
            }
        })
    }
    return(
        <div className="my-gifts-wrapper">
            <div className="my-gifts-title">赠礼记录</div>
            {!!giftsData &&
            <div>
                <List
                    itemLayout="horizontal"
                    size="large"
                    dataSource={src}
                    renderItem={(item) => (
                        <List.Item  actions={[<div className="qrcode-button" onClick={() => {
                            httpclient.getQrCode("/"+item.type+"s/"+item.id+"/share/"+item.token).then((res) => {
                                PopModal({
                                    body:<div>
                                        <img src={res}/>
                                        <div  style={{textAlign:"center",fontSize:"12px",color:"darkgrey",lineHeight:"13px",marginTop:"-10px"}}>请使用手机微信扫描二维码分享给您的好友</div>
                                    </div>,
                                    width: 300,
                                    closeButton:true,
                                })
                            })

                        }}>查看二维码</div>]}>
                            <List.Item.Meta
                                avatar={<Avatar shape="square" size="large" src={item.zhuanlan_img} />}
                                title={<NavLink to={`/${item.type}s/${item.id}`}>{item.zhuanlan_title}</NavLink>}
                                description={item.title}
                            />
                            {item.status === 'unclaimed' ? <div style={{color:"#5995DA"}}>未领取</div>:<div style={{color:"#fa6246"}}>已领取</div>}
                        </List.Item>
                    )}
                    />
            </div>}
        </div>
    )
}

export default Gifts;
