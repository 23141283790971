import React from "react";
import "./sidebar.scss";
import Catalog from "../../pages/zhuanlans/zhuanlan_detail_content/catalog/catalog";
import {NavLink} from "react-router-dom";
import SimpleScrollBar from "../ScrollBar/SimpleScrollBar";

function Sidebar(data,data_id) {
    if(data) {
        return (
            <div className="sidebar">
                <div className="sidebar-zhuanlan">
                    <img className="sidebar-zhuanlan-img" src={data.zhuanlan.image}/>
                    <NavLink className="sidebar-zhuanlan-title" to={'/zhuanlans/'+data_id}><div>{data.zhuanlan.name}</div></NavLink>
                    <div className="sidebar-zhuanlan-intro">{data.zhuanlan.introduction_html}</div>
                </div>
                        <div className="sidebar-list">
                            <SimpleScrollBar>
                                <div className="sidebar-list-title">课程目录</div>
                                {Catalog(data)}
                            </SimpleScrollBar>
                        </div>
            </div>
        )
    }
}

export default Sidebar;
// if (zhuanlansdata) {
//   let cnt = [0],
//     yOffset;
//   for (let i = 0; i < zhuanlansdata.zhuanlan.modules.length; i++) {
//     cnt[i + 1] = cnt[i] + zhuanlansdata.zhuanlan.modules[i].chapters.length;
//   }
//   if (device === "PC") {
//     yOffset = -100;
//   } else {
//     yOffset = -70;
//   }
//   const moduleslist = zhuanlansdata.zhuanlan.modules.map(
//     (modules, modulesindex) => {
//       const chapterslist = modules.chapters.map((chapters, chaptersindex) => {
//         return (
//           <a
//             onClick={() => {
//               func();
//               scrollToElement(
//                 document.getElementsByClassName("chapters-name")[
//                   chaptersindex + cnt[modulesindex]
//                 ],
//                 yOffset
//               );
//             }}
//             className="chapters-list"
//             title={chapters.name}
//           >
//             {chapters.name}
//           </a>
//         );
//       });
//       return (
//         <div>
//           <div>
//             <a
//               onClick={() => {
//                 func();
//                 scrollToElement(
//                   document.getElementsByClassName("modules-name")[
//                     modulesindex
//                   ],
//                   yOffset
//                 );
//               }}
//               className="modules-list"
//               title={modules.name}
//             >
//               {modules.name}
//             </a>
//           </div>
//           {chapterslist}
//         </div>
//       );
//     }
//   );
//   if (device === "PC") {
//     return <div className="sidebar-PC">{moduleslist}</div>;
//   } else if (device === "Mobile") {
//     return <div className="sidebar-Mobile">{moduleslist}</div>;
//   }
// }