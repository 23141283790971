import React from "react";
import "./footer.scss";
import footer from "../../site_config";
function Footer() {
  return (
    <div className="footer">
      <span
        className="copyright"
        dangerouslySetInnerHTML={{ __html: footer.copyright }}
      />
    </div>
  );
}

export default Footer;
