import React, {useEffect, useMemo, useRef, useState} from "react";
import ZhuanlanRender from "zhuanlan-render";
import "zhuanlan-render/build/static/css/main.css";
import "katex/dist/katex.min.css";
import httpclient from "../../service/httpclient";
import { Redirect, withRouter } from "react-router";
import List from "../../components/lists/list";
import { useParams } from "react-router";
import Pathtree from "../../components/PathTree/pathtree";
import "./categories.scss";
import "../../components/PathTree/pathtree.scss";
import {faShoppingCart} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {NavLink} from "react-router-dom";
import TestpaperList from "../../components/lists/testpaperlist";
import SimpleScrollBar from "../../components/ScrollBar/SimpleScrollBar";

function Categories() {
  const [CategoriesData, setCategoriesData] = useState(null);
  const [CategoriesPossession,setCategoriesPossession] = useState(false);
  const [TestPaperCollectionData,setTestPaperCollectionData] = useState(null);
  const [TestPaperCollectionPossession,setTestPaperCollectionPossession] = useState(false);
  const [renewMode,setRenewMode] = useState(false);
  const categoriesid = useParams().categoriesid;
  const scrollRef = useRef();
  useEffect(() => {
    httpclient.getCategoriesData(categoriesid).then((data) => {
      setCategoriesData(data);
      scrollRef.current.scrollTo(0,0);
     // window.scrollTo(0,0);
    });
    if(localStorage.token){
      httpclient.possessCategory(categoriesid).then((response) => {
        setCategoriesPossession(response.result);
        setRenewMode(response.time_expired);
      })
    }
  }, [categoriesid]);
  useEffect(() => {
    if(CategoriesData && CategoriesData.children.length === 1 && CategoriesData.children[0].datatype === 'testpaper_collection'){
      if(localStorage.token){
        httpclient.getTestPaperCollectionInfo(CategoriesData.children[0].detail.id).then((data) => {
          setTestPaperCollectionData(data);
        })
        httpclient.possessTestpaperCollection(CategoriesData.children[0].detail.id).then((response) => {
          setRenewMode(response.time_expired);
          setTestPaperCollectionPossession(response.result);
        })
      }else{
        httpclient.getTestPaperCollectionData(CategoriesData.children[0].detail.id).then((data) => {
          setTestPaperCollectionData(data);
        })
      }

    }
  },[CategoriesData]);
  const categorieslist = useMemo(() => {
    if (CategoriesData) return List(CategoriesData.children);
  }, [CategoriesData]);

  if (+categoriesid === 1) {
    return <Redirect to="/" />;
  }
  return (
    <div className="categories">
      <SimpleScrollBar ref={scrollRef}>
      {Pathtree("categories", CategoriesData,categoriesid)}
      <div className="category-wrapper">
        <div className="category-img-wrapper">
          {!!CategoriesData && (
            <img className="category-img" src={CategoriesData.category.image} />
          )}
        </div>
        <div className="category-name-wrapper">
          <div className="category-name">
            {!!CategoriesData && CategoriesData.category.name}
          </div>
        </div>
        <div className="category-intro-wrapper">
          <div className="category-intro">
            {!!CategoriesData && (
              <ZhuanlanRender
                drmVideoConfig={{
                  getVideoPlayInfo: (accessId) => {
                    return httpclient.getVideoPlayInfo(accessId);
                  },
                }}
                markdown={{
                  content: CategoriesData.category.introduction_html,
                }}
              />
            )}
          </div>
            {!!(CategoriesData && CategoriesData.category.purchasable && !CategoriesPossession) &&
            (
                <div className="purchase-wrapper">
                <div className="price">RMB{CategoriesData.category.price/100}/年</div>
                  <div className="purchase-frequency">(已有{CategoriesData.category.purchase_cnt}人购买)</div>
                <NavLink to={'/checkout/categories/'+ categoriesid + (renewMode ? '/renew' : '')} className="purchase-button">
                <FontAwesomeIcon icon={faShoppingCart}/> 合集购买
          </NavLink>
                </div>
            )}
        </div>
      </div>

        {!!CategoriesData && CategoriesData.children.length === 1 && CategoriesData.children[0].datatype === 'testpaper_collection' ?
          <div className="testpaper_collection-in-category">
            {!!(TestPaperCollectionData && !TestPaperCollectionPossession) &&
          (
              <div className="purchase-wrapper">
                <div className="price">RMB{TestPaperCollectionData.price/100}/年</div>
                <div className="purchase-frequency">(已有{TestPaperCollectionData.purchase_cnt}人购买)</div>
                <NavLink to={`/checkout/testpaper_collection/${TestPaperCollectionData.id}${renewMode ? '/renew' : ''}`} className="purchase-button">
                  <FontAwesomeIcon icon={faShoppingCart}/> 合集购买
                </NavLink>
              </div>
          )}
            <div className="testpaper-list-wrapper">{TestpaperList(TestPaperCollectionData)}</div> </div>:
          <div className="lists-wrapper">{categorieslist}</div>
      }
      </SimpleScrollBar>
    </div>
  );
}

export default withRouter(Categories);
