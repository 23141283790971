import { NavLink } from "react-router-dom";
import React from "react";
import routeUrls from "../../route/routeurls";
import "./list.scss";
import httpclient from "../../service/httpclient";
import ZhuanlanRender from "zhuanlan-render";



function List(data) {
  if (data) {
    return data.map((content) => {
      if(content.id !== 38 && content.id !== 16 && content.id !== 44 && content.id !== 46 && content.id !== 47 && content.id !== 48 && content.id !== 49 && content.id !== 52){
        return(
            <div className="item">
              <div className="item-wrapper">
                <div className="categories-name">
                  {content.detail.displayable ? <NavLink to={content.datatype === "testpaper_collection" ? routeUrls(content.datatype, content.detail.id):routeUrls(content.datatype, content.id)}>
                        {content.detail.name}
                      </NavLink>:
                      <span style={{cursor:"not-allowed"}}>{content.detail.name}</span>
                  }
                </div>
                <div className="categories-img-wrapper">

                  {content.detail.displayable ? <NavLink to={content.datatype === "testpaper_collection" ? routeUrls(content.datatype, content.detail.id):routeUrls(content.datatype, content.id)}>
                        <img
                            className="categories-img"
                            src={content.detail.image}
                            alt={content.detail.name}
                        />
                      </NavLink>:
                      <img
                          className="categories-img"
                          src={content.detail.image}
                          style={{cursor:"not-allowed"}}
                          alt={content.detail.name}
                      />
                  }
                </div>
                <div className="categories-content-wrapper">
                  <div className="categories-intro-wrapper">
                    <div className="categories-intro">
                      {content.datatype === "testpaper_collection" ? (
                          content.detail.introduction_text
                      ) : (
                          localStorage.token ? <div className="notelix-enabled">
                            <ZhuanlanRender
                                drmVideoConfig={{
                                  getVideoPlayInfo: (accessId) => {
                                    return httpclient.getVideoPlayInfo(accessId);
                                  },
                                }}
                                markdown={{
                                  content: content.detail.introduction_html,
                                }}
                            />
                          </div>: <ZhuanlanRender
                              drmVideoConfig={{
                                getVideoPlayInfo: (accessId) => {
                                  return httpclient.getVideoPlayInfo(accessId);
                                },
                              }}
                              markdown={{
                                content: content.detail.introduction_html,
                              }}
                          />
                      )}
                      {content.detail.displayable ?
                          <div>
                            <NavLink
                                to={content.datatype === "testpaper_collection" ? routeUrls(content.datatype, content.detail.id):routeUrls(content.datatype, content.id)}
                                className="read-more"
                            >
                              查看更多
                            </NavLink>
                          </div>
                          :
                          <div className="unopen-wrapper">
                            <span className="unopen">即将开放...</span>
                            {/*<span className="appointment"><FontAwesomeIcon icon={faPlusCircle} /> 预约</span>*/}
                          </div>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
        )
      }

    })
  }

  return null;
}

export default List;
