import React, {useCallback, useContext, useEffect, useMemo, useState} from "react";
import "./discuss.scss";
import SimpleScrollBar from "../../components/ScrollBar/SimpleScrollBar";
import { Input,Select } from "antd";
import { EditOutlined, InboxOutlined,DownOutlined } from "@ant-design/icons";
import DiscussList from "../../components/DiscussList/discuss-list";
import {NavLink} from "react-router-dom";
import httpclient from "../../service/httpclient";
import {UserContext} from "../../App";
import {cloneDeep} from "lodash";

export const DiscussContext = React.createContext("");

const { Search } = Input;

function Discuss() {
  const [selectedTag,setSelectedTag] = useState('');
  const [discussData,setDiscussData] = useState(null);
  const [sortMethod,setSortMethod] = useState('time');
  const [searchInput, setSearchInput] = useState('');
  const {userInfo, updateUserInfo} = useContext(UserContext);
  useEffect(() => {
    httpclient.getDiscussData().then((res) => {
      if(res){
        let map = new Map();
        res.forEach(item => {
          map.set(item.id,item);
        })
        setDiscussData(map)
      }
    })
  },[])
  const updateSelectedTag = (tag) => {
    if(selectedTag === tag){
      setSelectedTag('');
    }else{
      setSelectedTag(tag);
    }
  }
  const updateDiscussData = useCallback((id,action) => {
    let newState = cloneDeep(discussData);
    let item = newState.get(id);
    if(action === 'like'){
      item.likes++;
      item.liked = true;
      newState.set(id,item)
      httpclient.addDiscussLikes(id);
    }else if(action === 'dislike'){
      item.likes--;
      item.liked = false;
      newState.set(id,item)
      httpclient.delDiscussLikes(id);
    }else if(action === 'comment'){
      item.showComments = !item.showComments;
      newState.set(id,item)
    }
    setDiscussData(newState);
  },[discussData]);

  const onSearch = (value, _e, info) => {
    setSearchInput(value);
  }

    const discussList = useMemo(() => {
      return discussData && DiscussList(
          Array.from(discussData?.values())?.filter(item => (selectedTag === '' || item.select === 2) ? true : item.tag === selectedTag).sort((a,b) => {
            if(a.select !== b.select) return b.select - a.select;
            const dateA = new Date(a.created_at);
            const dateB = new Date(b.created_at);
            return sortMethod === 'time' ? dateB - dateA : b.likes - a.likes})
          ,{searchInput:searchInput},updateDiscussData,userInfo,updateUserInfo);
    },[discussData,selectedTag,sortMethod,searchInput,userInfo]);

  return (
    <div className="discuss">
      <DiscussContext.Provider value={{ discussData, updateDiscussData }}>
      <SimpleScrollBar>
        <div className="discuss-wrapper">
          <div className="discuss-title-bar">
            <div className="discuss-title">讨论广场</div>
            <div className="discuss-action-wrapper">
              <Search
                size="middle"
                style={{ width: 250, marginRight: "20px" }}
                className="discuss-search"
                onSearch={onSearch}
                allowClear
              />
              <div className="discuss-button-wrapper">
                <NavLink to="/discuss-submit/create" className="discuss-button" style={{ background: "#5189FF",marginRight:'20px'}}>
                <span className="discuss-button-icon" >
                  <EditOutlined />
                </span>
                  <span className="discuss-button-text">发帖子</span>
                </NavLink>
                <NavLink to="/user/center/my-discuss?selected=draft" className="discuss-button" style={{ background: "#44C176" }}>
                <span className="discuss-button-icon">
                  <InboxOutlined />
                </span>
                  <span className="discuss-button-text">草稿箱</span>
                </NavLink>
              </div>

            </div>
          </div>
          <div className="discuss-tag-bar">
            <div style={{display:'flex'}}>
              <div className={`discuss-tag${selectedTag === '求职' ? '-selected' : ''}`} onClick={() => {updateSelectedTag('求职')}}>+求职</div>
              <div className={`discuss-tag${selectedTag === '面经' ? '-selected' : ''}`} onClick={() => {updateSelectedTag('面经')}}>+面经</div>
              <div className={`discuss-tag${selectedTag === '内推' ? '-selected' : ''}`} onClick={() => {updateSelectedTag('内推')}}>+内推</div>
              <div className={`discuss-tag${selectedTag === '学习' ? '-selected' : ''}`} onClick={() => {updateSelectedTag('学习')}}>+学习</div>
            </div>
            <div className="sort-select">
              <Select
                  defaultValue='time'
                  options={[{label:'时间排序',value:'time'},{label:'点赞排序',value:'like'}]}
                  onChange={v => setSortMethod(v)}
              />
            </div>
          </div>
          <div className="discuss-content">
            {discussList}
          </div>
        </div>
      </SimpleScrollBar>
      </DiscussContext.Provider>
    </div>
  );
}

export default Discuss;
