import React, { useEffect, useState, useReducer, useContext } from "react";
import { NavLink } from "react-router-dom";
import {
  LogoutOutlined,
  ReadOutlined,
  UserOutlined,
  BellOutlined,
  GiftOutlined,
  StarOutlined,
  FileTextOutlined,
  MoneyCollectOutlined,
} from "@ant-design/icons";
import { Divider, Menu, Popover } from "antd";
import "./title.scss";
import httpclient from "../../service/httpclient";
import { MessageContext, UserContext } from "../../App";
import { createFromIconfontCN } from "@ant-design/icons";

const IconFont = createFromIconfontCN({
  scriptUrl: "//at.alicdn.com/t/c/font_3726078_2ct28hn0cbd.js",
});

function Title() {
  const [ignored, forceUpdate] = useReducer((x) => x + 1, 0);
  const [menuVisible, setMenuVisible] = useState(false);
  const { userInfo, updateUserInfo } = useContext(UserContext);
  const { unreadMessageCnt, delCnt } = useContext(MessageContext);
  useEffect(() => {
    if (localStorage.token) {
      httpclient.verifyToken().then((res) => {
        if (res === false) {
          localStorage.removeItem("token");
          localStorage.removeItem("note_token");
          forceUpdate();
        }
      });
    }
  });
  const menu = (
    <div>
      <div className="menu-title">欢迎</div>
      <Divider style={{ margin: "6px 0" }} />
      <div className="menu-item" o>
        <NavLink
          to="/user/center/general"
          onClick={() => setMenuVisible(false)}
        >
          <UserOutlined />
          个人中心
        </NavLink>
      </div>
      <div className="menu-item">
        <NavLink
          to="/user/center/messages"
          onClick={() => setMenuVisible(false)}
          style={{ display: "flex", alignItems: "center" }}
        >
          <BellOutlined />
          站内消息
          {unreadMessageCnt !== 0 && (
            <span className="menu-unread-message-cnt">
              {unreadMessageCnt >= 99 ? "99+" : unreadMessageCnt}
            </span>
          )}
        </NavLink>
      </div>
        <div className="menu-item">
            <NavLink
                to="/user/center/my-rights"
                onClick={() => setMenuVisible(false)}
                style={{ display: "flex", alignItems: "center" }}
            >
                <StarOutlined />
                    我的权益
            </NavLink>
        </div>
      <div className="menu-item">
        <NavLink
          to="/user/center/my-courses"
          onClick={() => setMenuVisible(false)}
        >
          <ReadOutlined />
          我的课程
        </NavLink>
      </div>
      <div className="menu-item">
        <NavLink
          to="/user/center/my-resume/view"
          onClick={() => setMenuVisible(false)}
        >
          <FileTextOutlined />
          我的简历
        </NavLink>
      </div>
      <div className="menu-item">
        <NavLink
          to="/user/center/my-coupons"
          onClick={() => setMenuVisible(false)}
        >
          <MoneyCollectOutlined />
          我的优惠
        </NavLink>
      </div>
      <Divider style={{ margin: "6px 0" }} />
      {/*<div className="menu-item">*/}
      {/*    <NavLink to="/user/center/recruit" onClick={() => setMenuVisible(false)} style={{display:'flex',alignItems:'center'}}>*/}
      {/*        <IconFont type="icon-bag"/>*/}
      {/*        <span style={{textAlign:'center',width:'100%'}}>我的招聘</span>*/}
      {/*    </NavLink>*/}
      {/*</div>*/}
      {/*<Divider style={{margin:'6px 0'}}/>*/}
      <div className="menu-item">
        <a
          href="/"
          onClick={() => {
            localStorage.removeItem("token");
            localStorage.removeItem("note_token");
          }}
        >
          <LogoutOutlined />
          退出登录
        </a>
      </div>
    </div>
  );

  return (
    <header className="title">
      <div className="left">
        <NavLink to="/">
          <img src="https://cdn.datongbei.com/icdesign-logo.png" alt="logo" />
        </NavLink>
      </div>
      <div className="middle"></div>
      <div className="right">
        <NavLink className="title-button" to="/" activeClassName="active" exact>
          专栏
        </NavLink>
        {userInfo &&
          (userInfo.id === 21 || userInfo.id === 17 || userInfo.id === 18) && (
            <NavLink
              className="title-button"
              to="/discuss"
              activeClassName="active"
              exact
            >
              讨论
            </NavLink>
          )}
        <NavLink
          className="title-button"
          to="/recruitment-question-set"
          activeClassName="active"
          exact
        >
          题库
        </NavLink>
        {localStorage.token ? (
          <Popover
            content={menu}
            trigger="click"
            placement="bottomRight"
            visible={menuVisible}
            onVisibleChange={(visible) => {
              setMenuVisible(visible);
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginLeft: "10px",
              }}
            >
              <UserOutlined className="title-button-img" />
              {unreadMessageCnt !== 0 && (
                <div className="unread-message-cnt">
                  {unreadMessageCnt >= 99 ? "99+" : unreadMessageCnt}
                </div>
              )}
            </div>
          </Popover>
        ) : (
          <NavLink
            className="title-button-login"
            to="/user/login"
            onClick={() => {
              window.loginRedirectUrl = window.location.pathname;
            }}
          >
            登录 | 注册
          </NavLink>
        )}
      </div>
    </header>
  );
}

export default Title;
