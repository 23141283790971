import React from "react";
import PopModal from "../modal/modal";
import './coupon-modal.scss';
import {CouponEffect} from "../../pages/user_center/contents/my_coupons/coupon-enums";

function CouponModal(coupons){
    const cardWidth = document.body.offsetWidth >= 600 ? 400 : document.body.offsetWidth*9/10;
    const couponList = coupons.map(coupon => (
            <div className="coupon-modal-item">
                <div className="coupon-modal-discount">{coupon.effect === CouponEffect.VOUCHER ? coupon.discount/100 + ' 元' : coupon.discount + ' 折'}</div>
                <div className="coupon-modal-content">
                    <div className="coupon-modal-name">{coupon.name}</div>
                    <div className="coupon-modal-description">{coupon.description}</div>
                </div>
            </div>
        ))

    PopModal({
        closeButtonPosition: 'bottom',
        title:<div style={{textAlign:'center',padding:'10px'}}>礼券已添加至账户</div>,
        body:<div>
            <div className="coupon-list-wrapper">{couponList}</div>
            <div className="coupon-modal-message">可至个人中心【我的礼券】查看详情</div>
        </div>,
        borderRadius:20,
        width:cardWidth,
        top: 200,
        onOk:function (){
            window.location.href = '/user/center/my-coupons'
        },
        okText:'点击查看',
        buttonPos:'center'
    })
}

export default CouponModal;
