import React from "react";
import "./rights-list.scss";
import {Tooltip} from "antd";
import PopModal from "../../../../components/modal/modal";
import {CouponEffect} from "../my_coupons/coupon-enums";

function RightsList(data) {
  return data?.rights.map((item) => {
      let modalContent;
        if(item.type === 'coupon'){
            let couponList= item?.content?.map(coupon => (
                <div className="coupon-modal-item">
                    <div className="coupon-modal-discount">{coupon.effect === CouponEffect.VOUCHER ? coupon.discount/100 + ' 元' : coupon.discount + ' 折'}</div>
                    <div className="coupon-modal-content">
                        <div className="coupon-modal-name">{coupon.name}</div>
                        <div className="coupon-modal-description">{coupon.description}</div>
                    </div>
                </div>
            ))
            modalContent = <div className="rights-modal-content">
                {couponList}
                <div className="rights-modal-description">所有优惠券均已自动发放至账户</div>
            </div>
        }
    if (data.level >= item.min_level) {
      return (
          <div className="rights-item-wrapper" onClick={() => {
              PopModal({
                  width:400,
                  closeButtonPosition: 'bottom',
                  borderRadius:20,
                  top: 150,
                  okText:'点击查看',
                  buttonPos:'center',
                  title: item.title + "权益",
                  body:modalContent ,
                  onOk() {
                      window.location.href = '/user/center/my-coupons'
                  },
              })
          }}>
          <img src={item.icon} className="rights-item-icon"/>
          <div className="rights-item-title">{item.title}</div>
              <div className="rights-item-description">{item.description}</div>
          </div>
      );
    } else return (
        <Tooltip className="rights-item-wrapper disabled" title={"达到相应等级后解锁对应权益"}>
            <img src={item.icon} className="rights-item-icon disabled"/>
            <div className="rights-item-title disabled">{item.title}</div>
            <div className="rights-item-description">{item.description}</div>
        </Tooltip>
    );
  });
}

export default RightsList;
