import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";


window.NotelixReady = new Promise(resolve => {
    if (localStorage.token) {
        window.NotelixEmbeddedConfig = {
            server: "https://public-dev.notelix.com/",
            staticToken: `${localStorage.note_token}`,
            rootElementClassName: "notelix-enabled",
            disablePaintMarkerRetry: true,
            disableLoadAllAnnotationsDataWhenUrlChanges: true,
            onReady: () => resolve()
        }
        window.addEventListener('load', function () {
            let script = document.createElement('script');
            script.src = window.NotelixEmbeddedConfig.server + "/embedded/content-script.dist.js";
            document.head.appendChild(script);
        });
    }
});


ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
