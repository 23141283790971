import React from "react";
import MacScrollBar from "./scrollbar";

const SimpleScrollBar = React.forwardRef((props,ref) => {
    if(props.scrollY !== false){
        return <MacScrollBar key={props.key} ref={ref} behavior={props.behavior}>{props.children}</MacScrollBar>
    }else{
        return(
            <div>
                {props.children}
            </div>
        )
    }
})

export default SimpleScrollBar;