import React from "react";
import "katex/dist/katex.min.css";
import "zhuanlan-render/build/static/css/main.css";
import ZhuanlanRender from "zhuanlan-render";
import httpclient from "../../service/httpclient";

const testMd = `
# video

<DrmVideo accessId="qsLEjFLsj8VRrxPKJLbWY1hh6f2bkUc4"/>

`;

export default class ZhuanlanRenderTestPage extends React.Component {
  render() {
    return (
      <div style={{ padding: "100px 20px 20px 20px" }}>
        <ZhuanlanRender
          markdown={{
            content: testMd,
          }}
          setTitle={(t) => (window.document.title = t)}
          drmVideoConfig={{
            getVideoPlayInfo: (accessId) => {
              return httpclient.getVideoPlayInfo(accessId);
            },
          }}
        />
      </div>
    );
  }
}
