import { Empty } from "antd";
import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import Progress from "../../../../components/progress/progress";
import httpclient from "../../../../service/httpclient";
import moment from "moment";
import "./my_courses.scss";

function MyCourses(props) {
  const [courses, setCourses] = useState([]);

  useEffect(() => {
    httpclient.getCourses().then((response) => {
      setCourses(response);
    });
  }, []);

  return (
    <div className="my-courses-wrapper">
      <div className="my-courses-title">我的课程</div>
      <div className="my-courses-content">
        {courses.length !== 0 ? (
          courses.map((course) => (
              <div className="course" key={course.id}>
                  <div style={{display:'flex',marginRight:'10px'}}>
                      <NavLink to={course.endpoint}><img className="course-image" src={course.image} /></NavLink>
                      <div style={{padding:'20px 0',display:'flex',justifyContent:'space-around',flexFlow:'column',marginLeft:'20px'}}>
                          <NavLink to={course.endpoint}><div style={{ color: "black" }} className="course-name">
                              {course.name}
                          </div></NavLink>
                          {course.expire_time && <div className="expire-time-notification">
                              到期时间：{moment.utc(course.expire_time).local().format('YYYY-MM-DD')}{" "}
                              {moment(course.expire_time).isBefore(moment())
                                  ? "(已过期)"
                                  : "(未过期)"}
                          </div>}
                      </div>
                  </div>
                  <NavLink to={`/checkout${course.endpoint}/renew`} className={'renew-button'}><div>续费</div></NavLink>
                {/* <Progress percent={80}/>
                <p className="progress-info">{`完成${80}%`}</p> */}
              </div>
          ))
        ) : (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="空空如也" />
        )}
      </div>
    </div>
  );
}

export default MyCourses;
