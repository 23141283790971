import React, {useEffect, useState} from "react";
import {NavLink, useLocation} from "react-router-dom";
import {LeftOutlined,StarOutlined,EnvironmentOutlined} from "@ant-design/icons";
import './recruit_detail.scss';
import {useParams} from "react-router";
import httpclient from "../../../../../../service/httpclient";
import {Modal, notification} from "antd";

function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

function RecruitDetail(){
    let query = useQuery();
    const addressFilter = query.get('address_filter');
    const timeFilter = query.get('time_filter');
    const postFilter = query.get('post_filter');
    const recruit_id = useParams().recruit_id;
    const [postData,setPostData] = useState(null);
    useEffect(() => {
        httpclient.getPostData(recruit_id).then((res) => {
            setPostData(res);
        })
    },[recruit_id])
    return(
        <div className="recruit-detail">
            <NavLink className="recruit-back" to={`/user/center/recruit?address_filter=${addressFilter}&time_filter=${timeFilter}&post_filter=${postFilter}`} >
                <LeftOutlined />  <span>返回</span>
            </NavLink>
            {!!postData && <div className="recruit-detail-content">
                <div className="recruit-detail-des">
                    <div className="recruit-detail-post">
                        <div className="recruit-detail-post-name">
                            {postData.name}
                        </div>
                        {/*<span style={{marginLeft:'10px',fontSize:'20px'}}><StarOutlined/></span>*/}
                    </div>
                    <div className="recruit-detail-salary-mobile">11k - 13k</div>
                    <div className="recruit-detail-requirements">
                        应聘要求：<br/>
                        {postData.capacity_req}
                    </div>
                    <div className="recruit-detail-welfare">
                        职位福利：<br/>
                        {postData.welfare}
                    </div>
                </div>
                <div className="recruit-detail-action">
                    <div className="recruit-detail-salary">{postData.salary === '面谈' ? '薪资面谈' : `${postData.salary[0]}K - ${postData.salary[1]}K`}</div>
                    <div className="recruit-detail-submit-resume" onClick={() => {
                        Modal.confirm({
                            title:'是否确定投递简历？',
                            okText:'确定',
                            cancelText:'取消',
                            onOk: function (){
                                httpclient.submitResume(postData.id).then(() => {
                                    notification.success({
                                        message:'投递简历成功',
                                    })
                                })
                            }})}}>投递简历</div>
                    {/*<div className="recruit-detail-greet">打个招呼</div>*/}
                </div>
            </div>}
            {!!postData && <div className="recruit-detail-company">
                <div className="recruit-detail-company-des">
                    <div className="recruit-detail-company-name">
                        上海实榴甜网络科技有限公司
                    </div>
                    <div style={{display:'flex',marginBottom:'20px'}}>
                        <div className="recruit-detail-people-num">{`${postData.people_num[0]} - ${postData.people_num[1]}人`}</div>
                        <div className="recruit-detail-company-financing">A轮融资</div>
                    </div>
                    <div className="recruit-detail-company-address">
                        <span><EnvironmentOutlined /></span> <span>{postData.address}</span>
                    </div>
                    <div className="recruit-detail-company-intro">
                        {postData.company_intro}
                    </div>
                </div>

                <div className="recruit-detail-company-image">
                    <img  className="company-image" src={postData.company_logo}/>
                </div>
            </div>}
        </div>
    )
}

export default RecruitDetail;