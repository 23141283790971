import HottestRecruitmentQuestionList from "../../components/recruitment-question/HottestRecruitmentQuestionList/HottestRecruitmentQuestionList";
import RecruitmentQuestionAllQuestionsTable from "../../components/recruitment-question/RecruitmentQuestionAllQuestionsTable/RecruitmentQuestionAllQuestionsTable";
import RecruitmentQuestionRecommendListCard from "../../components/recruitment-question/RecruitmentQuestionRecommendListCard/RecruitmentQuestionRecommendListCard";
import "./recruitment_question_set.scss";

function RecruitmentQuestionSet() {
  return (
    <div className="recruitment-question-set">
      <div className="recruitment-question-set-wrapper">
        <div id="recommend-list">
          <div className="section-header">
            <div className="section-title">推荐题单</div>
            {/* <div className="section-more">更多</div> */}
          </div>
          <div className="section-container">
            {[1].map((x) => (
              <div key={x} className="card-item">
                <RecruitmentQuestionRecommendListCard />
              </div>
            ))}
          </div>
        </div>
        <div id="hottest-questions">
          <div className="section-header">
            <div className="section-title">热门题目</div>
          </div>
          <div className="section-container">
            <HottestRecruitmentQuestionList />
          </div>
        </div>
        <div id="all-questions">
          <div className="section-header">
            <div className="section-title">全部题目</div>
          </div>
          <div className="section-container">
            <RecruitmentQuestionAllQuestionsTable />
          </div>
        </div>
      </div>
    </div>
  );
}

export default RecruitmentQuestionSet;
