import React from "react";
import "./comments.scss";
import {
  CaretRightOutlined,
  DeleteOutlined,
  LikeOutlined,
  MessageOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Avatar, Input, Modal, notification, Tooltip, Empty } from "antd";
import moment from "moment";
import httpclient from "../../../../service/httpclient";
import { socket } from "../../../../App";

const { TextArea } = Input;

function sortByLikes(a, b) {
  if (a.select > b.select) {
    return -1;
  } else if (a.select < b.select) {
    return 1;
  } else {
    if (a.likes < b.likes) {
      return 1;
    } else if (a.likes > b.likes) {
      return -1;
    } else {
      let timea = moment(a.created_at).format("YYYY-MM-DD HH:mm:ss");
      let timeb = moment(b.created_at).format("YYYY-MM-DD HH:mm:ss");
      if (moment(timea).isBefore(timeb)) {
        return 1;
      } else return -1;
    }
  }
}

function Comments(data, data_type, data_id, default_expand_id,userInfo,updateUserInfo,type="detail") {
  let inputContent = {};
  let active = {},
    likes = {},
    sub_active = {},
    sub_likes = {};
  let username;
  let input = null;
  const likesButton = (user_id, inform, comment_id, index, sub_index) => {
    if (!userInfo){
      notification.warn({
        message:'请登录之后再进行操作'
      })
      return;
    }
    if (userInfo && !userInfo?.name) {
      Modal.confirm({
        content: (
          <Input
            placeholder={"请输入姓名或昵称"}
            onChange={(e) => {
              username = e.target.value;
            }}
          />
        ),
        title: "点赞前需要输入您的姓名或昵称",
        cancelText: "取消",
        okText: "确定",
        autoFocusButton: "cancel",
        onOk: function () {
          if (username) {
            httpclient.updateUserName(username).then(() => {
              updateUserInfo();
            });
          } else {
            notification.error({
              message: "错误提示",
              description: "输入内容为空，请重新输入",
            });
          }
        },
      });
    } else {
      if (sub_index || sub_index === 0) {
        if (sub_active[`${index} ${sub_index}`]) {
          addDislikes(comment_id, index, sub_index);
        } else {
          addLikes(user_id, inform, comment_id, index, sub_index);
        }
      } else {
        if (active[comment_id]) {
          addDislikes(comment_id, index, sub_index);
        } else {
          addLikes(user_id, inform, comment_id, index, sub_index);
        }
      }
    }
  };
  const addLikes = (user_id, inform, comment_id, index, sub_index) => {
    httpclient.addCommentLikes(comment_id).then(() => {
      if (!inform) socket.emit("inform", user_id);
    });
    if (sub_index || sub_index === 0) {
      document.getElementsByClassName(
        `comment-like-button sub-comment${index} ${sub_index}`
      )[0].style.color = "#5995DA";
      sub_likes[`${index} ${sub_index}`]++;
      document.getElementsByClassName(
        `likes-cnt sub-comment${index} ${sub_index}`
      )[0].innerHTML = sub_likes[`${index} ${sub_index}`];
      sub_active[`${index} ${sub_index}`] = true;
    } else {
      document.getElementsByClassName(
        `comment-like-button comment ${comment_id}`
      )[0].style.color = "#5995DA";
      likes[comment_id] = likes[comment_id] + 1;
      document.getElementsByClassName(
        `likes-cnt comment ${comment_id}`
      )[0].innerHTML = likes[comment_id];
      active[comment_id] = true;
    }
  };
  const addDislikes = (comment_id, index, sub_index) => {
    httpclient.addCommentDislikes(comment_id);
    if (sub_index || sub_index === 0) {
      document.getElementsByClassName(
        `comment-like-button sub-comment${index} ${sub_index}`
      )[0].style.color = "darkgrey";
      sub_likes[`${index} ${sub_index}`]--;
      document.getElementsByClassName(
        `likes-cnt sub-comment${index} ${sub_index}`
      )[0].innerHTML = sub_likes[`${index} ${sub_index}`];
      sub_active[`${index} ${sub_index}`] = false;
    } else {
      document.getElementsByClassName(
        `comment-like-button comment ${comment_id}`
      )[0].style.color = "darkgrey";
      likes[comment_id] = likes[comment_id] - 1;
      document.getElementsByClassName(
        `likes-cnt comment ${comment_id}`
      )[0].innerHTML = likes[comment_id];
      active[comment_id] = false;
    }
  };
  const comments =
      data?.comments && data.comments.sort(sortByLikes).map((content, index) => {
        let sub_comments;
        if (content.sub_comments.length > 0) {
          sub_comments = content.sub_comments.map((content, sub_index) => {
            sub_active[`${index} ${sub_index}`] = content.active;
            sub_likes[`${index} ${sub_index}`] = content.likes;
            return (
              <div
                className={`comment-item ${content.id}`}
                style={{ padding: "10px 0" }}
              >
                {content.user_avatar ? (
                  <Avatar
                    className="comment-user-avatar"
                    size={{
                      xs: 32,
                      sm: 40,
                      md: 48,
                      lg: 48,
                      xl: 48,
                      xxl: 48,
                    }}
                    src={content.user_avatar}
                  />
                ) : (
                  <Avatar
                    className="comment-user-avatar"
                    size={{
                      xs: 32,
                      sm: 40,
                      md: 48,
                      lg: 48,
                      xl: 48,
                      xxl: 48,
                    }}
                    icon={<UserOutlined />}
                  />
                )}
                <div className="comment-body">
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div
                      className="comment-user-name"
                      style={{ color: "gray", display: "flex" }}
                    >
                      <span>{content.user_name}</span>
                      {!!content.author && (
                        <span className="comment-author">作者</span>
                      )}
                      <CaretRightOutlined />
                      <span>{content.reply_user_name}</span>
                      {!!content.reply_author && (
                        <span className="comment-author">作者</span>
                      )}
                    </div>
                    <div className="comment-date">
                      {moment(content.created_at).format("YYYY-MM-DD HH:mm")}
                    </div>
                  </div>
                  <div className="comment-content">{content.content}</div>
                  <div className="comment-icon">
                    <div
                      className={`comment-like-button sub-comment${index} ${sub_index}`}
                      style={content.active ? { color: "#5995DA" } : {}}
                      onClick={() => {
                        likesButton(
                          content.user_id,
                          content.delete,
                          content.id,
                          index,
                          sub_index
                        );
                      }}
                    >
                      <Tooltip title="点赞">
                        <LikeOutlined />
                        <span
                          className={`likes-cnt sub-comment${index} ${sub_index}`}
                          style={{
                            fontSize: "14px",
                            marginLeft: "5px",
                          }}
                        >
                          {content.likes}
                        </span>
                      </Tooltip>
                    </div>
                    <div
                      className="comment-subcomment-button"
                      onClick={() => {
                        let subcommentInputDom =
                          document.getElementsByClassName(
                            `subcomment-input sub-comment${index} ${sub_index}`
                          )[0];
                        if (subcommentInputDom.style.display !== "flex") {
                          subcommentInputDom.style.display = "flex";
                        } else {
                          subcommentInputDom.style.display = "none";
                        }
                      }}
                    >
                      <Tooltip title="评论">
                        <MessageOutlined />
                        <span style={{ fontSize: "14px", marginLeft: "5px" }}>
                          评论
                        </span>
                      </Tooltip>
                    </div>
                    {content.delete && (
                      <div
                        className="comment-delete-button"
                        onClick={() => {
                          Modal.confirm({
                            title: "是否确认删除评论？",
                            okType: "primary",
                            okText: "确定",
                            cancelText: "取消",
                            onOk: async function () {
                              httpclient.deleteComment(content.id).then(() => {
                                if (data_type === "zhuanlan")
                                  window.location.href =
                                    "/zhuanlans/" +
                                    data_id +
                                    "/?selected=comments";
                                else if (data_type === "chapter")
                                  window.location.href = "/chapters/" + data_id;
                                else if (data_type === "discuss")
                                  window.location.href = "/discuss/detail/" + data_id;
                              });
                            },
                          });
                        }}
                      >
                        <Tooltip title="删除">
                          <DeleteOutlined />
                        </Tooltip>
                      </div>
                    )}
                  </div>
                  {localStorage.token &&
                    userInfo && type !== "preview" &&
                    ((data_type === "zhuanlan" && !data.locked) ||
                      (data_type === "chapter" &&
                        ((data.free && !data.zhuanlan_locked) ||
                          (!data.free && !data.locked))) || (data_type === "discuss")) && (
                      <div
                        className={`subcomment-input sub-comment${index} ${sub_index}`}
                      >
                        <TextArea
                          autoSize={{
                            minRows: 1,
                            maxRows: 5,
                          }}
                          style={{
                            width: "calc(100% - 100px)",
                            marginRight: "20px",
                          }}
                          placeholder={`回复给${content.user_name}`}
                          maxLength={1000}
                          onChange={(e) => {
                            inputContent[content.id] = e.target.value
                          }}
                        />
                        <div
                          className="subcomment-submit-button"
                          onClick={() => {
                            if (!inputContent[content.id]) {
                              notification.error({
                                message: "错误提示",
                                description: "输入内容为空，请重新输入",
                              });
                            } else if (!userInfo.name) {
                              Modal.confirm({
                                content: (
                                  <Input
                                    placeholder={"请输入姓名或昵称"}
                                    onChange={(e) => {
                                      username = e.target.value;
                                    }}
                                  />
                                ),
                                title: "评论前需要输入您的姓名或昵称",
                                cancelText: "取消",
                                okText: "确定",
                                autoFocusButton: "cancel",
                                onOk: function () {
                                  if (username) {
                                    httpclient
                                      .updateUserName(username)
                                      .then(() => {
                                        updateUserInfo();
                                      });
                                  } else {
                                    notification.error({
                                      message: "错误提示",
                                      description: "输入内容为空，请重新输入",
                                    });
                                  }
                                },
                              });
                            } else {
                              httpclient
                                .addComment(
                                  inputContent[content.id],
                                  "comment",
                                  content.id
                                )
                                .then(() => {
                                  if (!content.delete)
                                    socket.emit("inform", content.user_id);
                                  Modal.success({
                                    content: "评论成功",
                                    okText: "确定",
                                    onOk: function () {
                                      window.location.reload();
                                    },
                                  });
                                });
                            }
                          }}
                        >
                          提交
                        </div>
                      </div>
                    )}
                </div>
              </div>
            );
          });
        }
        active[content.id] = content.active;
        likes[content.id] = content.likes;
        return (
          <div className={`comment-item ${content.id}`}>
            {content.user_avatar ? (
              <Avatar
                className="comment-user-avatar"
                size={{
                  xs: 32,
                  sm: 40,
                  md: 48,
                  lg: 48,
                  xl: 48,
                  xxl: 48,
                }}
                src={content.user_avatar}
              />
            ) : (
              <Avatar
                className="comment-user-avatar"
                size={{
                  xs: 32,
                  sm: 40,
                  md: 48,
                  lg: 48,
                  xl: 48,
                  xxl: 48,
                }}
                icon={<UserOutlined />}
              />
            )}
            <div className="comment-body">
              <div style={{ display: "flex", alignItems: "center" }}>
                <div className="comment-user-name">{content.user_name}</div>
                {!!content.author && <div className="comment-author">作者</div>}
                <div className="comment-date">
                  {moment(content.created_at).format("YYYY-MM-DD HH:mm")}
                </div>
                {!!content.select && (
                  <div className="comment-sticky-topic">置顶</div>
                )}
              </div>
              <div className="comment-content">{content.content}</div>
              <div className="comment-icon">
                <div
                  className={`comment-like-button comment ${content.id}`}
                  style={active[content.id] ? { color: "#5995DA" } : {}}
                  onClick={() => {
                    likesButton(
                      content.user_id,
                      content.delete,
                      content.id,
                      index
                    );
                  }}
                >
                  <Tooltip title="点赞">
                    <LikeOutlined />
                    <span
                      className={`likes-cnt comment ${content.id}`}
                      style={{ fontSize: "14px", marginLeft: "5px" }}
                    >
                      {likes[content.id]}
                    </span>
                  </Tooltip>
                </div>
                <div
                  className="comment-subcomment-button"
                  onClick={() => {
                    let sub_comments = document.getElementsByClassName(
                      `comment-subcomment ${content.id}`
                    )[0];
                    if (sub_comments.style.display === "block") {
                      sub_comments.style.display = "none";
                    } else {
                      sub_comments.style.display = "block";
                    }
                  }}
                >
                  <Tooltip title="评论">
                    <MessageOutlined />
                    <span
                      style={{
                        fontSize: "14px",
                        marginLeft: "5px",
                      }}
                    >
                      {content.sub_comments.length}
                    </span>
                  </Tooltip>
                </div>
                {content.delete && (
                  <div
                    className="comment-delete-button"
                    onClick={() => {
                      Modal.confirm({
                        title: "是否确认删除评论？",
                        okType: "primary",
                        okText: "确定",
                        cancelText: "取消",
                        onOk: async function () {
                          httpclient.deleteComment(content.id).then(() => {
                            if (data_type === "zhuanlan")
                              window.location.href =
                                "/zhuanlans/" + data_id + "/?selected=comments";
                            else if (data_type === "chapter")
                              window.location.href = "/chapters/" + data_id;
                            else if (data_type === "discuss")
                              window.location.href = "/discuss/detail/" + data_id;
                          });
                        },
                      });
                    }}
                  >
                    <Tooltip title="删除">
                      <DeleteOutlined />
                    </Tooltip>
                  </div>
                )}
              </div>
              {!!content.author_comment && (
                <div className="comment-author-comment">
                  作者回复：{content.author_comment.content}
                </div>
              )}
              <div
                className={`comment-subcomment ${content.id}`}
                style={
                  +content.id === +default_expand_id
                    ? { display: "block" }
                    : { display: "none" }
                }
              >
                {sub_comments}
                {localStorage.token &&
                  userInfo && type !== "preview" &&
                  ((data_type === "zhuanlan" && !data.locked) ||
                    (data_type === "chapter" &&
                      ((data.free && !data.zhuanlan_locked) ||
                        (!data.free && !data.locked))) || (data_type === "discuss")) && (
                    <div
                      style={{
                        display: "flex",
                        marginTop: "20px",
                        marginBottom: "10px",
                        alignItems: "flex-end",
                      }}
                    >
                      <TextArea
                        autoSize={{
                          minRows: 1,
                          maxRows: 5,
                        }}
                        style={{
                          width: "calc(100% - 90px)",
                          marginRight: "20px",
                        }}
                        placeholder={`回复给${content.user_name}`}
                        maxLength={1000}
                        onChange={(e) => {
                          inputContent[content.id] = e.target.value
                        }}
                      />

                      <div
                        className="comment-subcomment-submit-button"
                        onClick={() => {
                          if (!inputContent[content.id]) {
                            notification.error({
                              message: "错误提示",
                              description: "输入内容为空，请重新输入",
                            });
                          } else if (!userInfo.name) {
                            Modal.confirm({
                              content: (
                                <Input
                                  placeholder={"请输入姓名或昵称"}
                                  onChange={(e) => {
                                    username = e.target.value;
                                  }}
                                />
                              ),
                              title: "评论前需要输入您的姓名或昵称",
                              cancelText: "取消",
                              okText: "确定",
                              autoFocusButton: "cancel",
                              onOk: function () {
                                if (username) {
                                  httpclient
                                    .updateUserName(username)
                                    .then(() => {
                                      updateUserInfo();
                                    });
                                } else {
                                  notification.error({
                                    message: "错误提示",
                                    description: "输入内容为空，请重新输入",
                                  });
                                }
                              },
                            });
                          } else {
                            httpclient
                              .addComment(
                                inputContent[content.id],
                                "comment",
                                content.id
                              )
                              .then(() => {
                                if (!content.delete)
                                  socket.emit("inform", content.user_id);
                                Modal.success({
                                  content: "评论成功",
                                  okText: "确定",
                                  onOk: function () {
                                    window.location.reload();
                                  },
                                });
                              });
                          }
                        }}
                      >
                        提交
                      </div>
                    </div>
                  )}
              </div>
            </div>
          </div>
        );
      });
  if (data) {
    return (
      <div className="comments">
        {localStorage.token &&
          userInfo && type !== "preview" &&
          ((data_type === "zhuanlan" && !data.locked) ||
            (data_type === "chapter" &&
              ((data.free && (!data.zhuanlan_locked)) ||
                (!data.free && !data.locked))) || (data_type === "discuss")) && (
            <div className="comments-input">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "20px",
                }}
              >
                {userInfo.avatar ? (
                  <Avatar
                    className="comments-input-avatar"
                    size={{
                      xs: 32,
                      sm: 40,
                      md: 48,
                      lg: 48,
                      xl: 48,
                      xxl: 48,
                    }}
                    src={userInfo.avatar}
                  />
                ) : (
                  <Avatar
                    className="comments-input-avatar"
                    size={{
                      xs: 32,
                      sm: 40,
                      md: 48,
                      lg: 48,
                      xl: 48,
                      xxl: 48,
                    }}
                    icon={<UserOutlined />}
                  />
                )}
                <div className="comments-input-user">
                  {userInfo.name ? userInfo.name : "匿名用户"}
                </div>
              </div>
              <div className="comments-input-textarea">
                <TextArea
                  autoSize={{
                    minRows: 4,
                    maxRows: 6,
                  }}
                  placeholder="通过审核的评论将会公开展示，请输入您的评论内容"
                  showCount
                  maxLength={1000}
                  onChange={(e) => {
                    //inputContent[0] = e.target.value;
                    input = e.target.value
                  }}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                <span
                  className="comments-submit-button"
                  onClick={() => {
                    if (!input) {
                      notification.error({
                        message: "错误提示",
                        description: "输入内容为空，请重新输入",
                      });
                    } else if (!userInfo.name) {
                      Modal.confirm({
                        content: (
                          <Input
                            placeholder={"请输入姓名"}
                            onChange={(e) => {
                              username = e.target.value;
                            }}
                          />
                        ),
                        title: "评论前需要输入您的姓名",
                        cancelText: "取消",
                        okText: "确定",
                        autoFocusButton: "cancel",
                        onOk: function () {
                          if (username) {
                            httpclient.updateUserName(username).then(() => {
                              updateUserInfo();
                            });
                          } else {
                            notification.error({
                              message: "错误提示",
                              description: "输入内容为空，请重新输入",
                            });
                          }
                        },
                      });
                    } else {
                      httpclient
                        .addComment(input, data_type, data_id)
                        .then(() => {
                          if (data_type === "zhuanlan")
                            socket.emit("inform", data.zhuanlan.author_id);
                          else if (data_type === "chapter")
                            socket.emit("inform", data.zhuanlan_author_id);
                          Modal.success({
                            content: "评论成功",
                            okText: "确定",
                            onOk: function () {
                              window.location.reload();
                            },
                          });
                        });
                    }
                  }}
                >
                  提交评论
                </span>
              </div>
            </div>
          )}
        {type !== "preview" && <span className="comments-title">精选评论</span>}
        {comments?.length ? (
          <div>{comments}</div>
        ) : (
          <div>
            <Empty
              description="暂无评论"
              image={Empty.PRESENTED_IMAGE_SIMPLE}
            />
          </div>
        )}
        {data.comments_preview && (
          <div className="comments-preview">更多评论请解锁专栏后观看……</div>
        )}
      </div>
    );
  }
}

export default Comments;
