import React from "react";
import "./modal.scss";
import ReactDOM from 'react-dom';
import {CloseCircleOutlined} from '@ant-design/icons';

function PopModal(config) {
    const modalRoot = document.createElement('div');
    document.body.appendChild(modalRoot);
    const Modal = () => {
        return (
            <div className="modal-wrapper">
                <div className="modal-layer"></div>
                <div style={{width: config.width + "px",top:config.top + "px",borderRadius:config.borderRadius + "px"}} className="modal-card">
                    {config.closeButtonPosition === "top" ? <div className="close-button-top" onClick={() =>{if(config.onClose){config.onClose()}ReactDOM.unmountComponentAtNode(modalRoot);document.body.removeChild(modalRoot)}}><CloseCircleOutlined /></div>
                    : <div className="close-button-bottom" style={{left: "calc("+config.width/2 +"px - 10px)"}} onClick={() =>{if(config.onClose){config.onClose()}ReactDOM.unmountComponentAtNode(modalRoot);document.body.removeChild(modalRoot)}}><CloseCircleOutlined /></div>}
                    {!!config.title && <div className="modal-title"><div className="modal-title-message">{config.title}</div></div>}
                    {!!config.body && <div className="modal-body"><div>{config.body}</div><div onClick={() => {if(config.onClose){config.onClose()}ReactDOM.unmountComponentAtNode(modalRoot);document.body.removeChild(modalRoot)}}>{config.closeText}</div></div>}
                    {!!(config.onCancel || config.onOk) && <div className="modal-button" style={config.buttonPos === 'center' ? {display:'flex',justifyContent:'center',float:'none'} : {}}>
                        {!!config.onCancel && <div className="cancel-button" onClick={() => {if(config.onClose){config.onClose()};config.onCancel();ReactDOM.unmountComponentAtNode(modalRoot);document.body.removeChild(modalRoot)}}>{config.cancelText || "Cancel"}</div>}
                        {!!config.onOk && <div className="ok-button" onClick={() => {if(config.onClose){config.onClose()};config.onOk();ReactDOM.unmountComponentAtNode(modalRoot);document.body.removeChild(modalRoot)}}>{config.okText || "Ok"}</div>}
                    </div>}
                </div>
            </div>
        )
    }
    ReactDOM.render(<Modal/>, modalRoot);
}

export default PopModal;
