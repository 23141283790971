import { useState } from "react";

function useCommentsExpandControl() {
  const [expandedComments, setExpandedComments] = useState(new Set());
  const isExpanded = (commentId) => expandedComments.has(commentId);
  const toggleExpand = (commentId) =>
    setExpandedComments((set) => {
      const newSet = new Set(set);
      newSet.has(commentId) ? newSet.delete(commentId) : newSet.add(commentId);
      return newSet;
    });
  return { expandedComments, setExpandedComments, isExpanded, toggleExpand };
}

export default useCommentsExpandControl;
