import React, { useEffect, useState } from "react";
import { Empty } from 'antd';
import "./my_questions.scss";

function MyQuestions(props) {

  return (
    <div className="my-questions-wrapper">
      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="暂无内容"/>
    </div>
  );

}

export default MyQuestions;
