import React, {useState} from "react";
import ReactDOM from "react-dom";
import "./drawer.scss";

function PopDrawer(config) {
    const drawerRoot = document.createElement('div');
    document.body.appendChild(drawerRoot);
    const Drawer = () =>{
        return (
            <div className="drawer-wrapper">
                <div className="drawer-layer"></div>
                <div style={{width:config.width}} className="drawer-card">
                    <div className="drawer-title">{config.title}</div>
                    <div className="drawer-body">{config.body}</div>
                    <div className="drawer-footer" onClick={() => {ReactDOM.unmountComponentAtNode(drawerRoot);document.body.removeChild(drawerRoot)}}>{config.footer}</div>
                </div>
            </div>
        )
    }
    ReactDOM.render(<Drawer/>, drawerRoot);
}

export default PopDrawer;