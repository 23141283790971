import { Avatar, Empty, Tooltip, Typography } from "antd";
import { UserOutlined, WomanOutlined, ManOutlined } from "@ant-design/icons";
import "./general.scss";
import { useEffect, useState } from "react";
import httpclient from "../../../../service/httpclient";
import UserInfoEditor from "../../../user/userinfo-editor";
import { NavLink } from "react-router-dom";

const { Paragraph } = Typography;

function General(props) {
  const [userInfo, setUserInfo] = useState({});
  const [courses, setCourses] = useState([]);

  useEffect(() => {
    httpclient.getCourses().then((response) => {
      setCourses(response);
    });
  }, []);

  useEffect(() => {
    httpclient.getUserInfo().then((data) => {
      if (data) {
        setUserInfo(data);
      }
    });
  }, []);

  return (
    <div className="general-wrapper">
      <div className="grid-item info">
        <UserInfoEditor
          userInfo={userInfo}
          refreshUserInfo={(data) => setUserInfo(data)}
        />
        <div className="info-wrapper">
          <div className="info-avatar">
            <Avatar
              size={64}
              icon={
                userInfo.avatar ? (
                  <img src={userInfo.avatar} />
                ) : (
                  <UserOutlined />
                )
              }
            />
          </div>
          <div className="info-basic">
            <div className="info-basic-name">
              {userInfo.name || userInfo.mobile}
              {!!userInfo.gender &&
                (userInfo.gender == "男" ? (
                  <ManOutlined style={{ color: "rgb(73, 106, 198)" }} />
                ) : (
                  <WomanOutlined style={{ color: "rgb(228, 83, 125)" }} />
                ))}
            </div>
            <div className="info-basic-birthday">{userInfo.birthday}</div>
          </div>
        </div>
        <div className="info-more-wrapper">
          <div className="one-info">
            <span className="info-more-label">居住地</span>
            <span className="info-more-content">
              {userInfo.address || <>待完善</>}
            </span>
          </div>
          <div className="one-info">
            <span className="info-more-label">学历</span>
            <span className="info-more-content">
              {userInfo.education || <>待完善</>}
            </span>
          </div>
          <div className="one-info">
            <span className="info-more-label">目前所在单位/学校</span>
            <span className="info-more-content">
              {userInfo.school || <>待完善</>}
            </span>
            <span
              className="info-more-label recommend"
              style={{ display: "flex", alignItems: "center" }}
            >
              <Tooltip title="分享引荐码给好友，新用户首次购买可享5折优惠，您也可获得50元抵扣券（适用于引荐人和新用户都购买成功情况）">
                <span>
                  <img
                    style={{ width: "22px", marginRight: "4px" }}
                    src={
                      "https://cdn.datongbei.com/icdesign-17014217294306b8h88a3cb07.png?imageView2/0/format/jpg/q/90|imageslim"
                    }
                  />
                </span>
                优惠引荐码:{" "}
              </Tooltip>
              <span className="info-more-content">
                <Paragraph copyable>
                  {userInfo?.note_token?.slice(0, 6)}
                </Paragraph>
              </span>
            </span>
          </div>
        </div>
        <div className="resume-complete">
          <span className="resume-complete-content">
            完善个人资料可免费生成简历
          </span>
          <NavLink to="/user/center/my-resume/view">
            <span className="resume-complete-button">去完善</span>
          </NavLink>
        </div>
      </div>
      <div className="grid-item courses">
        <div className="general-title">正在学习</div>
        <div className="general-content-wrapper">
          {courses.length !== 0 ? (
            courses.map((course) => (
              <NavLink to={course.endpoint}>
                <div className="one-course">
                  <div className="course-title">{course.name}</div>
                  {/* <div className='course-progress'>
                    <div className='course-progress-description'>
                      进度：8/14章
                    </div>
                    <div className='course-progress-percent'>
                      60%
                    </div>
                  </div> */}
                </div>
              </NavLink>
            ))
          ) : (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description="空空如也"
            />
          )}
        </div>
      </div>
      {/*<div className="grid-item questions">*/}
      {/*  <div className="general-title">*/}
      {/*    我的提问*/}
      {/*  </div>*/}
      {/*  <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="暂无数据"/>*/}
      {/*</div>*/}
      {/* <div className="grid-item account">
        <div className="general-title">
          我的账户
        </div>
        <div className="general-content-wrapper">
          <div className='account-balance'>
            账户余额：
            {(!!userInfo.account || userInfo.account === 0) && (
              <span className='money'>
                <span className='yuan'>
                ￥{Math.floor(userInfo.account/100)}.
                </span>
                <span className='cent'>
                  {userInfo.account % 100 > 9 ? userInfo.account % 100 : '0' + userInfo.account % 100}
                </span>
              </span>
            )}
          </div>
        </div>
      </div> */}
    </div>
  );
}

export default General;
