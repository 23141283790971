import React, {useState} from "react";
import httpclient from "../../service/httpclient";
import ScopeJudge from "../scopejudge";
import PubSub from "pubsub-js";
import {Input, notification, Select} from "antd";
import {smsCodeData} from "../../service/sms";
import './logindrawer.scss';
import {Countdown} from "react-countdown-hoc";
import {MobileOutlined,MailOutlined} from "@ant-design/icons";
const { Option } = Select;

class SmsCodeSelector extends React.Component{
    render() {
        return <Select  value={this.props.value} className="select-before" onChange={e=>this.props.onChange(e)}>
            {smsCodeData.map(item => <Option value={item.code} key={item.code}>{item.name} {item.code}</Option>)}
        </Select>
    }
}

function LoginDrawer(){
    const[loading,setLoading] = useState(false);
    const [mobile, setMobile] = useState(null);
    const [smsCode, setSmsCode] = useState("+86");
    const [smsRequired, setSmsRequired] = useState(false);
    const [policyChecked, setPolicyChecked] = useState(false);
    const [smsVerificationCode, setSmsVerificationCode] = useState(null);
    function getMobile() {
        if(mobile == null){
            return null;
        }

        if (smsCode.trim() === "+86") {
            return mobile.trim();
        } else {
            return smsCode.trim() + mobile.trim();
        }
    }

    function doLogin() {
        httpclient
            .Login(getMobile(), smsVerificationCode, ScopeJudge())
            .then((response) => {
                if (response) {
                    localStorage.setItem("token",response.token);
                    //  localStorage.token = response.token;
                    httpclient.getUserInfo().then((response) => {
                        // localStorage.note_token = response.note_token;
                        localStorage.setItem("note_token",response.note_token);
                    })
                    let msg = {"data":true}
                    PubSub.publish("loggedIn",msg);
                    notification.info({
                        description: "欢迎，" + getMobile(),
                        message: "登录成功",
                    });
                    setTimeout(function () {
                        window.location.reload();
                    }, 1000);
                }
            });
    }
    return(
        <div>
            {!smsRequired ? <div>
                    <Input key="mobile" addonBefore={<SmsCodeSelector value={smsCode} onChange={value=>setSmsCode(value)}/>}
                   placeholder="请输入手机号" onChange={(e) => setMobile(e.currentTarget.value)} />
                {!loading ? <div className="logindrawer-sms-button" onClick={() => {
                // if(policyChecked){
                setLoading(true);
                httpclient.getVerificationCode(getMobile()).then(()=>{
                    setLoading(false);
                    setSmsRequired(true);
                })
                    .catch(() => {
                        setLoading(false);
                    });
                // }
            }}>获取验证码</div>:
                    <div className="logindrawer-sms-button-loading">
                       获取验证码
                    </div>}
            </div> :
                <div>
                    <div className="logindrawer-sms-wrapper">
                    <Input maxLength={6}  className="logindrawer-input-sms" key="sms" placeholder="请输入验证码" onChange={(e) => setSmsVerificationCode(e.currentTarget.value)}  />
                    <Countdown intervalSecond={1} duration={80} autoTrigger={true}>
                        {(isCounting, restTime, startCount) => (
                            <>
                                {isCounting ? (
                                    <span className="logindrawer-disabled-button">
                        重新发送({restTime}s)
                      </span>
                                ) : (
                                    <span
                                        className="logindrawer-button"
                                        onClick={() => {
                                            httpclient.getVerificationCode(getMobile()).then((response)=>{
                                                startCount();
                                            });
                                        }}
                                    >
                        获取验证码
                      </span>
                                )}
                            </>
                        )}
                    </Countdown>
                </div>
                <div className="logindrawer-login-button" onClick={() => doLogin()}>
                    登录
                </div>
                </div>
            }
        </div>
    )
}

export default LoginDrawer;