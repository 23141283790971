import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router";
import httpclient from "../../service/httpclient";
import { NavLink } from "react-router-dom";
import "./testpaper_collection.scss";
import routeUrls from "../../route/routeurls";
import { faLock, faShoppingCart } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TestpaperList from "../../components/lists/testpaperlist";

function TestPaperCollection() {
  const [TestPaperCollectionData, setTestPaperCollectionData] = useState(null);
  const [TestPaperCollectionPossession,setTestPaperCollectionPossession] = useState(false);
  const [renewMode,setRenewMode] = useState(false);
  const testpaper_collectionid = useParams().testpaper_collectionid;
  useEffect(() => {
    if(localStorage.token){
      httpclient.possessTestpaperCollection(testpaper_collectionid).then((response) => {
          console.log(response);
          setRenewMode(response.time_expired);
        setTestPaperCollectionPossession(response.result);
      })
      httpclient
          .getTestPaperCollectionInfo(testpaper_collectionid)
          .then((data) => {
            setTestPaperCollectionData(data);
          });
    }else{
      httpclient
          .getTestPaperCollectionData(testpaper_collectionid)
          .then((data) => {
            setTestPaperCollectionData(data);
          });
    }
  }, [testpaper_collectionid]);
  const testpaperlist = useMemo(() => {
      console.log(TestPaperCollectionData);
   return TestpaperList(TestPaperCollectionData);
  }, [TestPaperCollectionData]);
  return (
    <div className="testpaper-collection">
      <div className="testpaper-collection-wrapper">
        <div className="testpaper-collection-name-wrapper">
          <div className="testpaper-collection-name">
            {!!TestPaperCollectionData && TestPaperCollectionData.name}
          </div>
        </div>
        <div className="testpaper-collection-intro-wrapper">
          <div className="testpaper-collection-intro">
            {!!TestPaperCollectionData &&
              TestPaperCollectionData.introduction_text}
          </div>
          {!!(TestPaperCollectionData && !TestPaperCollectionPossession) &&
          (
              <div className="purchase-wrapper">
                <div className="price">RMB{TestPaperCollectionData.price/100}/年</div>
                <div className="purchase-frequency">(已有{TestPaperCollectionData.purchase_cnt}人购买)</div>
                <NavLink to={`/checkout/testpaper_collection/${testpaper_collectionid}${renewMode ? '/renew' : ''}`} className="purchase-button">
                    <FontAwesomeIcon icon={faShoppingCart}/> 合集购买
                </NavLink>
              </div>
          )}
        </div>
      </div>
      <div className="testpaper-list-wrapper">{testpaperlist}</div>
    </div>
  );
}

export default TestPaperCollection;
