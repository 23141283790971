import { createContext, useContext, useState } from "react";
import useCommentsDataAndActions from "./useCommentsDataAndActions";
import moment from "moment";
import UserAvatar from "../user/UserAvatar";
import { Empty, Space, Spin } from "antd";
import Username from "../user/Username";
import {
  CaretRightOutlined,
  DeleteOutlined,
  LikeOutlined,
  MessageOutlined,
} from "@ant-design/icons";
import DebouncingButton from "../DebouncingButton/DebouncingButton";
import { UserContext } from "../../App";
import TextArea from "antd/lib/input/TextArea";
import "./Comments.scss";
import useCommentsExpandControl from "./useCommentsExpandControl";

const defaultRenderers = {
  CommentsLayout,
  CommentInput,
  CommentsList,
  CommentsListTitle,
  CommentItem,
  Header,
  Content,
  ActionBar,
  CommentInputAvatar: Avatar,
  CommentItemAvatar: Avatar,
  Name,
  Time,
  ReplyBar,
};

const defaultActionItems = (commentsContext, commentItemContext, userInfo) => {
  const { toggleLike, toggleExpand, deleteComment } = commentsContext;
  const { comment, parent_comment } = commentItemContext;
  return [
    {
      key: "like",
      access: true,
      render: () => (
        <Space
          size={6}
          style={comment.liked ? { color: "#5995da" } : undefined}
        >
          <LikeOutlined />
          {comment.likes_cnt}
        </Space>
      ),
      onClick: () => toggleLike(comment.liked, comment.id),
    },
    {
      key: "comment",
      access: true,
      render: () => (
        <Space size={6}>
          <MessageOutlined />
          {parent_comment ? "评论" : comment.all_sub_comments_cnt}
        </Space>
      ),
      onClick: () => toggleExpand(comment.id),
    },
    {
      key: "delete",
      access:
        !!userInfo &&
        (comment.user.id === userInfo.id || userInfo.role === "admin"),
      render: () => <DeleteOutlined />,
      onClick: () => deleteComment(comment.id),
    },
  ];
};

const CommentsContext = createContext({ Renderers: defaultRenderers });
const useCommentsContext = () => useContext(CommentsContext);
const CommentItemContext = createContext({
  depth: 0,
  comment: null,
  parent_comment: null,
});
const useCommentItemContext = () => useContext(CommentItemContext);

function Comments({ data_type, data_id, decorators = [], onChange }) {
  const commentsDataAndActions = useCommentsDataAndActions(
    data_type,
    data_id,
    onChange
  );
  const commentsExpandControl = useCommentsExpandControl();

  return (
    <CommentsContext.Provider
      value={{
        data_type,
        data_id,
        ...commentsDataAndActions,
        ...commentsExpandControl,
        actionItems: defaultActionItems,
        Renderers: defaultRenderers,
      }}
      children={decorators.reduceRight(
        (Component, decorator) => (
          <decorator.Component {...decorator.props} children={Component} />
        ),
        <RenderEntry />
      )}
    />
  );
}

function RenderEntry() {
  const {
    Renderers: { CommentsLayout },
  } = useCommentsContext();

  return <CommentsLayout />;
}

function CommentsLayout() {
  const {
    Renderers: { CommentInput, CommentsList },
  } = useCommentsContext();

  return (
    <div className="_comments-layout">
      <CommentInput />
      <CommentsList />
    </div>
  );
}

function CommentInput() {
  const {
    data_type,
    data_id,
    submitComment,
    Renderers: { CommentInputAvatar, Name },
  } = useCommentsContext();
  const [content, setContent] = useState("");
  const { userInfo } = useContext(UserContext);

  return (
    <div className="_comment-input">
      <div>
        <Space align="center">
          <CommentInputAvatar user={userInfo} />
          <Name user={userInfo} />
        </Space>
      </div>
      <TextArea
        className="_comment-input-textarea"
        autoSize={{
          minRows: 4,
          maxRows: 6,
        }}
        placeholder="通过审核的评论将会公开展示，请输入您的评论内容"
        showCount
        maxLength={1000}
        value={content}
        onChange={(e) => setContent(e.target.value)}
      />
      <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
        <DebouncingButton
          type="primary"
          onClick={(loading, setLoding) => {
            if (loading) return;
            setLoding(true);
            submitComment(data_type, data_id, content)
              .then(() => setContent(""))
              .finally(() => setLoding(false));
          }}
        >
          提交评论
        </DebouncingButton>
      </div>
    </div>
  );
}

function CommentsList() {
  const {
    comments,
    Renderers: { CommentsListTitle, CommentItem },
  } = useCommentsContext();
  return (
    <div className="_comments-list">
      <CommentsListTitle />
      {comments === null ? (
        <div style={{ textAlign: "center" }}>
          <Spin />
        </div>
      ) : comments.length === 0 ? (
        <Empty description="暂无评论" image={Empty.PRESENTED_IMAGE_SIMPLE} />
      ) : (
        comments.map((comment) => (
          <CommentItem key={comment.id} comment={comment} />
        ))
      )}
    </div>
  );
}

function CommentsListTitle() {
  return <div className="_comments-list-title">精选评论</div>;
}

function CommentItem({ comment }) {
  const {
    isExpanded,
    Renderers: { CommentItemAvatar, Header, Content, ActionBar },
  } = useCommentsContext();
  const { depth: parent_depth, comment: parent_comment } =
    useCommentItemContext();
  // depth指嵌套深度
  const depth = parent_depth + 1;

  return (
    <CommentItemContext.Provider value={{ depth, comment, parent_comment }}>
      <div className="_comment-item" data-id={comment.id}>
        <CommentItemAvatar user={comment.user} />
        <div className="_comment-item-main">
          <Header />
          <Content />
          <ActionBar />
          {isExpanded(comment.id) && <ReplyBar />}
          {isExpanded(comment.id) &&
            depth === 1 &&
            comment.direct_sub_comments.map((comment) => (
              <CommentItem
                key={comment.id}
                comment={comment}
                depth={depth + 1}
              />
            ))}
        </div>
      </div>
      {/* 嵌套两层及以上的评论默认在下方展开罗列 */}
      {depth > 1 &&
        comment.direct_sub_comments.map((comment) => (
          <CommentItem key={comment.id} comment={comment} depth={depth + 1} />
        ))}
    </CommentItemContext.Provider>
  );
}

function Header() {
  const {
    Renderers: { Name, Time },
  } = useCommentsContext();
  const { comment, parent_comment } = useCommentItemContext();

  return (
    <div className="_comment-header">
      <Space size={[12, 0]} style={{ flexWrap: "wrap" }}>
        <Space size={4}>
          <Name user={comment.user} />
          {parent_comment && (
            <>
              <CaretRightOutlined />
              <Name user={parent_comment.user} />
            </>
          )}
        </Space>
        <Time time={comment.created_at} />
      </Space>
    </div>
  );
}

function Content() {
  const { comment } = useCommentItemContext();

  return <div className="_comment-content">{comment.content}</div>;
}

function ActionBar() {
  const commentsCtx = useCommentsContext();
  const commentItemCtx = useCommentItemContext();
  const { userInfo } = useContext(UserContext);
  const actionItems = commentsCtx.actionItems(
    commentsCtx,
    commentItemCtx,
    userInfo
  );

  return (
    <div className="_comment-action-bar">
      <Space size={12}>
        {actionItems.map(
          (item) =>
            !!item.access && (
              <span
                key={item.key}
                className="_comment-action-item"
                onClick={item.onClick}
              >
                {item.render()}
              </span>
            )
        )}
      </Space>
    </div>
  );
}

function Avatar({ user }) {
  return (
    <div className="_comment-avatar">
      <UserAvatar avatar={user?.avatar} />
    </div>
  );
}

function Name({ user }) {
  return (
    <span className="_comment-username">
      <Username username={user?.name} />
    </span>
  );
}

function Time({ time }) {
  return (
    <span className="_comment-time">
      {moment(time).format("YYYY-MM-DD HH:mm")}
    </span>
  );
}

function ReplyBar() {
  const { submitComment } = useCommentsContext();
  const { comment } = useCommentItemContext();
  const [content, setContent] = useState("");

  return (
    <div className="_comment-reply-bar">
      <TextArea
        value={content}
        onChange={(e) => setContent(e.target.value)}
        autoSize={{
          minRows: 1,
          maxRows: 5,
        }}
        placeholder={`回复给${Username({ username: comment.user.name })}`}
        maxLength={1000}
      />
      <DebouncingButton
        type="primary"
        onClick={(loading, setLoding) => {
          if (loading) return;
          setLoding(true);
          submitComment("comment", comment.id, content)
            .then(() => setContent(""))
            .finally(() => setLoding(false));
        }}
      >
        提交
      </DebouncingButton>
    </div>
  );
}

export default Comments;
export {
  CommentsContext,
  useCommentsContext,
  CommentItemContext,
  useCommentItemContext,
};
