import { Button, Modal, Tooltip, message } from "antd";
import {
  CommentsContext,
  useCommentItemContext,
  useCommentsContext,
} from "../../Comments";
import { useContext } from "react";
import { UserContext } from "../../../../App";
import httpclient from "../../../../service/httpclient";

function BestAnswerDecorator({
  children,
  authorId,
  refresh,
  bestAnswerCommentId,
}) {
  const commentsCtx = useCommentsContext();

  const newRenderers = { ...commentsCtx.Renderers };
  newRenderers.OriginalCommentItemAvatar =
    commentsCtx.Renderers.CommentItemAvatar;
  newRenderers.CommentItemAvatar = CommentItemAvatarWithBestAnswerIcon;

  return (
    <CommentsContext.Provider
      value={{
        ...commentsCtx,
        authorId,
        refresh,
        bestAnswerCommentId,
        Renderers: newRenderers,
      }}
      children={children}
    />
  );
}

function CommentItemAvatarWithBestAnswerIcon({ user }) {
  const {
    authorId,
    bestAnswerCommentId,
    Renderers: { OriginalCommentItemAvatar },
  } = useCommentsContext();
  const { depth, comment } = useCommentItemContext();
  const { userInfo } = useContext(UserContext);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: 20,
        width: 40,
        flexGrow: 0,
      }}
    >
      <OriginalCommentItemAvatar user={user} />
      {depth === 1 && bestAnswerCommentId === comment.id && <BestAnswerIcon />}
      {depth === 1 &&
        bestAnswerCommentId !== comment.id &&
        userInfo.id === authorId && <TakeBestAnswerBtn />}
    </div>
  );
}

function BestAnswerIcon() {
  return (
    <Tooltip title="作者采纳了这条回答" placement="topLeft">
      <svg
        aria-hidden="true"
        width="36"
        height="36"
        viewBox="0 0 36 36"
        fill="green"
      >
        <path d="m6 14 8 8L30 6v8L14 30l-8-8v-8Z"></path>
      </svg>
    </Tooltip>
  );
}

function TakeBestAnswerBtn() {
  const { data_type, data_id, refresh } = useCommentsContext();
  const { comment } = useCommentItemContext();
  const handleTakeBestAnswer = () =>
    Modal.confirm({
      title: (
        <div>
          <p>是否采纳该回答？</p>
          <h5 style={{ color: "grey" }}>采纳后，本疑惑将被标记为"已解决"</h5>
        </div>
      ),
      okType: "primary",
      okText: "确定",
      cancelText: "取消",
      onOk: () =>
        httpclient
          .updateBestAnswerRecord(
            data_type,
            data_id,
            comment.id,
            comment.user_id
          )
          .then(refresh)
          .then(() => message.success("已采纳") && true),
    });

  return (
    <Button size="small" onClick={handleTakeBestAnswer}>
      采纳
    </Button>
  );
}

export default BestAnswerDecorator;
