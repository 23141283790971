import React, { useEffect, useState } from "react";
import "./testpaper.scss";
import "katex/dist/katex.min.css";
import { useParams } from "react-router";
import httpclient from "../../service/httpclient";
import useKaTeX from "../../hooks/useKaTeX";
import QuestionList from "../../components/lists/question-list";
import { Modal } from "antd";
import {NavLink} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faShoppingCart} from "@fortawesome/free-solid-svg-icons";

function TestPaper() {
  const [TestPaperData, setTestPaperData] = useState(null);
  const testpaperid = useParams().testpaperid;
  useEffect(() => {
    httpclient.getTestPaperData(testpaperid).then((data) => {
      setTestPaperData(data.testpaper);
    });
  }, [testpaperid]);
  useKaTeX("math-tex", TestPaperData);
  useKaTeX("math-mq", TestPaperData);
  return (
    <div className="testpaper-wrapper">
      {!!TestPaperData && (
        <div className="testpaper-title">{TestPaperData.title}</div>
      )}
      {/*{!!TestPaperData && !TestPaperPossession &&*/}
      {/*<div className="purchase-wrapper">*/}
      {/*  <div className="price">RMB{TestPaperData.price/100}/年</div>*/}
      {/*  <div className="purchase-frequency">(已有{TestPaperData.purchase_cnt}人购买)</div>*/}
      {/*  <NavLink to={'/checkout/testpaper/'+testpaperid} className="purchase-button">*/}
      {/*    <FontAwesomeIcon icon={faShoppingCart}/> 试卷购买*/}
      {/*  </NavLink>*/}
      {/*</div>}*/}
      <div className="questions-wrapper">{QuestionList(TestPaperData)}</div>
    </div>
  );
}

export default TestPaper;
