import React, {useState} from "react";
import './sidemenu.scss';
import {MenuOutlined} from "@ant-design/icons";

function SideMenu(data){
    const expand = () => {
        let sidemenuDom =  document.getElementsByClassName('sidemenu-mobile-wrapper')[0];
        if(sidemenuDom) {
            if(!sidemenuDom.style.opacity || sidemenuDom.style.opacity === '0'){
                sidemenuDom.style.display = 'block';
                 setTimeout(() => {
                     sidemenuDom.style.opacity = '0.9';
                 },50)

            }else{
                sidemenuDom.style.opacity = '0';
                setTimeout(() => {
                    sidemenuDom.style.display = 'none';
                },500)
            }
        }
    }
    if(data){
        const menu = data.map((content) => {
            return(
                <div className="sidemenu-item">
                    <div className="sidemenu-item-icon" onClick={() => {if(content.onClick)content.onClick()}}>{content.icon}</div>
                </div>
            )
        })
        const menu_mobile = data.map((content) => {
            return(
                <div className="sidemenu-item-mobile">
                    <div className="sidemenu-item-icon-mobile" onClick={() => {if(content.onClick)content.onClick()}}>{content.icon}</div>
                </div>
            )
        })
        return(
            <div className="sidemenu-wrapper">
                <div className="sidemenu">
                    {menu}
                </div>
                <div className="sidemenu-mobile">
                    <div className="sidemenu-mobile-wrapper">{menu_mobile}</div>
                    <div className="sidemenu-mobile-expand">
                        <div className="sidemenu-mobile-expand-icon" onClick={() => {expand();}}><MenuOutlined/></div>
                    </div>
                </div>
            </div>

        )
    }
}

export default SideMenu;