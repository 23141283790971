import DiscussItem from "./discuss-item";
import {cloneDeep} from "lodash";
import DiscussNotice from "./discuss-notice";

function DiscussList(discussData,options,updateDiscussData,userInfo,updateUserInfo) {
    return discussData?.map((item) => {
        if(item.select === 2) return <DiscussNotice discussItem={cloneDeep(item)}/>
        return <DiscussItem discussItem={cloneDeep(item)} options={options} updateDiscussData={updateDiscussData} userInfo={userInfo} updateUserInfo={updateUserInfo}/>
    })
}
export default DiscussList;
