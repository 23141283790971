import { EditOutlined } from '@ant-design/icons'
import './EditButton.css'

function EditButton(prop){

  return (
    <div className="edit-button">
      <EditOutlined />
      编辑
    </div>
  );
}

export default EditButton