import { Button } from "antd";
import { useState } from "react";

function DebouncingButton({ children, onClick, ...props }) {
  const [loading, setLoading] = useState(false);
  return (
    <Button
      loading={loading}
      onClick={() => onClick?.(loading, setLoading)}
      {...props}
    >
      {children}
    </Button>
  );
}

export default DebouncingButton;
