import React from "react";

function Intro() {

    return(
        <div style={{height:'600px'}}>
            intro
        </div>
    )
}

export default Intro;