import * as QiniuUploadService from "../service/qiniuUpload";

export default class UploadImageAdapter {
  loader = null;

  constructor(loader) {
    this.loader = loader;
  }

  async upload() {
    if (!this.loader) {
      throw new Error("loader is null");
    }

    return QiniuUploadService.upload(await this.loader.file).then((data) => ({
      default: data,
    }));
  }

  abort = () => {};
}
