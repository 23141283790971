import provinces from 'china-division/dist/provinces.json';
import cities from 'china-division/dist/cities.json';
import areas from 'china-division/dist/areas.json';


areas.forEach((area) => {
  const matchCity = cities.filter(city => city.code === area.cityCode)[0];
  if (matchCity) {
    matchCity.children = matchCity.children || [];
    matchCity.children.push({
      label: area.name,
      value: area.name,
    });
  }
});

cities.forEach((city) => {
  const matchProvince = provinces.filter(province => province.code === city.provinceCode)[0];
  if (matchProvince) {
    matchProvince.children = matchProvince.children || [];
    matchProvince.children.push({
      label: city.name,
      value: city.name,
      children: city.children,
    });
  }
});


const options = provinces.map(province => ({
  label: province.name,
  value: province.name,
  children: province.children,
}));

const recruit_cities = [
  {
    label:'上海',
    value: '上海市',
  },
  {
    label:'北京',
    value: '北京市',
  },
  {
    label:'杭州',
    value: '杭州市',
  },
  {
    label:'深圳',
    value: '深圳市',
  },
  {
    label:'广州',
    value: '广州市',
  }
]

export {options,recruit_cities};