import { Form, Input, Button, notification, Select, DatePicker, Space, Upload } from 'antd';
import { useState, useEffect } from 'react';
import { NavLink, useHistory, useParams } from 'react-router-dom';
import moment from "moment"
import useResumeService from '../../../../../../service/hooks/useResumeService'
import ImgCrop from 'antd-img-crop';
import httpclient from "../../../../../../service/httpclient";

function PersonalInfoFrom() {
  const history = useHistory();
  const service = useResumeService();
  const data = service.info;
  const urlInfo = service.urlInfo.info;
  const [qiniuToken, setQiniuToken] = useState(null);
  const [fileList, setFileList] = useState([]);
  const [imgUrl, setImgUrl] = useState(null);

  const onFinish = (values) => {
    console.log('Success:', values);
    service.postData('personal_info', {
      ...values,
      birth: values.birth.format('YYYY-MM-DD'),
      avatar: imgUrl
    }).then(() => {
      history.location.search === '?type=complete' ? history.push(urlInfo.urlAfterSave) : history.push('/user/center/my-resume/view');
    })
  };


  const onChange = ({ fileList: newFileList, file }) => {
    console.log(file);
    setFileList(newFileList);
    console.log(newFileList);
    if (!!file.response) {
      setImgUrl('https://cdn.datongbei.com/' + file.response.key);
    }
  };

  const onPreview = async file => {
    let src = file.url;
    if (!src) {
      src = await new Promise(resolve => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow.document.write(image.outerHTML);
  };

  useEffect(() => {
    httpclient.getQiniuUploadToken().then((resp) => setQiniuToken(resp.token));
    if (data?.avatar) {
      setFileList([{
        uid: '-1',
        name: 'image.png',
        status: 'done',
        url: data.avatar,
      }])
    }
  }, []);

  return(
    <Form
      key="info"
      name="info"
      labelCol={{ span: 4 }}
      wrapperCol={{ span: 16 }}
      initialValues={data && {
        ...data,
        birth: moment(data.birth, 'YYYY-MM-DD')
      }}
      onFinish={onFinish}
      onFinishFailed={(values)=>{notification.warn({ message: '格式错误', description: '请检查表单内容', key: 'form error'});console.log(values)}}
    >

      <Form.Item
        label="个人照片"
        name="avatar"
      >
        <ImgCrop aspect={25/35} rotate>
          <Upload
            action="https://up.qiniup.com"
            listType="picture-card"
            data={{
              token: qiniuToken,
              filename: `${Math.random().toString(16).slice(2)}`
            }}
            fileList={fileList}
            onChange={onChange}
            onPreview={onPreview}
          >
            {fileList.length < 1 && '+ Upload'}
          </Upload>
        </ImgCrop>
      </Form.Item>
      

      <Form.Item
        label="真实姓名"
        name="name"
        rules={[{ required: true, message: '请输入真实姓名!' }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="性别"
        name="gender"
        rules={[{ required: true, message: '请选择性别!'}]}
      >
        <Select style={{width: '80px'}}>
          <Select.Option value="男">男</Select.Option>
          <Select.Option value="女">女</Select.Option>
        </Select>
      </Form.Item>

      <Form.Item
        label="出生年月"
        name="birth"
        rules={[{ required: true, message: '请输入出生年月!'}]}
      >
        <DatePicker />
      </Form.Item>

      <Form.Item
        label="电话"
        name="telephone"
        rules={[{ required: true, message: '请输入电话!' }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="邮箱"
        name="email"
        rules={[{ required: true, message: '请输入邮箱!' }]}
      >
        <Input />
      </Form.Item>

      <Form.Item wrapperCol={{ offset: 7, span: 16 }}>
        <Space>
          <NavLink to='/user/center/my-resume/view'>
            <Button>
              取消
            </Button>
          </NavLink>
          <Button type="primary" htmlType="submit">
            保存{history.location.search === '?type=complete' ? '并进入下一项' : '并返回'}
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
}

export default PersonalInfoFrom;