import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { LikeOutlined, CheckCircleOutlined, LeftOutlined } from '@ant-design/icons'
import "./teacher.scss";

function Teacher(props) {

  return (
    <div className="teacher-wrapper">
      <div className="teacher-wrapper-inner">
        <NavLink className="back-button" to={"/teachers"}>
            <LeftOutlined />
        </NavLink>
        <div className="teacher">
          <div className="teacher-avatar">
          </div>
          <div className="teacher-name">王晓丽</div>
          <div className="teacher-position">资深复旦名师</div>
          <div className="teacher-consult-info">
            <CheckCircleOutlined />
            <span className="info-text">咨询230次</span>
            <LikeOutlined />
            <span className="info-text">点赞80次</span>
          </div>
          <div className="teacher-introduction">擅长设计模拟电路、版图设计等模块，提供工作面试咨询服务。擅长设计模拟电路、版图设计等模块，提供工作面试咨询服务。擅长设计模拟电路、版图设计等模块，提供工作面试咨询服务。擅长设计模拟电路、版图设计等模块，提供工作面试咨询服务。擅长设计模拟电路、版图设计等模块，提供工作面试咨询服务。</div>
          <NavLink className="ask-button" to={"/teachers/1/ask"}>
            <span>向TA提问</span>
          </NavLink>
        </div>
        <div className="public-questions">
          <div className="label">公开咨询</div>
          <div className="questions">
            <div className="question">
              <div className="content">请问面试xxxx岗位一般需要熟练掌握哪些知识？每方面的知识又都需要掌握到何种程度？</div>
              <div className="question-consult-info">
                <LikeOutlined />
                <div className="info-text">12人点赞</div>
                <div className="info-text">57人旁听</div>
                <div className="audit-button">
                  <span>￥1旁听</span>
                </div>
              </div>
            </div>
          </div>
          <div className="questions">
            <div className="question">
              <div className="content">请问面试xxxx岗位一般需要熟练掌握哪些知识？每方面的知识又都需要掌握到何种程度？请问面试xxxx岗位一般需要熟练掌握哪些知识？每方面的知识又都需要掌握到何种程度？请问面试xxxx岗位一般需要熟练掌握哪些知识？每方面的知识又都需要掌握到何种程度？请问面试xxxx岗位一般需要熟练掌握哪些知识？每方面的知识又都需要掌握到何种程度？</div>
              <div className="question-consult-info">
                <LikeOutlined />
                <div className="info-text">12人点赞</div>
                <div className="info-text">57人旁听</div>
                <div className="audit-button">
                  <span>￥1旁听</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

}

export default Teacher;
