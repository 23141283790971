import React, {useContext, useEffect, useMemo, useRef, useState} from "react";
import {Avatar, Carousel, Checkbox, Input, Modal, notification,Select} from 'antd';
import {CaretUpOutlined,DownOutlined,SearchOutlined,StarOutlined} from "@ant-design/icons";
import "./recruit.scss";
import {UserContext} from "../../../../App";
import {UserOutlined} from "@ant-design/icons";
import {NavLink, useLocation} from "react-router-dom";
import httpclient from "../../../../service/httpclient";
import modal from "../../../../components/modal/modal";
import {recruit_cities} from "../../../../components/addressOptions";
import moment from "moment";

function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

function Recruit(){
    let query = useQuery();
    const address_filter = query.get('address_filter') ? query.get('address_filter').split(',') : [];
    const time_filter = query.get('time_filter');
    const post_filter = query.get('post_filter');
    const {userInfo,updateUserInfo} = useContext(UserContext);
    const [isOpen,setIsOpen] = useState(false);
    const [postsData,setPostsData] = useState(null);
    const isAgree = useRef(false);
    const [addressFilter,setAddressFilter] = useState(address_filter);
    const [timeFilter,setTimeFilter] = useState(time_filter);
    const [postFilter,setPostFilter] = useState(post_filter);
    const  filterPostsData = useMemo(() => {
        if(postsData){
            return postsData.filter((content) => {
                let address_flag,time_flag,post_flag=0;
                    addressFilter.forEach((address) => {
                        if(address === content.address[0] || address === content.address[1]){
                            address_flag = 1;
                        }
                    })
                if(moment().diff(moment(content.created_at),'minute') < timeFilter) {
                    time_flag = 1;
                }
                if(content.name.includes(postFilter)){
                    post_flag = 1;
                }
                return ((address_flag || addressFilter.length === 0 ) && (time_flag || !timeFilter) && (post_flag || !postFilter));
            })
        }
    },[postsData,addressFilter,timeFilter,postFilter])
    useEffect(() => {
        httpclient.getAllPosts().then((res) => {
            setPostsData(res);
        })
    },[userInfo])
    const postList = useMemo(() => {
        if(filterPostsData){
            return filterPostsData.map((content) => {
                return(
                    <div className="recruit-table-item">
                        <div style={{display:'flex',justifyContent:'space-between',flexFlow:'column',width:'150px'}}>
                            <div className="post-name"><NavLink to={`/user/center/recruit/detail/${content.id}?address_filter=${addressFilter ? addressFilter : ''}&time_filter=${timeFilter ? timeFilter : ''}&post_filter=${postFilter ? postFilter : ''}`} style={{color:'#404040'}}>{content.name}</NavLink></div>
                            <div className="post-salary">{content.salary === '面谈' ? '薪资面谈' : `${content.salary[0]}K - ${content.salary[1]}K`}</div>
                        </div>
                        <div style={{display:'flex',justifyContent:'space-between',flexFlow:'column',width:'200px'}}>
                            <div className="company-name">{content.company_name}</div>
                            <div className="people-num">{`${content.people_num[0]} - ${content.people_num[1]}人`}</div>

                        </div>
                        <div style={{display:'flex',alignItems:'center'}}>
                            <div className="submit-resume" onClick={() => {
                                Modal.confirm({
                                    title:'是否确定投递简历？',
                                    okText:'确定',
                                    cancelText:'取消',
                                    onOk: function (){
                                        httpclient.submitResume(content.id).then(() => {
                                            notification.success({
                                                message:'投递简历成功',
                                            })
                                    })
                            }})}}>
                                投递简历
                            </div>
                            {/*<span className="star-icon">*/}
                            {/*    <StarOutlined/>*/}
                            {/*</span>*/}
                        </div>
                    </div>
                )
            })
        }

    },[filterPostsData])

    const timeCount = (time) => {
        if(time == 10080) return '一周之内';
        else if(time == 1440) return '一天之内';
        else if(time == 43200) return '一月之内';
        else if(time == 129600) return '三月之内';
        else if(time == 259200) return '六月之内';
    }

    const contentStyle = {
        height: '350px',
        color: '#fff',
        lineHeight: '350px',
        textAlign: 'center',
        background: '#5995DA',
        borderRadius: '10px'
    };
    const openRecruit = () => {
        if(isAgree.current){
            httpclient.openRecruit().then(() => {
                setIsOpen(true);
            })
        }else{
            notification.error({
                message:'错误提醒',
                description:'请同意开启招聘功能'
            })
        }
    }
    return(
        <div>
            {userInfo && ((!isOpen && userInfo.recruit !== 1) ? <div className="recruit">
            <div className="recruit-carousel">
                <Carousel>
                    <div>
                        <h3 style={contentStyle}>1</h3>
                    </div>
                    <div>
                        <h3 style={contentStyle}>2</h3>
                    </div>
                    <div>
                        <h3 style={contentStyle}>3</h3>
                    </div>
                    <div>
                        <h3 style={contentStyle}>4</h3>
                    </div>
                </Carousel>
            </div>
                <div className="recruit-notification">在[我的招聘]中，您可以看到行业内最权威、最专业的细分领域公司发布的职业需求，在此找到心仪的工作！</div>
               <div className="recruit-start-button" onClick={() => {openRecruit()}}>开启招聘</div>
                <Checkbox onChange={(e) => {isAgree.current = e.target.checked}} className="recruit-agreement">同意开启招聘功能，您的简历将会自动上传至我们的招聘资料库，心仪的公司就能看到您的简历了</Checkbox>
        </div> :
                <div className="recruit-content">
                    <div className="recruit-user">
                        <div className="recruit-user-info">
                            <div className="recruit-user-avatar">
                                {userInfo && userInfo.avatar ? <Avatar className="comment-user-avatar" size={{
                                        xs: 56,
                                        sm: 56,
                                        md: 64,
                                        lg: 64,
                                        xl: 72,
                                        xxl: 72,
                                    }} src={userInfo.avatar}/>:
                                    <Avatar className="comment-user-avatar" size={{
                                        xs: 56,
                                        sm: 56,
                                        md: 64,
                                        lg: 64,
                                        xl: 72,
                                        xxl: 72,
                                    }} icon={<UserOutlined/>}/>
                                }
                            </div>
                            <div className="recruit-user-des">
                                {/*<div className="recruit-user-post">*/}
                                {/*    期望职位：*/}
                                {/*    <span className="recruit-user-des">工程师、设计师</span>*/}
                                {/*</div>*/}
                                {/*<div className="recruit-user-fe">*/}
                                {/*    <div className="recruit-user-fee">*/}
                                {/*        期望薪资：*/}
                                {/*        <span className="recruit-user-des">10<span style={{color:'#404040'}}> K</span>   -   12<span style={{color:'#404040'}}> K</span></span>*/}
                                {/*    </div>*/}
                                {/*    <div className="recruit-user-exp">*/}
                                {/*        工作经验：*/}
                                {/*        <span className="recruit-user-des">1<span style={{color:'#404040'}}> 年</span>   -   3<span style={{color:'#404040'}}> 年</span></span>*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                            </div>
                            {userInfo && <div style={{fontSize:'20px',marginTop:'10px'}}>{userInfo.name}</div>}
                        </div>
                        <div className="recruit-user-tab">
                            <NavLink className="recruit-user-tab-label" to='/user/center/my-resume/view'>我的简历</NavLink>
                            <div style={{borderRight:'#e8e8e8 solid 1.5px'}}></div>
                            {/*<NavLink className="recruit-user-tab-label"  to='/'>我的收藏</NavLink>*/}
                            {/*<NavLink className="recruit-user-tab-label"  to='/'>我的消息</NavLink>*/}
                            <NavLink className="recruit-user-tab-label" to='/user/center/recruit/settings'>功能设置</NavLink>
                        </div>
                    </div>
                    <div className="recruit-up-arrow">
                        <CaretUpOutlined />
                    </div>
                    <div className="recruit-table">
                        <div className="recruit-table-search">
                            <div className="recruit-table-la">
                                <div className="recruit-table-search-label">
                                    <Select showSearch placeholder="所在地区"
                                            defaultValue={addressFilter ? address_filter : []}
                                            allowClear
                                            mode="multiple"
                                            optionFilterProp="children"
                                            onChange={(value) => {setAddressFilter(value)}}
                                            filterOption={(input, option) =>
                                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                            }
                                            options={recruit_cities}
                                            style={{'width':'200px'}}
                                            maxTagCount={2}
                                            dropdownStyle={{height:'100px',overflowY:'scroll'}}
                                    />
                                </div>
                                <div className="recruit-table-search-label">
                                    <Select showSearch placeholder="发布时间"
                                            defaultValue={
                                                timeCount(time_filter)
                                            }
                                            allowClear
                                            optionFilterProp="children"
                                            onChange={(value) => {setTimeFilter(value)}}
                                            filterOption={(input, option) =>
                                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                            }
                                            options={[
                                                {
                                                    label:'一天之内',
                                                    value: 24*60,
                                                },
                                                {
                                                    label:' 一周之内',
                                                    value: 7*24*60
                                                },
                                                {
                                                    label:'一月之内',
                                                    value: 30*24*60
                                                },
                                                {
                                                    label:'三月之内',
                                                    value: 90*24*60
                                                },
                                                {
                                                    label:'六月之内',
                                                    value: 180*24*60
                                                },
                                            ]}
                                            style={{'width':'100px'}}
                                            maxTagCount={2}
                                            dropdownStyle={{height:'100px',overflowY:'scroll'}}
                                    />
                                </div>
                            </div>
                            <div className="recruit-table-search-input">
                                <Input className="search-input" defaultValue={post_filter} allowClear placeholder={'搜索岗位名称'} onChange={(e) => {setPostFilter(e.target.value)}} prefix={<SearchOutlined />}/>
                            </div>
                        </div>
                        <div className="recruit-table-list">
                            {postList}
                        </div>
                    </div>
                </div>)
            }
        </div>
    )
}

export default Recruit;