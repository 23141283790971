import { Form, Input, Button, notification, Select, DatePicker, Space, Divider } from 'antd';
import { NavLink, useHistory } from 'react-router-dom';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import moment from "moment"
import useResumeService from '../../../../../../service/hooks/useResumeService'

const { RangePicker } = DatePicker;

function EducationBackgroundFrom() {
  const history = useHistory();
  const service = useResumeService();
  const data = service.education;
  const urlInfo = service.urlInfo.education;

  const onFinish = (values) => {
    console.log('Success:', values);
    service.postData('education_background', 
      values.educations.map(value => ({
        ...value,
        duration: value.duration.map(time => time.format('YYYY-MM-DD'))
      }))
    ).then(() => {
      history.location.search === '?type=complete' ? history.push(urlInfo.urlAfterSave) : history.push('/user/center/my-resume/view');
    })
  };

  return(
    <Form
      key="education"
      name="education"
      initialValues={data ?
        {
          educations: data.map(item => ({
            ...item,
            duration: [moment(item.duration[0], 'YYYY-MM-DD'), moment(item.duration[1], 'YYYY-MM-DD')]
          }))
        }:
        {educations: [{education: '本科'}]}
      }
      onFinish={onFinish}
      onFinishFailed={()=>notification.warn({ message: '格式错误', description: '请检查表单内容', key: 'form error'})}
    >

      <Form.List name="educations">
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, ...restField }) => (
              <>
                {console.log(key, name, restField)}
                {name > 0 && 
                  <MinusCircleOutlined
                    onClick={() => remove(name)}
                    style={{
                      fontSize: '20px',
                      color: '#909090'
                    }}
                  />
                }

                <Form.Item
                  {...restField}
                  label="学历"
                  name={[name, "education"]}
                  labelCol={{ span: 4 }}
                  wrapperCol={{ span: 16 }}
                  rules={[{ required: true, message: '请选择学历!'}]}
                >
                  <Select style={{width: '120px'}} disabled={name == 0}>
                    <Select.Option value="本科" disabled>本科</Select.Option>
                    <Select.Option value="硕士">硕士</Select.Option>
                    <Select.Option value="博士">博士</Select.Option>
                    <Select.Option value="硕博连读">硕博连读</Select.Option>
                  </Select>
                </Form.Item>

                <Form.Item
                  {...restField}
                  label="学校"
                  name={[name, "school"]}
                  labelCol={{ span: 4 }}
                  wrapperCol={{ span: 16 }}
                  rules={[{ required: true, message: '请输入学校!' }]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  {...restField}
                  label="在校时间"
                  name={[name, "duration"]}
                  labelCol={{ span: 4 }}
                  wrapperCol={{ span: 16 }}
                  rules={[{ required: true, message: '请输入在校时间!'}]}
                >
                  <RangePicker />
                </Form.Item>

                <Form.Item
                  {...restField}
                  label="专业"
                  name={[name, "major"]}
                  labelCol={{ span: 4 }}
                  wrapperCol={{ span: 16 }}
                  rules={[{ required: true, message: '请输入专业!' }]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  {...restField}
                  label="专业描述"
                  name={[name, "description"]}
                  labelCol={{ span: 4 }}
                  wrapperCol={{ span: 16 }}
                >
                  <Input.TextArea
                    showCount
                    maxLength={100}
                    autoSize
                    placeholder='描述专业课程内容、毕业设计、所获奖项'
                  />
                </Form.Item>
                
                <Divider />
              </>
            ))}
            <Form.Item>
              <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                添加学历（硕士、博士等）
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>

      <Form.Item wrapperCol={{ offset: 7, span: 16 }}>
        <Space>
          <NavLink to='/user/center/my-resume/view'>
            <Button>
              取消
            </Button>
          </NavLink>
          <Button type="primary" htmlType="submit">
            保存{history.location.search === '?type=complete' ? '并进入下一项' : '并返回'}
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
}

export default EducationBackgroundFrom;