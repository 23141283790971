import { QuestionCircleOutlined } from "@ant-design/icons";
import { Badge, Modal } from "antd";
import { useState } from "react";
import { Link } from "react-router-dom/cjs/react-router-dom";
import "./interactive_points_reminder.scss";

function InteractivePointsReminder() {
  const [showDot, setShowDot] = useState(
    () => localStorage.getItem("interactive-points-reminder") !== "no-dot"
  );
  const [modalOpen, setModalOpen] = useState(false);

  const handleOpen = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setModalOpen(true);
    localStorage.setItem("interactive-points-reminder", "no-dot");
    setShowDot(false);
  };
  const handleClose = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setModalOpen(false);
  };

  return (
    <span className="interactive-points-reminder" onClick={handleOpen}>
      <span>
        互动得权益积分{" "}
        <Badge dot={showDot}>
          <QuestionCircleOutlined />
        </Badge>
      </span>
      <Modal
        visible={modalOpen}
        title="互动得权益积分"
        onCancel={handleClose}
        footer={false}
      >
        <p>在题库板块进行互动（发帖、评论、采纳回答等），可获得权益积分。</p>
        <p>积累权益积分可获得优惠券、延期券等一系列好礼！</p>
        <p>
          详情可通过个人页面“我的权益”栏目查看，
          <Link to="/user/center/my-rights">即刻前往</Link>。
        </p>
      </Modal>
    </span>
  );
}

export default InteractivePointsReminder;
