import React, { useEffect, useState } from "react";
import httpclient from "../../service/httpclient";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import PossessList from "../../components/lists/possesslist";
import { NavLink } from "react-router-dom";
import "./userinfo.scss";
import "./user.scss";

function UserInfo() {
  const [userData, setUserData] = useState(null);
  const [userPossessTestpaper, setUserpossessTestpaper] = useState(null);
  const [userPossessTestpaperCollection, setUserPossessTestpaperCollection] =
    useState(null);
  useEffect(() => {
    httpclient.getUserInfo().then((response) => {
      setUserData(response);
    });
    httpclient.getUserPossessTestpaper().then((response) => {
      setUserpossessTestpaper(response);
      console.log(response);
    });
    httpclient.getUserPossessTestpaperCollection().then((response) => {
      setUserPossessTestpaperCollection(response);
      console.log(response);
    });
  }, [localStorage.token]);

  return (
    <div className="userinfo-wrapper">
      {!!userData && (
        <div className="userinfo">
          <div className="userinfo-title">个人信息</div>
          <div className="section">
            <NavLink to="/user/info/editor">
              <FontAwesomeIcon className="edit-icon" icon={faEdit} />
            </NavLink>
            <div className="userinfo-list">
              <div>
                <li>
                  姓名：{userData.name}
                  {!userData.name && (
                    <span className="hint-no-data-inline">（待补充）</span>
                  )}
                </li>
                <li>
                  学校：{userData.school}
                  {!userData.school && (
                    <span className="hint-no-data-inline">（待补充）</span>
                  )}
                </li>
              </div>
            </div>
          </div>
          <div className="section">
            <div className="buy-testpaper">
              <div className="buy-testpaper-title">试卷购买记录</div>
              {PossessList(userPossessTestpaper, "testpaper")}
            </div>
          </div>
          <div className="section">
            <div className="buy-testpaper-collection">
              <div className="buy-testpaper-collection-title">
                试卷集合购买记录
              </div>
              {PossessList(
                userPossessTestpaperCollection,
                "testpaper_collection"
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default UserInfo;
