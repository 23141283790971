import { useEffect } from "react";
import { useCommentsContext } from "../../Comments";
import { notification } from "antd";
import useSearchParams from "../../../../hooks/useSearchQuery";
import "./ScrollAndHighlightDecorator.scss";

function ScrollAndHighlightDecorator({
  children,
  searchKey = "scrollToComment",
}) {
  const { comments, setExpandedComments } = useCommentsContext();
  const { search, changeSearchAndRedirect } = useSearchParams();
  const commentIdToShow = +search.get(searchKey);

  useEffect(() => {
    if (!comments || !commentIdToShow) return;

    const commentIdToExpand = comments
      .map((c) => ({
        ...c,
        include: includeComment(c, commentIdToShow),
      }))
      .find((c) => c.include)?.id;

    if (!commentIdToExpand) notification.warn({ message: "评论消失了" });
    else {
      // 目标评论是最外层评论时，无需展开
      if (!comments.some((c) => c.id === commentIdToShow))
        setExpandedComments((set) => new Set([...set, commentIdToExpand]));

      scrollAndHighlight(commentIdToShow);
    }

    changeSearchAndRedirect((s) => s.delete(searchKey));
  }, [
    changeSearchAndRedirect,
    commentIdToShow,
    comments,
    searchKey,
    setExpandedComments,
  ]);

  return <>{children}</>;
}

function includeComment(comment, commentIdToShow) {
  if (comment.id === commentIdToShow) return true;
  else if (comment.direct_sub_comments.length === 0) return false;
  else
    return comment.direct_sub_comments
      .map((c) => includeComment(c, commentIdToShow))
      .some((x) => !!x);
}

function scrollAndHighlight(commentIdToShow) {
  setImmediate(() => {
    const commentDom = document.querySelector(
      `._comment-item[data-id="${commentIdToShow}"]`
    );
    if (!commentDom) return;

    commentDom?.scrollIntoView({ behavior: "smooth", block: "center" });

    setTimeout(() => {
      commentDom?.classList?.add?.("highlight");
      setTimeout(function () {
        commentDom?.classList?.remove?.("highlight");
      }, 1200);
    }, 1000);
  });
}

export default ScrollAndHighlightDecorator;
