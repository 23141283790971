import "./RecruitmentQuestionRecommendListCard.scss";

function RecruitmentQuestionRecommendListCard() {
  return (
    <div className="recruitment-question-recommend-list-card">
      <div className="card-logo">
        <img src="https://cdn.datongbei.com/icdesign-logo.png" alt="logo" />
      </div>
      <div className="card-info-wrapper">
        <div className="card-title">2024 模拟设计笔试必刷49题</div>
        <div className="card-description">制作中，敬请期待</div>
      </div>
    </div>
  );
}

export default RecruitmentQuestionRecommendListCard;
