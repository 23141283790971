import React, { useEffect, useRef, useState } from "react";
import "./discuss_submit.scss";
import CKEditorReact from "../../CKEditor/CKEditor";
import { Input, notification, Modal  } from "antd";
import {EditOutlined, InboxOutlined, CloseCircleOutlined} from "@ant-design/icons";
import httpclient from "../../service/httpclient";
import {useParams} from "react-router-dom";

function DiscussSubmit() {
  const [discussHtml, setDiscussHtml] = useState("");
  const [discussTag, setDiscussTag] = useState("");
  const [discussTitle, setDiscussTitle] = useState("");
  const [customTag, setCustomTag] = useState(null);
  const mode = useParams().mode;
  const discussId = useParams().discussid;
  const tagInput = useRef("");
  useEffect(() => {
    httpclient.getUserInfo();
  }, [localStorage.token]);
  const isCustom  = (tag) => {
    return tag !== "求职" && tag !== "面经" && tag !== "内推" && tag !== "学习";
  }
  const setContent = (title,html,tag) => {
    setDiscussTitle(title);
    setDiscussHtml(html);
    if(isCustom(tag)){
      setCustomTag(tag);
    }
    setDiscussTag(tag);
  }
  useEffect(() => {
    if(discussId){
      if(mode === 'draft'){
        httpclient.getDiscussDraftDetailData(discussId).then(res => {
          if(res){
            setContent(res.title,res.html,res.tag);
          }
        })
      }else{
        httpclient.getDiscussDetailData(discussId).then(res => {
          if(res){
            setContent(res.title,res.html,res.tag);
          }
        })
      }
    }
  },[mode,discussId]);
  const createDiscussDraft = () => {
    httpclient.createDiscussDraft(discussTitle, discussHtml, discussTag).then((res) => {
      if (res) {
        notification.success({
          message: "创建草稿成功",
          description: "您可在个人中心编辑或发布创建的草稿",
        });
        setTimeout(() => {
          window.location.href = "/user/center/my-discuss?selected=draft";
        }, 2000);
      }
    })
  }
  const updateDiscuss = () => {
    console.log(discussTag)
    if(mode === "draft"){
      httpclient.updateDiscussDraft(discussTitle, discussHtml, discussTag,discussId).then((res) => {
        if (res) {
          notification.success({
            message: "保存草稿成功",
            description: "您可在个人中心编辑或发布保存的草稿",
          });
          setTimeout(() => {
            window.location.href = "/user/center/my-discuss?selected=draft";
          }, 2000);
        }
      })
    }else{
      httpclient.updateDiscuss(discussTitle, discussHtml, discussTag,discussId).then((res) => {
        if (res) {
          notification.success({
            message: "保存讨论成功",
            description: "您可在个人中心管理已发布的讨论",
          });
          setTimeout(() => {
            window.location.href = "/user/center/my-discuss";
          }, 2000);
        }
      })
    }
  }
  const createDiscuss = () => {
    if (!discussTitle) {
      notification.error({
        message: "发布失败",
        description: "请输入讨论标题",
      });
    } else if (!discussHtml) {
      notification.error({
        message: "发布失败",
        description: "请输入讨论内容",
      });
    } else if (!discussTag) {
      notification.error({
        message: "发布失败",
        description: "请选择讨论的标签",
      });
    } else {
      httpclient
        .createDiscuss(discussTitle, discussHtml, discussTag)
        .then((res) => {
          if (res) {
            notification.success({
              message: "发布成功",
              description: "您可在个人中心查看或编辑已发布的讨论",
            });
            setTimeout(() => {
              window.location.href = "/discuss";
            }, 2000);
          }
        });
      if(discussId && mode === "draft") httpclient.deleteDiscussDraft(discussId);
    }
  };
  return (
    <div className="discuss-create">
      <div className="discuss-create-wrapper">
        <Input
            className="discuss-title-input"
            value={discussTitle}
          onChange={(e) => {
            setDiscussTitle(e.target.value);
          }}
          style={{
            fontSize: "32px",
            paddingLeft: "0",
            color: "#7B7B7B",
            marginBottom: "10px",
          }}
          bordered={false}
          size="large"
          placeholder="此处输入标题"
        />
        <div>
          <CKEditorReact
              onChange={(event, editor) => {
                setDiscussHtml(editor.getData());
              }}
              data={discussHtml}
          />
        </div>

        <div className="discuss-create-bottom-bar">
          <div className="tag-selector">
            <span className="tag-title">所属话题：</span>
            <div className="default-tag">
              <div style={{display:'flex'}}>
            <div
              className={`discuss-tag${
                discussTag === "求职" ? "-selected" : ""
              }`}
              onClick={() => {
                setDiscussTag("求职");
              }}
            >
              +求职
            </div>
            <div
              className={`discuss-tag${
                discussTag === "面经" ? "-selected" : ""
              }`}
              onClick={() => {
                setDiscussTag("面经");
              }}
            >
              +面经
            </div>
            <div
              className={`discuss-tag${
                discussTag === "内推" ? "-selected" : ""
              }`}
              onClick={() => {
                setDiscussTag("内推");
              }}
            >
              +内推
            </div>
            <div
              className={`discuss-tag${
                discussTag === "学习" ? "-selected" : ""
              }`}
              onClick={() => {
                setDiscussTag("学习");
              }}
            >
              +学习
            </div>
              </div>
          </div>
            {customTag ? (
              <div
                  style={{flexShrink:0}}
                className={`discuss-tag${
                  isCustom(discussTag) ? "-selected" : ""
                }`}
                onClick={() => {
                  setDiscussTag(customTag);
                }}
              >
                +{customTag} <span className="tag-delete" onClick={(e) => {setCustomTag(null);setDiscussTag("");e.stopPropagation();}}><CloseCircleOutlined /></span>
              </div>
            ) : (
              <div
                  style={{flexShrink:0}}
                className="add-custom-tag"
                onClick={() => {
                  Modal.info({
                    title: "请输入自定义标签",
                    content: (
                      <Input
                        onChange={(e) => {
                          tagInput.current = e.target.value;
                        }}
                      />
                    ),
                    onOk:() => {setCustomTag(tagInput.current);setDiscussTag(tagInput.current);}
                  });
                }}
              >
                +自定义
              </div>
            )}
          </div>
            {!mode ? <div className="discuss-action">
              <div className="action-button" style={{ background: "#44C176" }} onClick={createDiscussDraft}>
              <span style={{ marginRight: "4px" }}>
                <InboxOutlined />
              </span>
                存草稿
              </div>
              <div
                  className="action-button"
                  style={{ background: "#5189FF" }}
                  onClick={createDiscuss}
              >
              <span style={{ marginRight: "4px" }}>
                <EditOutlined />
              </span>
                发布
              </div>
            </div> : <div className="discuss-action">
              <div className="action-button" style={{ background: "#44C176" }} onClick={updateDiscuss}>
              <span style={{ marginRight: "4px" }}>
                <InboxOutlined />
              </span>
                保存编辑
              </div>
              {mode === "draft" && <div
                  className="action-button"
                  style={{ background: "#5189FF" }}
                  onClick={createDiscuss}
              >
              <span style={{ marginRight: "4px" }}>
                <EditOutlined />
              </span>
                发布
              </div>}
            </div>}
          </div>
      </div>
    </div>
  );
}

export default DiscussSubmit;
