import React, {useEffect, useMemo} from "react";
import {NavLink} from "react-router-dom";
import "./navigationbar.scss";
import useResizeObserver from "@react-hook/resize-observer";

function useSize(target) {
    const [size, setSize] = React.useState();

    React.useLayoutEffect(() => {
        target && setSize(target.getBoundingClientRect());
    }, [target]);

    useResizeObserver(target, (entry) => setSize(entry.contentRect));
    return size;
}

function NavigationBar(srcdata,selected){
    const [target, setTarget] = React.useState();
    const size = useSize(target);
    useEffect(() =>{
        if(srcdata && selected){let activatedDom,activatedDomPositon,activatedBar,activatedDomParent;
        activatedDom = document.getElementsByClassName('navigation-menu-item-activated')[0];
        activatedBar = document.getElementsByClassName('menu-activated-bar')[0];
        activatedDomParent = document.getElementsByClassName('navigation-menu')[0];
        activatedDomPositon = activatedDom.getBoundingClientRect().left - activatedDomParent.getBoundingClientRect().left;
        activatedBar.style.left = `${activatedDomPositon+(activatedDom.clientWidth-40)/2}px`;}
    },[srcdata,selected,size]);
    const menu = useMemo(() => {
        if(srcdata && selected) return srcdata.map((content,index) => {
            if(content.link){
                return(
                    <NavLink to={content.link} className={"navigation-menu-item" + ((selected === content.key || (!selected && index === 0)) ? "-activated" : '')}><div>{content.title}</div></NavLink>
                )
            }else{
                return (
                    <div onClick={content.onClick} className={"navigation-menu-item" + ((selected === content.key || (!selected && index === 0)) ? "-activated" : '')}>{content.title}</div>
                )
            }
        })
    },[srcdata,selected]);
    if(srcdata && selected){
        return (
            <div style={{width:"100%"}} ref={setTarget}>
                <div className="navigation-menu">
                    {menu}
                    <div className="menu-activated-bar"></div>
                </div>
            </div>
        )
    }
}

export default NavigationBar;
