import React, {useMemo, useState} from "react";
import "./coupon-card.scss";
import {CouponEffect, CouponStatus, CouponType, RangeType} from "../../pages/user_center/contents/my_coupons/coupon-enums";
import moment from "moment";
import {CheckOutlined} from "@ant-design/icons";
import {cloneDeep} from "lodash";
import couponRuleParser from "./coupon-rule-parser";
import {Empty} from "antd";

function CouponCard(props) {
  const { cardData,type,handleClick,dataType,dataId,price,disable } = props;
  const [selectedCoupon,setSelectedCoupon] = useState(new Map())
  const selectCoupon = (coupon) => {
      let newMap = cloneDeep(selectedCoupon)
      if(selectedCoupon.has(coupon.id)){
          newMap.delete(coupon.id)
      }else{
          newMap.set(coupon.id,coupon?.detail)
      }
      setSelectedCoupon(newMap)
      if(type === 'checkbox' && handleClick) handleClick(Array.from(newMap.keys()))
  }
  const isSelectable = (currentCoupon) => {
      if(disable) return false
      //已被选中的可以取消
      if(Array.from(selectedCoupon.keys()).includes(currentCoupon?.id)) return true
      //检验是否能够同时使用
      if(currentCoupon?.detail?.type === CouponType.SINGE && Array.from(selectedCoupon.keys()).length) return false
      if(Array.from(selectedCoupon.values())?.[0]?.type === CouponType.SINGE) return false
      //检验rule
      const ruleDSL = JSON.parse(currentCoupon?.detail?.rule)
      return couponRuleParser(ruleDSL,dataId,dataType,price)
  }
  const cards = useMemo(() => {
    return cardData?.map((item) => {
        const duration = JSON.parse(item?.duration)
        const start_time = moment.utc(duration[0]).local().format('YYYY.MM.DD')
        const end_time = moment.utc(duration[1]).local().format('YYYY.MM.DD')
        const isUnusable = item.status === CouponStatus.USED || item.status === CouponStatus.EXPIRED || item.status === CouponStatus.LOCKED || (!isSelectable(item) && type === 'checkbox')
      return (
        <div style={isUnusable ? {cursor:'not-allowed'} : {}} className={`coupon-card-item${type === 'checkbox' ? '-checkbox' : ''}`} key={item.id} onClick={() => {if(!isUnusable)selectCoupon(item)}}>
            <div className="item-content-wrapper">
                <div className="item-content">
                    <div className={`item-title${type === 'checkbox' ? '-checkbox' : ''}`}>{item?.detail?.name}</div>
                    <div className={`item-description${type === 'checkbox' ? '-checkbox' : ''}`}>{item?.detail?.description}</div>
                </div>
                <div className={`item-action${type === 'checkbox' ? '-checkbox' : ''}`}>
                    <div style={isUnusable ? {color:'lightgrey'} : {}} className={`item-discount${type === 'checkbox' ? '-checkbox' : ''}`}>
                        {item?.detail?.effect === CouponEffect.VOUCHER ?  `¥ ${item?.detail?.discount/100}`: `${item?.detail?.discount} 折`}
                        {(item.status === CouponStatus.USED || item.status === CouponStatus.EXPIRED || item.status === CouponStatus.LOCKED) && <div className="not-allowed-notification">
                            {item.status === CouponStatus.EXPIRED ? '已过期' : '已使用'}
                        </div>}
                    </div>
                </div>
            </div>
            <div className={`item-duration${type === 'checkbox' ? '-checkbox' : ''}`}>有效期：{start_time} - {end_time}</div>
            {(type === 'checkbox' && selectedCoupon.has(item?.id)) && <div className="checkbox-selected">
               <div className="checkbox-selected-icon"><CheckOutlined /></div>
            </div>}
        </div>
      );
    });
  }, [cardData,selectedCoupon,price]);
  return(
      cardData.length > 0 ?
      <div className="coupon-card-wrapper">{cards}</div>
          :<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description='您暂无可使用的优惠券'/>
      )
}
export default CouponCard;
