import {
  CommentsContext,
  useCommentItemContext,
  useCommentsContext,
} from "../../Comments";
import { Space } from "antd";
import "./AuthorDecorator.scss";

function AuthorDecorator({ children, authorId }) {
  const commentsCtx = useCommentsContext();

  const newRenderers = { ...commentsCtx.Renderers };
  newRenderers.OriginalName = commentsCtx.Renderers.Name;
  newRenderers.Name = NameWithAutorTag;
  newRenderers.OriginalContent = commentsCtx.Renderers.Content;
  newRenderers.Content = ContentWithAuthorReply;

  return (
    <CommentsContext.Provider
      value={{ ...commentsCtx, authorId, Renderers: newRenderers }}
      children={children}
    />
  );
}

function ContentWithAuthorReply() {
  const {
    authorId,
    Renderers: { OriginalContent },
  } = useCommentsContext();
  const { depth, comment } = useCommentItemContext();
  const authorReply = comment.direct_sub_comments.find(
    (sub_comment) => sub_comment.user.id === authorId
  );

  return (
    <>
      <OriginalContent />
      {depth === 1 && authorReply && (
        <AuthorReply reply={authorReply.content} />
      )}
    </>
  );
}

function AuthorReply({ reply }) {
  return <div className="_comment-author-reply">作者回复：{reply}</div>;
}

function NameWithAutorTag({ user }) {
  const {
    authorId,
    Renderers: { OriginalName },
  } = useCommentsContext();

  return (
    <Space size={2}>
      <OriginalName user={user} />
      {authorId === user?.id && <AuthorTag />}
    </Space>
  );
}

function AuthorTag() {
  return <span className="_comment-author-tag">作者</span>;
}

export default AuthorDecorator;
