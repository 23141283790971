import React, { useEffect, useState } from "react";
import NiceModal from "@ebay/nice-modal-react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import Title from "./components/title/title";
import Footer from "./components/footer/footer";
import Home from "./pages/home/home";
import Categories from "./pages/categories/categories";
import Zhuanlans from "./pages/zhuanlans/zhuanlans";
import "antd/dist/antd.css";
import "zhuanlan-render/build/static/css/main.css";
import "./App.scss";
import { RouterRefHolder } from "./utils/value";
import Login from "./pages/user/login";
import LoginBot from "./pages/user/login-bot";
import TestPaper from "./pages/testpaper/testpaper";
import UserInfo from "./pages/user/userinfo";
import CheckOut from "./pages/checkout/checkout";
import httpclient from "./service/httpclient";
import UserInfoEditor from "./pages/user/userinfo-editor";
import TestPaperCollection from "./pages/testpaper_collection/testpaper_collection";
import UserCenter from "./pages/user_center/user_center";
import Teachers from "./pages/teachers/teachers";
import Teacher from "./pages/teachers/teacher/teacher";
import ZhuanlanRenderTestPage from "./pages/zhuanlanrendertest/ZhuanlanRenderTestPage";
import CheckoutPortal from "./pages/checkoutportal/CheckoutPortal";
import { TestCheckout } from "./pages/testcheckout/testcheckout";
import Gift from "./pages/gift/gift";
import Chapters from "./pages/chapters/chapters";
import io from "socket.io-client";
import CouponModal from "./components/CouponModal/coupon-modal";
import Discuss from "./pages/discuss/discuss";
import DiscussDetail from "./pages/discuss/discuss-detail";
import DiscussSubmit from "./pages/discuss_submit/discuss_submit";
import RecruitmentQuestionDetailPage from "./pages/recruitment_question/recruitment_questions";
import RecruitmentQuestionSet from "./pages/recruitment_question_set/recruitment_question_set";
import { SendUserBrowsingDataByUrl } from "./components/SendUserBrowsingData/SendUserBrowsingData";

export const socket = io("https://icdesign.com:3120", {
  transports: ["websocket"],
});
socket.on("connect_error", (ex) => {
  console.log(ex);
});
socket.on("connect", function () {
  console.log("connect success");
});

export const UserContext = React.createContext("");
export const MessageContext = React.createContext("");

function App() {
  const [unreadMessageCnt, setUnreadMessageCnt] = useState(null);
  const [userInfo, setUserInfo] = useState(null);
  useEffect(() => {
    socket.on("update", function () {
      httpclient.getUnreadMessagesCnt().then((response) => {
        setUnreadMessageCnt(response);
      });
    });
    socket.on("receiveCoupons", function () {
      console.log("receiveCoupon");
      httpclient.getUnreadCouponsMessage().then((res) => {
        if (res && res.length) {
          CouponModal(res);
        }
      });
    });
  }, []);
  useEffect(() => {
    if (localStorage.token) {
      httpclient.getUserInfo().then((response) => {
        setUserInfo(response);
        socket.emit("login", response.id);
      });
    }
  }, [localStorage.token]);
  useEffect(() => {
    if (localStorage.token) {
      httpclient.getUnreadMessagesCnt().then((response) => {
        setUnreadMessageCnt(response);
      });
      httpclient.getUnreadCouponsMessage().then((res) => {
        if (res && res.length) {
          CouponModal(res);
        }
      });
    }
  }, []);
  const updateUserInfo = () => {
    if (localStorage.token) {
      httpclient.getUserInfo().then((response) => {
        setUserInfo(response);
      });
    }
  };
  const delCnt = (num) => {
    if (unreadMessageCnt - num >= 0) {
      setUnreadMessageCnt(unreadMessageCnt - num);
    } else {
      setUnreadMessageCnt(0);
    }
  };
  // useEffect(() => {
  //   if (localStorage.token) {
  //     httpclient.verifyToken().then(() => {
  //       httpclient.getUserInfo().then((response) => {
  //         if (response) {
  //           if (response.name === null || response.school === null || response.name === ''|| response.school === '') {
  //             notification.info({
  //               message: "系统提示",
  //               description: "请补全用户信息",
  //             });
  //           }
  //         }
  //       });
  //     })
  //         .catch((ex) => {
  //               if(ex.invalidToken){
  //                 delete localStorage['token'];
  //               }
  //
  //         })
  //   }
  // }, [localStorage.token]);
  return (
    <NiceModal.Provider>
      <UserContext.Provider value={{ userInfo, updateUserInfo }}>
        <MessageContext.Provider value={{ unreadMessageCnt, delCnt }}>
          <div className="wrapper">
            <meta name="apple-mobile-web-app-capable" content="yes" />
            <meta
              name="viewport"
              content="width=device-width,initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no, viewport-fit=cover"
            />
            <Router ref={(r) => RouterRefHolder.setValue(r)}>
              <Title></Title>
              <Footer></Footer>
              <Route path="/" exact>
                <Home></Home>
              </Route>
              <Route path="/discuss" exact>
                <Discuss></Discuss>
              </Route>
              <Route path="/categories/:categoriesid" exact>
                <Categories></Categories>
              </Route>
              <Route path="/zhuanlans/:zhuanlansid">
                <Zhuanlans></Zhuanlans>
              </Route>
              <Route path="/chapters/:chaptersid" exact>
                <Chapters></Chapters>
              </Route>
              <Route path="/chapters/:chaptersid/:mode/:gift_token" exact>
                <Chapters></Chapters>
              </Route>
              <Route path="/user/login">
                <Login></Login>
              </Route>
              <Route path="/bot/login">
                <LoginBot></LoginBot>
              </Route>
              <Route path="/user/info" exact>
                <UserInfo></UserInfo>
              </Route>
              <Route path="/user/info/editor" exact>
                <UserInfoEditor></UserInfoEditor>
              </Route>
              <Route path="/testpaper/:testpaperid" exact>
                <TestPaper></TestPaper>
              </Route>
              <Route path="/testpaper_collection/:testpaper_collectionid" exact>
                <TestPaperCollection></TestPaperCollection>
              </Route>
              <Route path="/checkout/:datatype/:dataid" exact>
                <CheckOut></CheckOut>
              </Route>
              <Route path="/checkout/:datatype/:dataid/:mode" exact>
                <CheckOut></CheckOut>
              </Route>
              <Route path="/checkout" exact>
                <CheckoutPortal></CheckoutPortal>
              </Route>
              <Route path="/test-checkout" exact>
                <TestCheckout></TestCheckout>
              </Route>
              <Route path="/user/center/:selected">
                <UserCenter></UserCenter>
              </Route>
              <Route path="/teachers" exact>
                <Teachers></Teachers>
              </Route>
              <Route path="/teachers/:teacherid" exact>
                <Teacher></Teacher>
              </Route>
              <Route path="/zhuanlan-render-test" exact>
                <ZhuanlanRenderTestPage></ZhuanlanRenderTestPage>
              </Route>
              <Route path="/gifts/:token" exact>
                <Gift></Gift>
              </Route>
              <Route path="/discuss-submit/create" exact>
                <DiscussSubmit></DiscussSubmit>
              </Route>
              <Route path="/discuss-submit/edit/:mode/:discussid" exact>
                <DiscussSubmit></DiscussSubmit>
              </Route>
              <Route path="/discuss/detail/:discussid" exact>
                <DiscussDetail></DiscussDetail>
              </Route>
              <Route path="/recruitment-questions/:questionId">
                <SendUserBrowsingDataByUrl
                  content_type="recruitment-question"
                  nameInUrlToGetId="questionId"
                >
                  <RecruitmentQuestionDetailPage />
                </SendUserBrowsingDataByUrl>
              </Route>
              <Route path="/recruitment-question-set">
                <RecruitmentQuestionSet />
              </Route>
            </Router>
          </div>
        </MessageContext.Provider>
      </UserContext.Provider>
    </NiceModal.Provider>
  );
}

export default App;
