import { RangeType } from "../../pages/user_center/contents/my_coupons/coupon-enums";

function couponRuleParser(ruleDSL, dataId, dataType, price) {
  if (price <= ruleDSL?.threshold) return false;
  if (ruleDSL?.range?.type === RangeType.ALL) {
    return ruleDSL?.range?.data_type?.includes(dataType) || false;
  } else if (ruleDSL?.range?.type === RangeType.PART) {
    return ruleDSL?.range?.data?.[dataType]?.includes(Number(dataId)) || false;
  }
}
export default couponRuleParser;
