import React, { useEffect, useState } from "react";
import { LikeOutlined, CheckCircleOutlined } from '@ant-design/icons'
import "./teachers.scss";
import { NavLink } from "react-router-dom";

function Teachers(props) {

  return (
    <div className="teachers-wrapper">
        <div className="teachers-title">热门名师</div>

        <div className="teachers-content">
          <div className="teacher">
            <div className="teacher-avatar" >
            </div>
            <div className="teacher-name">王晓丽</div>
            <div className="teacher-consult-info">
              <CheckCircleOutlined />
              <span className="info-text">咨询230次</span>
              <LikeOutlined />
              <span className="info-text">点赞80次</span>
            </div>
            <div className="teacher-position">资深复旦名师</div>
            <div className="teacher-introduction">擅长设计模拟电路、版图设计等模块，提供工作面试咨询服务。</div>
            <NavLink className="ask-button" to={"/teachers/1"}>
              <span>向TA提问</span>
            </NavLink>
          </div>
        </div>

        <div className="teachers-content">
          <div className="teacher">
            <div className="teacher-avatar">
            </div>
            <div className="teacher-name">王晓丽</div>
            <div className="teacher-consult-info">
              <CheckCircleOutlined />
              <span className="info-text">咨询230次</span>
              <LikeOutlined />
              <span className="info-text">点赞80次</span>
            </div>
            <div className="teacher-position">资深复旦名师</div>
            <div className="teacher-introduction">擅长设计模拟电路、版图设计等模块，提供工作面试咨询服务。</div>
            <NavLink className="ask-button" to={"/teachers/1"}>
              <span>向TA提问</span>
            </NavLink>
          </div>
        </div>
        
        <div className="teachers-content">
          <div className="teacher">
            <div className="teacher-avatar">
            </div>
            <div className="teacher-name">王晓丽</div>
            <div className="teacher-consult-info">
              <CheckCircleOutlined />
              <span className="info-text">咨询230次</span>
              <LikeOutlined />
              <span className="info-text">点赞80次</span>
            </div>
            <div className="teacher-position">资深复旦名师</div>
            <div className="teacher-introduction">擅长设计模拟电路、版图设计等模块，提供工作面试咨询服务。</div>
            <NavLink className="ask-button" to={"/teachers/1"}>
              <span>向TA提问</span>
            </NavLink>
          </div>
        </div>
        
    </div>
  );

}

export default Teachers;
