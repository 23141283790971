import { useEffect, useState } from "react";
import httpclient from "../../service/httpclient";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

function SendUserBrowsingData({ children, content_type, content_id }) {
  const [hasBeenSent, setHasBeenSent] = useState(false);
  useEffect(() => {
    if (!hasBeenSent) {
      httpclient
        .sendUserBrowsingData(content_type, content_id)
        .then(() => setHasBeenSent(true));
    }
  }, [content_id, content_type, hasBeenSent]);
  return <>{children}</>;
}

function SendUserBrowsingDataByUrl({
  children,
  nameInUrlToGetId,
  content_type,
}) {
  const content_id = useParams()[nameInUrlToGetId];
  return (
    <SendUserBrowsingData content_type={content_type} content_id={content_id}>
      {children}
    </SendUserBrowsingData>
  );
}

export { SendUserBrowsingData, SendUserBrowsingDataByUrl };
