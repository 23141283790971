import React, { useContext, useEffect, useState } from "react";
import "./my_rights.scss";
import {
  InfoCircleOutlined,
  QuestionCircleOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Avatar, Progress, Tooltip } from "antd";
import RightsList from "./rights-list";
import MissionsList from "./missions-list";
import { UserContext } from "../../../../App";
import httpclient from "../../../../service/httpclient";

const levelIcons = [
  "https://cdn.datongbei.com/icdesign-171981694506922261bg54dgd55.png",
  "https://cdn.datongbei.com/icdesign-171981757604116609bh53fa79.png",
  "https://cdn.datongbei.com/icdesign-171981758876684222f2aa1d5g.png",
  "https://cdn.datongbei.com/icdesign-1719817594963bd4b0f2c44hf7.png",
  "https://cdn.datongbei.com/icdesign-171981759496670f74a32h9485.png",
];

function MyRights() {
  const missionsData = [
    {
      title: "写题解",
        description: <div>在题库上传一次题目解答 <span style={{fontSize:'12px'}}>（完成后可获得<span style={{color:"#5995DA"}}>10</span>权益积分）</span></div>,
      link: "/recruitment-question-set",
    },
    {
      title: "答疑",
      description: <div>在题库回答一次别人的疑问 <span style={{fontSize:'12px'}}>（完成后可获得<span style={{color:"#5995DA"}}>10</span>权益积分）</span></div>,
      link: "/recruitment-question-set",
    },
    {
      title: "采纳",
      description: <div>在所提的问题中采纳一个别人的正确回答 <span style={{fontSize:'12px'}}>（完成后可获得<span style={{color:"#5995DA"}}>2</span>权益积分）</span></div>,
      link: "/recruitment-question-set",
    },
  ];
  const [rightsData, setRightsData] = useState(null);
  const { userInfo, updateUserInfo } = useContext(UserContext);
  useEffect(() => {
    httpclient.getUserRightsData().then((res) => {
      if (res) setRightsData(res);
    });
  }, [localStorage.token]);
  return (
    <div className="my-rights-wrapper">
      {!!rightsData && (
        <>
          <div className="my-rights-top-bar">
            <div className="my-rights-notification">
              <Tooltip
                placement="bottomRight"
                title="每个用户拥有单独的权益积分，权益积分可以通过在网站题库板块的互动获得。当权益积分达到一定标准时即可升级解锁相应等级的权益。"
              >
                <InfoCircleOutlined /> 规则说明
              </Tooltip>
            </div>
            <div className="my-rights-avatar">
              <Avatar
                size={64}
                src={userInfo?.avatar}
                icon={<UserOutlined />}
              />
              <img
                src={levelIcons[rightsData.level - 1]}
                className="my-rights-level-icon"
              />
            </div>
            <div className="my-rights-name-wrapper">
              <div className="my-rights-name">
                {userInfo?.name || userInfo?.mobile}
              </div>
              <div className="my-rights-tag">Lv {rightsData.level}</div>
            </div>
            <div className="my-rights-progress">
              <Progress
                strokeColor={"#5995DA"}
                percent={
                  (rightsData.left_points * 100) / rightsData.level_points
                }
                showInfo={false}
              />
              <span className="progress-text">
                {rightsData.left_points}/{rightsData.level_points}
              </span>
            </div>
          </div>
          <div className="my-rights">
            <div className="rights-title">
              等级权益{" "}
              <Tooltip title="达到不同Lv等级将解锁不同等级权益哦!Lv2将解锁10元优惠券一张！">
                <QuestionCircleOutlined />
              </Tooltip>
            </div>
            <div className="rights-list">{RightsList(rightsData)}</div>
          </div>
          <div className="my-rights-missions">
            <div className="rights-title">
              权益任务{" "}
              <Tooltip title="完成相应任务，获得权益等级积分">
                <QuestionCircleOutlined />
              </Tooltip>
            </div>
            <div className="missions-list">{MissionsList(missionsData)}</div>
          </div>
        </>
      )}
    </div>
  );
}

export default MyRights;
