import { List, Space } from "antd";
import { useEffect, useState } from "react";
import httpclient from "../../../service/httpclient";
import { FireOutlined } from "@ant-design/icons";
import "./HottestRecruitmentQuestionList.scss";

function HottestRecruitmentQuestionList() {
  const [questions, setQuestions] = useState(null);
  useEffect(() => {
    httpclient.getHottestRecruitmentQuestions().then((qs) => setQuestions(qs));
  }, []);
  const navigate = (id) => {
    window.open(
      `/recruitment-questions/${id}`,
      "_blank",
      "noopener,noreferrer"
    );
  };

  return (
    <List
      className="hottest-recruitment-question-list"
      loading={questions === null}
      dataSource={questions || []}
      bordered
      rowKey="id"
      renderItem={(item) => (
        <List.Item onClick={() => navigate(item.recruitment_question_id)}>
          <div style={{ width: "4rem" }}>
            <strong>{item.recruitment_question_id}.</strong>
          </div>
          <div style={{ flex: 1, flexShrink: 1 }}>
            <strong>{item.recruitment_question.title}</strong>
          </div>
          <div style={{ width: "6rem" }}>
            <Space>
              <FireOutlined />
              {item.heat}
            </Space>
          </div>
        </List.Item>
      )}
    />
  );
}

export default HottestRecruitmentQuestionList;
