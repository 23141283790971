import React from "react";
import { NavLink } from "react-router-dom";
import routeUrls from "../../route/routeurls";
import "./possesslist.scss";

function PossessList(PossessData, type) {
  if (PossessData) {
    if (!PossessData.length) {
      return <div className="hint-no-item">暂无购买记录</div>;
    }
    return PossessData.map((content) => (
      <div className="possess-item">
        <NavLink
          className="possess-item-name"
          to={
            type === "testpaper_collection"
              ? routeUrls("testpaper_collection", content.id)
              : routeUrls("testpaper", content.id)
          }
        >
          {type === "testpaper_collection" ? content.name : content.title}
        </NavLink>
        <div className="possess-item-price">
          购买花费：{content.price / 100}
        </div>
        <div className="expiration-time">到期时间：{content.time}</div>
        {type === "testpaper_collection" && (
          <div className="possess-item-intro">{content.introduction_text}</div>
        )}
      </div>
    ));
  }
}
export default PossessList;
