import React from "react";
import "./discuss-item.scss";
import {
  CommentOutlined,
  StarOutlined,
  LikeOutlined,
  UserOutlined,
    DeleteOutlined
} from "@ant-design/icons";
import {Avatar, Empty, notification} from "antd";
import { NavLink } from "react-router-dom";
import moment from "moment";
import Comments from "../../pages/zhuanlans/zhuanlan_detail_content/comments/comments";
import {isEqual} from "lodash";
import {serial} from "react-lz-editor/global/supports/datas/url";
import {show} from "@ebay/nice-modal-react";
import httpclient from "../../service/httpclient";

function PreviewImg(discussHtml, cnt) {
  const regex = /<img[^>]*src=["']([^"']+)["'][^>]*>/g;
  let matches = [],
    result = [];
  let match;
  while ((match = regex.exec(discussHtml)) !== null) {
    matches.push(match[1]);
  }
  for (let i = 0; i < cnt; i++) {
      if(matches[i]){
          result.push(<img className="preview-img-item" src={matches[i]} />);
      }
  }
  return result.length > 0 && (
      <div className="preview-image-wrapper">
          {result}
      </div>
  )
}

function areEqual(prevProps, nextProps) {
  return (
      isEqual(prevProps.discussItem, nextProps.discussItem) &&
      isEqual(prevProps.options, nextProps.options) &&
      prevProps.index === nextProps.index &&
      prevProps.userInfo === nextProps.userInfo &&
          prevProps.updateDiscussData === nextProps.updateDiscussData
  );
}

function DiscussItem({discussItem, options, updateDiscussData,userInfo,updateUserInfo}) {
  const { showAvatar, showDetail } = options || true;
  const { showDelete } = options || false;
  const searchInput = options?.searchInput;
  if (
    discussItem?.title.includes(searchInput) ||
    discussItem?.html.includes(searchInput) || !searchInput
  ) {
    return (
      <div className="discuss-item">
        <div className="discuss-top-bar">
          <div style={{ display: "flex" }}>
            {showAvatar !== false && (
              <div className="discuss-user-avatar">
                <Avatar
                  src={discussItem.avatar}
                  size={40}
                  icon={<UserOutlined />}
                />
              </div>
            )}
            <div className="discuss-title">{discussItem.title}</div>
          </div>
            <div style={{display:'flex',alignItems:'center'}}>
                <div className="discuss-tag"># {discussItem.tag}</div>
                {(!!discussItem.select && showDetail !== false) && <div className="discuss-select-tag">置顶</div>}
            </div>
        </div>
        <div style={{ padding: "20px 20px" }}>
          <div className={showDetail !== false ? "discuss-content-preview" : "discuss-content"}>
            <span
              dangerouslySetInnerHTML={{
                __html: showDetail !== false ? discussItem.html.replace(/<img[^>]*>/g, "") : discussItem.html.replace(/<img(.*?)>/g, '<img$1 style="max-width: 100%;">'),
              }}
            />
          </div>
          {showDetail !== false && (
            <span className="discuss-detail-link">
              <NavLink to={"/discuss/detail/" + discussItem.id}>详情</NavLink>
            </span>
          )}
            {showDetail !== false && <div className="discuss-preview-img">
            {PreviewImg(discussItem.html, 3)}
          </div>}
        </div>
        <div className="discuss-bottom-bar">
          <div className="discuss-time">
            {moment(discussItem.created_at).format("YYYY-MM-DD HH:mm")}
          </div>
          <div style={{ display: "flex" }}>
            <div className="discuss-action" onClick={() => {if(showDetail !== false)updateDiscussData(discussItem.id,'comment')}}>
              <span style={{ marginRight: "4px" }}>
                <CommentOutlined />
              </span>
                {discussItem.comments.length}
                <span className="discuss-action-text">条评论</span>
            </div>
            <div
              className={`discuss-action${discussItem.liked ? "-liked" : ""}`}
              onClick={() => {
                discussItem.liked
                  ? updateDiscussData(discussItem.id, "dislike")
                  : updateDiscussData(discussItem.id, "like");
              }}
            >
              <span style={{ marginRight: "4px" }}>
                <LikeOutlined />
              </span>
              {discussItem.likes !== 0 && discussItem.likes}
                <span className="discuss-action-text">点赞</span>
            </div>
            <div className="discuss-action">
              <span style={{ marginRight: "4px" }}>
                <StarOutlined />
              </span>
              <span className="discuss-action-text">收藏</span>
            </div>
              {showDelete && <div className="discuss-action" onClick={() => {httpclient.deleteDiscuss(discussItem.id).then(res => {
                  if (res) {
                      notification.success({
                          message: "删除成功",
                      });
                      setTimeout(() => {
                          window.location.href = "/user/center/my-discuss";
                      }, 1000);
                  }
              })}}>
              <span style={{ marginRight: "4px" }}>
                <DeleteOutlined />
              </span>
                  删除
              </div>
              }
          </div>
        </div>
        {(discussItem.showComments || showDetail === false) && <div className="discuss-item-comments">
          {Comments(discussItem,'discuss',discussItem.id,'',userInfo,updateUserInfo,showDetail !== false ? "preview" : "detail")}
        </div>}
      </div>
    );
  }else{
      return null;
  }
}

export default React.memo(DiscussItem,areEqual);
