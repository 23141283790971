function ScopeJudge() {
  const nu = navigator.userAgent;
  if (
    nu.match(/Android/i) ||
    nu.match(/webOS/i) ||
    nu.match(/iPhone/i) ||
    nu.match(/iPod/i) ||
    nu.match(/BlackBerry/i) ||
    nu.match(/Windows Phone/i)
  ) {
    return "mobile";
  } else if (nu.match(/iPad/i)) {
    return "ipad";
  } else {
    return "pc";
  }
}
export default ScopeJudge;
