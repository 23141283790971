class ValueHolder {
  heldValue = null;
  getNonNullValueCallbacks = [];

  setValue(v) {
    this.heldValue = v;
    if (v !== null) {
      this.getNonNullValueCallbacks.splice(0).forEach((cb) => cb(v));
    }
  }

  getValue() {
    return this.heldValue;
  }

  getNonNullValue() {
    if (this.heldValue !== null) {
      return Promise.resolve(this.heldValue);
    }

    return new Promise((resolve) => {
      this.getNonNullValueCallbacks.push(resolve);
    });
  }
}

const RouterRefHolder = new ValueHolder();

export { RouterRefHolder };
