import { useMemo } from "react";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";

function useSearchParams() {
  const history = useHistory();
  const { search: rawSearch } = useLocation();
  const search = useMemo(() => new URLSearchParams(rawSearch), [rawSearch]);
  const changeSearchAndRedirect = (doOperation) => {
    doOperation(search);
    history.replace(`?${search.toString()}`);
  };
  return { search, changeSearchAndRedirect };
}

export default useSearchParams;
