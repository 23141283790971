import * as qiniu from "qiniu-js";
import { memoize } from "lodash";
import httpclient from "./httpclient";

const getQiniuUploadToken = memoize(() => {
  return httpclient.getQiniuUploadToken().then((resp) => resp.token);
});

const qiniuDomain = "https://cdn.datongbei.com/";
const qiniuConfig = {
  useCdnDomain: true,
};
const qiniuPutExtra = {
  fname: "",
  params: {},
};

export const upload = async (img) => {
  const imgType = img.name.split(".").reverse()[0];
  const filename = `icdesign-${new Date().getTime()}${Math.random()
    .toString(18)
    .substr(2)}.${imgType}`;

  const observable = qiniu.upload(
    img,
    filename,
    await getQiniuUploadToken(),
    qiniuPutExtra,
    qiniuConfig
  );
  return new Promise((resolve, reject) => {
    const observer = {
      next(res) {},
      error(err) {
        reject(err);
      },
      complete: (res) => {
        resolve(
          `${qiniuDomain}${res.key}?imageView2/0/format/jpg/q/95|imageslim`
        );
      },
    };
    observable.subscribe(observer);
  });
};
