import httpclient from "../../service/httpclient";
import EditButton from '../../components/EditButton/EditButton';
import { useEffect, useRef, useState } from 'react';
import {Modal, Form, Input, DatePicker, Radio, Cascader, Select, Upload, notification} from 'antd';
import { useHistory } from "react-router";
import { options } from "../../components/addressOptions.js";
import moment from "moment"
import ImgCrop from "antd-img-crop";

const { Option } = Select;

function UserInfoEditor(props) {
  const [isShowEditor, setIsShowEditor] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const formRef = useRef();
  const userInfo = props.userInfo;
  const dateFormat = "YYYY-MM-DD";
  const [qiniuToken, setQiniuToken] = useState(null);
  const [fileList, setFileList] = useState([]);
  const [imgUrl, setImgUrl] = useState(null);

  const onFinish = (values) => {
    setConfirmLoading(true);
    if(!values.name){
      notification.error({
        message: "错误提示",
        description: "输入姓名不能为空，请重新输入",
      })
      setConfirmLoading(false);
    }else{
      httpclient.updateUserInfo({
        ...values,
        address: values?.address?.join('-'),
        birthday: values?.birthday?.format && values?.birthday?.format(dateFormat),
        avatar: imgUrl,
      }).then((data) => {
        setIsShowEditor(!isShowEditor);
        setConfirmLoading(false);
        props.refreshUserInfo(data);
      });
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const onChange = ({ fileList: newFileList, file }) => {
    console.log(file);
    setFileList(newFileList);
    console.log(newFileList);
    if (!!file.response) {
      setImgUrl('https://cdn.datongbei.com/' + file.response.key);
    }
  };

  const onPreview = async file => {
    let src = file.url;
    if (!src) {
      src = await new Promise(resolve => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow.document.write(image.outerHTML);
  };

  useEffect(() => {
    httpclient.getQiniuUploadToken().then((resp) => {if(resp) setQiniuToken(resp.token)});
    if (userInfo?.avatar) {
      setFileList([{
        uid: '-1',
        name: 'image.png',
        status: 'done',
        url: userInfo.avatar,
      }])
      setImgUrl(userInfo.avatar);
    }
  }, [userInfo]);

  return (
    <>
    <div onClick={()=>{setIsShowEditor(!isShowEditor)}}>
      <EditButton />
    </div>

    <Modal
      title='编辑个人信息'
      visible={isShowEditor}
      onCancel={()=>{setIsShowEditor(!isShowEditor)}}
      onOk={()=>{formRef.current.submit()}}
      confirmLoading={confirmLoading}
      okText='提交'
      cancelText='取消'
    >

      <Form
        ref={formRef}
        name="info-editor"
        labelCol={{
          span: 7,
        }}
        wrapperCol={{
          span: 14,
        }}
        initialValues={{
          ...userInfo,
          address: userInfo?.address?.split('-'),
          birthday: (userInfo?.birthday ? moment(userInfo.birthday, dateFormat) : '')
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item
          label="个人照片"
          name="avatar"
        >
          <ImgCrop rotate>
            <Upload
              action="https://up.qiniup.com"
              listType="picture-card"
              data={{
                token: qiniuToken,
                filename: `${Math.random().toString(16).slice(2)}`
              }}
              fileList={fileList}
              onChange={onChange}
              onPreview={onPreview}
            >
              {fileList.length < 1 && '+ Upload'}
            </Upload>
          </ImgCrop>
        </Form.Item>
        <Form.Item
          label="姓名"
          name="name"
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="性别"
          name="gender"
        >
          <Radio.Group>
            <Radio value="男">男</Radio>
            <Radio value="女">女</Radio>
          </Radio.Group>
        </Form.Item>

        <Form.Item
          label="出生日期"
          name="birthday"
        >
          <DatePicker format={dateFormat} />
        </Form.Item>

        <Form.Item
          label="居住地"
          name="address"
        >
          <Cascader options={options} onChange={()=>{}}></Cascader>
        </Form.Item>

        <Form.Item
          label="学历"
          name="education"
        >
          <Select>
            <Option value="博士研究生">博士研究生</Option>
            <Option value="硕士研究生">硕士研究生</Option>
            <Option value="本科">本科</Option>
            <Option value="专科">专科</Option>
            <Option value="中专/高中">中专/高中</Option>
            <Option value="初中">初中</Option>
            <Option value="小学">小学</Option>
          </Select>
        </Form.Item>

        <Form.Item
          label="目前所在单位/学校"
          name="school"
        >
          <Input />
        </Form.Item>

      </Form>

    </Modal>
    </>
  );
}

export default UserInfoEditor;
