import httpclient from "./httpclient";
import { urlSafeBase64Encode } from "../utils/base64";
import Swal from "sweetalert2";
import {socket} from "../App";

const qrServiceBaseUrl = "https://server.datongbei.com/api/qr?url=";

class OrderService {
  /**
   * @param orderExtra {OrderExtra}
   * @returns {Promise<*>}
   */
  placeOrder(orderExtra) {
    return httpclient.Request({
      input: "/order/place",
      method: "POST",
      body: { order_extra: orderExtra },
      alert: true,
      auth: "true",
    });
  }

  setOrderJumpBackUrl(orderId, url) {
    localStorage[`jumpBackUrl_order_${orderId}`] = url;
  }

  getOrderJumpBackUrl(orderId) {
    setTimeout(() => {
      delete localStorage[`jumpBackUrl_order_${orderId}`];
    });
    return localStorage[`jumpBackUrl_order_${orderId}`] || "";
  }

  pollOrderStatus(orderId) {
    return httpclient.Request({
      input: "/order/status",
      method: "POST",
      body: { id: orderId },
      auth: "true",
    });
  }

  /**
   * @param product {{id: number, type: string, renew_price_mode: number}}
   * @param options {{jumpBackUrl}}
   * @returns {Promise<*>}
   */
  order(product, options = {}) {
    return this.placeOrder({ product }).then((res) => {
      const orderId = res.id;
      if (options.jumpBackUrl) {
        this.setOrderJumpBackUrl(orderId, options.jumpBackUrl);
      }
      const gatewayUrl =
        window.location.origin +
        "/checkout/#" +
        urlSafeBase64Encode(
          JSON.stringify({ orderId, token: localStorage.token })
        );

      if (isWeixin()) {
        window.location.href = gatewayUrl;
        return;
      }

      let pollIntervalId = 0;

      const clearPoll = () => {
        if (pollIntervalId) {
          clearInterval(pollIntervalId);
          pollIntervalId = 0;
        }
      };

      pollIntervalId = setInterval(async () => {
        const orderStatus = await this.pollOrderStatus(orderId);
        socket.emit('heartBeat',orderId)
        if (orderStatus.payment_received) {
          clearPoll();
          Swal.close();
          Swal.fire({ icon: "success", text: "支付成功" });
          setTimeout(() => {
            const jumpBackUrl = this.getOrderJumpBackUrl(orderId);
            if (jumpBackUrl) {
              window.location.href = jumpBackUrl;
            }
          }, 3000);
        }
      }, 1000);

      Swal.fire({
        text: "请用微信扫码进行支付",
        imageUrl: qrServiceBaseUrl + encodeURIComponent(gatewayUrl),
        imageWidth: 200,
        imageHeight: 200,
        showConfirmButton: false,
        showCancelButton: true,
        cancelButtonText: "取消支付",
        allowEscapeKey: false,
        allowOutsideClick: false,
      }).then((x) => {
        if (x.isDismissed) {
          clearPoll();
        }
      });
    });
  }
}

export function isWeixin() {
  try {
    return (
      navigator.userAgent.toLowerCase().indexOf("micromessenger") > -1 ||
      typeof window.navgator.wxuserAgent !== "undefined"
    );
  } catch (e) {
    return false;
  }
}

export default OrderService;
