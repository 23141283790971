import {
  ArrowLeftOutlined,
  CommentOutlined,
  EditOutlined,
  LikeFilled,
  LikeOutlined,
  MessageOutlined,
  ReadOutlined,
} from "@ant-design/icons";
import "./recruitment_questions.scss";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import httpclient from "../../service/httpclient";
import {
  Button,
  Collapse,
  Drawer,
  Empty,
  Form,
  Input,
  List,
  Menu,
  Select,
  Space,
  Spin,
  Tag,
  Typography,
  message,
} from "antd";
import RichText from "../../components/RichText/RichText";
import CKEditorReact from "../../CKEditor/CKEditor";
import CKEditorAntdFormItem from "../../CKEditor/CKEditorAntdFormItem";
import Comments from "../../components/Comments/Comments";
import AuthorDecorator from "../../components/Comments/decorators/author/AuthorDecorator";
import ScrollAndHighlightDecorator from "../../components/Comments/decorators/default-expand/ScrollAndHighlightDecorator";
import useSearchParams from "../../hooks/useSearchQuery";
import useWindowWidth from "../../hooks/useWindowWidth";
import BestAnswerDecorator from "../../components/Comments/decorators/best-answer/BestAnswerDecorator";
import UserAvatar from "../../components/user/UserAvatar";
import FormItem from "antd/lib/form/FormItem";
import InteractivePointsReminder from "./interactive_points_reminder";

const RecruitmentQuestionPageContext = createContext(null);

function RecruitmentQuestionDetailPage() {
  const { search, changeSearchAndRedirect } = useSearchParams();
  const collapseSelectedKeys =
    search
      .get("collapseSelect")
      ?.split("|")
      .filter((x) => !!x) || [];
  const tabSelectedKey = search.get("tabSelect");
  const discussionId = search.get("discussionId");

  const { deviceType } = useWindowWidth();

  const { questionId } = useParams();
  const [question, setQuestion] = useState(null);
  useEffect(() => {
    httpclient
      .getOneRecruitmentQuestion(questionId)
      .then((q) => setQuestion(q));
  }, [questionId]);

  const [answer, setAnswer] = useState(undefined);
  const [answerContent, setAnswerContent] = useState("");
  useEffect(() => {
    httpclient
      .getRecruitmentQuestionAnswerByQuestion(questionId)
      .then((answer) => {
        if (answer) {
          setAnswer(answer);
          setAnswerContent(answer.content.content);
        } else {
          setAnswer(null);
        }
      });
  }, [questionId]);

  return (
    <RecruitmentQuestionPageContext.Provider
      value={{
        question,
        questionId,
        discussionId,
        search,
        changeSearchAndRedirect,
        collapseSelectedKeys,
        tabSelectedKey,
        deviceType,
        answer,
        answerContent,
        setAnswer,
        setAnswerContent,
      }}
    >
      <RecruitmentQuestionDetail />
    </RecruitmentQuestionPageContext.Provider>
  );
}

function RecruitmentQuestionDetail() {
  const {
    question,
    discussionId,
    search,
    changeSearchAndRedirect,
    tabSelectedKey,
    deviceType,
  } = useContext(RecruitmentQuestionPageContext);
  if (!tabSelectedKey)
    changeSearchAndRedirect((s) => s.set("tabSelect", "discussion"));

  const [mobileEditOpen, setMobileEditOpen] = useState(false);
  const [discussionEditOpen, setDiscussionEditOpen] = useState(false);

  const handleMenuClick = ({ key }) => {
    if (tabSelectedKey !== key)
      changeSearchAndRedirect((s) => s.set("tabSelect", key));
  };

  const handleBackFromDiscussionDetail = () =>
    changeSearchAndRedirect((s) => s.delete("discussionId"));

  const handleDiscussionBtnClick = () => {
    const collapseKeys = (search.get("collapseSelect") || "").split("|");
    !collapseKeys.includes("discussion") &&
      changeSearchAndRedirect((s) =>
        s.set("collapseSelect", [...collapseKeys, "discussion"].join("|"))
      );

    setTimeout(
      () =>
        document
          .querySelector(".recruitment-question-relative-info-collapse")
          ?.scrollIntoView({ behavior: "smooth", block: "start" }),
      500
    );
  };

  return (
    <div className="recruitment-questions">
      <div
        className="recruitment-question-wrapper"
        style={question ? {} : { justifyContent: "center" }}
      >
        {question ? (
          <>
            <RecruitmentQuestionDescription />
            <RecruitmentQuestionRelativeInfoCollapse />
          </>
        ) : (
          <Spin />
        )}
      </div>
      <div className="recruitment-question-discussion-wrapper">
        <Menu
          mode="horizontal"
          selectedKeys={[tabSelectedKey]}
          onClick={handleMenuClick}
        >
          <Menu.Item key="discussion">
            <ReadOutlined />
            &nbsp;讨论区
          </Menu.Item>
          <Menu.Item key="edit-answer">
            <EditOutlined />
            &nbsp;做题笔记
          </Menu.Item>
          <InteractivePointsReminder />
        </Menu>
        <div className="recruitment-question-discussion-content">
          {tabSelectedKey === "edit-answer" && (
            <EditRecruitmentQuestionAnswer />
          )}
          {tabSelectedKey === "discussion" &&
            (discussionId ? (
              <>
                <div className="recruitment-question-discussion-back-btn">
                  <Space
                    className="back-btn-text"
                    onClick={handleBackFromDiscussionDetail}
                  >
                    <ArrowLeftOutlined />
                    <span>返回</span>
                  </Space>
                </div>
                <div className="recruitment-question-discussion-detail-wrapper">
                  <RecruitmentQuestionDiscussionDetail />
                </div>
              </>
            ) : (
              <>
                <div className="recruitment-question-discussions-header">
                  <Button onClick={() => setDiscussionEditOpen(true)}>
                    <EditOutlined />
                    发帖
                  </Button>
                </div>
                <RecruitmentQuestionDiscussions />

                <Drawer
                  title="讨论区"
                  width="100vw"
                  visible={discussionEditOpen}
                  onClose={() => setDiscussionEditOpen(false)}
                  className="recruitment-question-editor-wrapper-drawer"
                >
                  <EditRecruitmentQuestionDiscussion />
                </Drawer>
              </>
            ))}
        </div>
      </div>
      <div className="recruitment-question-answer-edit-btn">
        <div
          className="sidemenu-item-mobile"
          onClick={() => setMobileEditOpen(true)}
        >
          <EditOutlined />
        </div>
      </div>
      <div className="recruitment-question-discussion-btn">
        <div
          className="sidemenu-item-mobile"
          onClick={handleDiscussionBtnClick}
        >
          <CommentOutlined />
        </div>
      </div>
      <Drawer
        title="做题笔记"
        width="100vw"
        visible={deviceType === "mobile" && mobileEditOpen}
        onClose={() => setMobileEditOpen(false)}
        className="recruitment-question-editor-wrapper-drawer"
      >
        <EditRecruitmentQuestionAnswer />
      </Drawer>
      <Drawer
        title="详情"
        width="100vw"
        visible={deviceType === "mobile" && !!discussionId}
        onClose={handleBackFromDiscussionDetail}
        className="view-recruitment-question-discussion-drawer"
      >
        <RecruitmentQuestionDiscussionDetail />
      </Drawer>
    </div>
  );
}

function RecruitmentQuestionDescription() {
  const { question } = useContext(RecruitmentQuestionPageContext);

  return (
    <div>
      <div>
        <Space size="small">
          <Tag>{question.difficulty}</Tag>
          <Tag>{question.format}</Tag>
        </Space>
      </div>
      <div style={{ marginTop: 16 }}>
        <h3>题面</h3>
        <RichText
          style={{ margin: "0 16px" }}
          richText={question.content.description}
        />
      </div>
      {(question.format === "单选" || question.format === "多选") && (
        <div style={{ marginTop: 16 }}>
          <h3>选项</h3>
          {question.content.choices.map((choice, index) => (
            <div key={index}>
              <Space style={{ margin: "0 16px" }}>
                <b>{String.fromCharCode(65 + index)}.</b>
                <RichText richText={choice} />
              </Space>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

function RecruitmentQuestionRelativeInfoCollapse() {
  const {
    question,
    deviceType,
    collapseSelectedKeys,
    changeSearchAndRedirect,
  } = useContext(RecruitmentQuestionPageContext);

  const [discussionEditOpen, setDiscussionEditOpen] = useState(false);

  const hadleChange = (keys) =>
    changeSearchAndRedirect((s) => s.set("collapseSelect", keys.join("|")));

  return (
    <Collapse
      className="recruitment-question-relative-info-collapse"
      activeKey={collapseSelectedKeys}
      onChange={hadleChange}
      style={{ marginTop: 64 }}
    >
      <Collapse.Panel key="label" header="相关标签">
        <Space>
          {question.tags.map((tag) => (
            <Tag key={tag.id}>{tag.name}</Tag>
          ))}
        </Space>
      </Collapse.Panel>
      {deviceType === "mobile" && (
        <Collapse.Panel
          key="discussion"
          header="讨论区"
          extra={<InteractivePointsReminder />}
        >
          <div>
            <div style={{ textAlign: "center" }}>
              <Button onClick={() => setDiscussionEditOpen(true)}>
                <EditOutlined />
                发帖
              </Button>
            </div>
            <Drawer
              title="讨论区"
              width="100vw"
              visible={discussionEditOpen}
              onClose={() => setDiscussionEditOpen(false)}
              className="recruitment-question-editor-wrapper-drawer"
            >
              <EditRecruitmentQuestionDiscussion />
            </Drawer>
            <RecruitmentQuestionDiscussions />
          </div>
        </Collapse.Panel>
      )}
      {!!question.solution?.analysis && (
        <Collapse.Panel key="hints" header="提示">
          <RichText
            style={{ margin: "0 16px" }}
            richText={question.solution.analysis}
          />
        </Collapse.Panel>
      )}
      {!!question.solution?.answer && (
        <Collapse.Panel key="answer" header="参考答案">
          {question.format === "解答" ? (
            <RichText
              style={{ margin: "0 16px" }}
              richText={question.solution.answer}
            />
          ) : (
            <div style={{ margin: "0 16px" }}>
              {(question.format === "单选"
                ? [question.solution.answer]
                : question.solution.answer
              ).map((choice) => (
                <b key={choice}>{String.fromCharCode(65 + choice)}</b>
              ))}
            </div>
          )}
        </Collapse.Panel>
      )}
    </Collapse>
  );
}

function EditRecruitmentQuestionDiscussion() {
  const { questionId } = useContext(RecruitmentQuestionPageContext);
  const [discussion, setDiscussion] = useState(undefined);
  const [form] = Form.useForm();
  useEffect(() => {
    httpclient
      .getRecruitmentQuestionDiscussionDraftByQuestion(questionId)
      .then((discussion) => {
        if (discussion) {
          setDiscussion(discussion);
        } else {
          setDiscussion(null);
        }
      });
  }, [questionId]);
  const save = async () => {
    return form
      .validateFields()
      .then(() =>
        httpclient.saveRecruitmentQuestionDiscussionDraft(
          questionId,
          form.getFieldValue("type"),
          form.getFieldValue("title"),
          {
            type: "HTML",
            content: form.getFieldValue("content"),
          }
        )
      )
      .catch((err) => {
        const firstErrorFieldName = err?.errorFields[0]?.name;
        !!firstErrorFieldName && form.scrollToField(firstErrorFieldName);
        throw err;
      });
  };

  const publish = async () => {
    const discussion = await save();
    await httpclient.publishRecruitmentQuestionDiscussionDraft(discussion.id);
    setDiscussion(null);
    form.resetFields();
  };

  return discussion === undefined ? (
    <div
      style={{
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Spin />
    </div>
  ) : (
    <Form
      className="recruitment-question-editor-wrapper"
      scrollToFirstError
      initialValues={{ ...discussion, content: discussion?.content?.content }}
      form={form}
      requiredMark={false}
    >
      <FormItem
        name="type"
        label="请选择帖子类型"
        rules={[{ required: true, message: "请选择类型" }]}
      >
        <Select
          placeholder="请选择"
          options={[
            { label: "疑惑", value: "疑惑" },
            { label: "题解", value: "题解" },
          ]}
        />
      </FormItem>
      <FormItem
        name="title"
        label="标题"
        rules={[{ required: true, message: "请输入标题" }]}
      >
        <Input placeholder="请输入标题" />
      </FormItem>
      <FormItem
        name="content"
        rules={[{ required: true, message: "请输入正文" }]}
        className="recruitment-question-editor-wrapper-editor"
      >
        <CKEditorAntdFormItem />
      </FormItem>
      <div className="recruitment-question-editor-wrapper-footer">
        <Space>
          <Button
            onClick={() => save().then(() => message.success("保存成功"))}
          >
            暂存
          </Button>
          <Button
            type="primary"
            onClick={() => publish().then(() => message.success("发布成功"))}
          >
            发布
          </Button>
        </Space>
      </div>
    </Form>
  );
}

function EditRecruitmentQuestionAnswer() {
  const { questionId, answer, answerContent, setAnswerContent } = useContext(
    RecruitmentQuestionPageContext
  );
  const save = () =>
    httpclient.saveRecruitmentQuestionAnswer(questionId, {
      type: "HTML",
      content: answerContent,
    });

  return (
    <div
      className="recruitment-question-editor-wrapper"
      style={
        answer === undefined
          ? { display: "flex", justifyContent: "center", alignItems: "center" }
          : {}
      }
    >
      {answer === undefined ? (
        <Spin />
      ) : (
        <>
          <div className="recruitment-question-editor-wrapper-editor">
            <CKEditorReact
              onChange={(_, editor) => setAnswerContent(editor.getData())}
              data={answerContent}
            />
          </div>
          <div className="recruitment-question-editor-wrapper-footer answer">
            <Space>
              <Button
                onClick={() => save().then(() => message.success("保存成功"))}
                disabled={!answerContent}
              >
                保存笔记
              </Button>
            </Space>
          </div>
        </>
      )}
    </div>
  );
}

function RecruitmentQuestionDiscussions() {
  const { questionId, changeSearchAndRedirect } = useContext(
    RecruitmentQuestionPageContext
  );
  const [discussions, setDiscussions] = useState(null);
  useEffect(() => {
    httpclient
      .getAllRecruitmentQuestionPublicDiscussionsByQuestion(questionId)
      .then((discussions) => setDiscussions(discussions));
  }, [questionId]);

  return (
    <div
      style={{
        height: "100%",
        padding: "12px 16px 0 16px",
        ...(!discussions && {
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }),
      }}
    >
      {!discussions && <Spin />}
      {discussions && discussions.length === 0 && (
        <Empty description="暂时还有人发帖讨论，点击“发帖”按钮发布第一个讨论帖吧！" />
      )}
      {discussions && discussions.length > 0 && (
        <List
          itemLayout="vertical"
          dataSource={discussions}
          renderItem={(item) => (
            <List.Item
              onClick={() =>
                changeSearchAndRedirect((s) => s.set("discussionId", item.id))
              }
              style={{ cursor: "pointer" }}
              actions={[
                <Space
                  key="comment"
                  style={{ fontWeight: "normal", color: "grey" }}
                >
                  <MessageOutlined />
                  {item.related_comments_count}
                </Space>,
                <Space
                  key="like"
                  style={{ fontWeight: "normal", color: "grey" }}
                >
                  <LikeButton
                    count={item.likes_count}
                    liked={item.liked}
                    id={item.id}
                  />
                </Space>,
              ]}
            >
              <List.Item.Meta
                avatar={<UserAvatar avatar={item.user?.avatar} />}
                title={
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Space>
                      {item.title}
                      {item.type === "题解" && <Tag color="purple">题解</Tag>}
                      {item.type === "疑惑" && <Tag color="orange">疑惑</Tag>}
                      {item.type === "疑惑" && !!item.best_answer_record && (
                        <Tag color="green">已采纳</Tag>
                      )}
                    </Space>
                  </div>
                }
                description={
                  <Typography.Paragraph
                    ellipsis={{ rows: 3 }}
                    style={{ color: "grey", marginBottom: 0 }}
                  >
                    {item.preview}
                  </Typography.Paragraph>
                }
              />
            </List.Item>
          )}
        />
      )}
    </div>
  );
}

function RecruitmentQuestionDiscussionDetail() {
  const { discussionId } = useContext(RecruitmentQuestionPageContext);
  const [discussion, setDiscussion] = useState(undefined);
  const fetchDiscussion = useCallback(
    () =>
      !!discussionId &&
      httpclient
        .getOneRecruitmentQuestionPublicDiscussionById(discussionId)
        .then((s) => setDiscussion(s)),
    [discussionId]
  );
  useEffect(() => {
    fetchDiscussion();
  }, [fetchDiscussion]);

  return discussion === undefined ? (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
      }}
    >
      <Spin />
    </div>
  ) : (
    <div className="recruitment-question-discussion-detail">
      <div className="recruitment-question-discussion-detail-main">
        <h2>{discussion.title}</h2>
        <RichText richText={discussion.content} />
        <div className="recruitment-question-discussion-detail-comment">
          <Comments
            data_type="recruitment_question_discussion"
            data_id={discussionId}
            decorators={[
              ...(discussion.type === "疑惑"
                ? [
                    {
                      Component: BestAnswerDecorator,
                      props: {
                        authorId: discussion.user_id,
                        refresh: fetchDiscussion,
                        bestAnswerCommentId:
                          discussion.best_answer_record?.comment_id,
                      },
                    },
                  ]
                : []),
              {
                Component: AuthorDecorator,
                props: { authorId: discussion.user_id },
              },
              {
                Component: ScrollAndHighlightDecorator,
                props: { searchKey: "scrollToDiscussionComment" },
              },
            ]}
            onChange={fetchDiscussion}
          />
        </div>
      </div>
      <div className="recruitment-question-discussion-detail-action">
        <Space size="middle">
          <Space
            className="recruitment-question-discussion-action-item"
            onClick={() =>
              document
                .querySelector(
                  ".recruitment-question-discussion-detail-comment"
                )
                ?.scrollIntoView({ behavior: "smooth", block: "start" })
            }
          >
            <CommentOutlined />
            {discussion.related_comments_count}
          </Space>
          <LikeButton
            count={discussion.likes_count}
            liked={discussion.liked}
            id={discussionId}
          />
        </Space>
      </div>
    </div>
  );
}

function LikeButton({ count, liked, id }) {
  const [localLiked, setLocalLiked] = useState(liked);

  const toggleLike = (e) => {
    e.preventDefault();
    e.stopPropagation();
    localLiked
      ? httpclient.cancelLike("recruitment_question_discussion", id)
      : httpclient.addLike("recruitment_question_discussion", id);
    setLocalLiked(!localLiked);
  };

  return (
    <Space
      className="recruitment-question-discussion-action-item"
      onClick={toggleLike}
    >
      {localLiked ? <LikeFilled /> : <LikeOutlined />}
      {localLiked === liked ? count : localLiked ? count + 1 : count - 1}
    </Space>
  );
}

export default RecruitmentQuestionDetailPage;
