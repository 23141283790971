import EditButton from '../../../../../components/EditButton/EditButton';
import './ResumeItem.scss'
import { NavLink } from 'react-router-dom';

function ResumeItem(props) {
  const editUrl = props.title == '个人信息' ? '/user/center/my-resume/info' :
                  props.title == '教育背景' ? '/user/center/my-resume/education' :
                  props.title == '项目经历' ? '/user/center/my-resume/project' :
                  props.title == '个人评价' ? '/user/center/my-resume/assessment' :
                  null;

  return (
    <div className="resume-item-wrapper">
      <NavLink to={editUrl}>
        <EditButton />
      </NavLink>
      {props.title &&
        <div className="resume-item-title">
          {props.title}
        </div>
      }
      <div className="resume-item-content">
        {props.children || 
          <div style={{fontSize: '16px', color: 'rgb(185, 180, 180)'}}>
            暂无
          </div>
        }
      </div>
    </div>
  )
}

export default ResumeItem;