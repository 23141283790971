import React, {useContext, useEffect, useState} from "react";
import { useParams} from "react-router-dom";
import httpclient from "../../service/httpclient";
import './discuss-detail.scss';
import DiscussItem from "../../components/DiscussList/discuss-item";
import {cloneDeep} from "lodash";
import {UserContext} from "../../App";

function DiscussDetail(){
    const discussId = useParams().discussid;
    const [discussData,setDiscussData] = useState(null);
    const {userInfo, updateUserInfo} = useContext(UserContext);
    const updateDiscussData = (index,action) => {
        let newState = {...discussData};
        if(action === 'like'){
            newState.likes++;
            newState.liked = true;
            httpclient.addDiscussLikes(newState.id);
        }else{
            newState.likes--;
            newState.liked = false;
            httpclient.delDiscussLikes(newState.id);
        }
        setDiscussData(newState);
    }
    useEffect(() => {
        httpclient.getDiscussDetailData(discussId).then(res => {
            if(res){
                setDiscussData(res);
            }
        })
    },[discussId]);
    return(
        <div className="discuss-detail">
            <div className="discuss-detail-wrapper">
                {discussData && <DiscussItem discussItem={cloneDeep(discussData)} index={0} options={{showDetail:false}}  updateDiscussData={updateDiscussData} userInfo={userInfo} updateUserInfo={updateUserInfo}/>}
            </div>
        </div>
    )
}

export default DiscussDetail;
