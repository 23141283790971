import React, { useState } from "react";
import httpclient from "../../service/httpclient";
import "./user.scss";
import { notification, Checkbox } from "antd";
import { Countdown } from "react-countdown-hoc/build";
import ScopeJudge from "../../components/scopejudge";
import { Input, Select } from "antd";
import PubSub from "pubsub-js";
import { WechatOutlined } from "@ant-design/icons";
import {smsCodeData} from "../../service/sms";

const { Option } = Select;

class SmsCodeSelector extends React.Component{
    render() {
        return <Select value={this.props.value} className="select-before" onChange={e=>this.props.onChange(e)}>
            {smsCodeData.map(item => <Option value={item.code} key={item.code}>{item.name} {item.code}</Option>)}
        </Select>
    }
}

function Login() {
    const[loading,setLoading] = useState(false);
  const [mobile, setMobile] = useState(null);
  const [smsCode, setSmsCode] = useState("+86");
  const [smsRequired, setSmsRequired] = useState(false);
  const [policyChecked, setPolicyChecked] = useState(false);
  const [smsVerificationCode, setSmsVerificationCode] = useState(null);

  function getMobile() {
      if(mobile == null){
          return null;
      }

      if (smsCode.trim() === "+86") {
          return mobile.trim();
      } else {
          return smsCode.trim() + mobile.trim();
      }
  }

  function doLogin() {
    httpclient
      .Login(getMobile(), smsVerificationCode, ScopeJudge())
      .then((response) => {
        if (response) {
          localStorage.setItem("token",response.token);
        //  localStorage.token = response.token;
          httpclient.getUserInfo().then((response) => {
           // localStorage.note_token = response.note_token;
            localStorage.setItem("note_token",response.note_token);
          })
          let msg = {"data":true}
          PubSub.publish("loggedIn",msg);
          notification.info({
            description: "欢迎，" + getMobile(),
            message: "登录成功",
          });
          setTimeout(function () {
            if (window.loginRedirectUrl) {
              window.location = window.loginRedirectUrl;
              window.loginRedirectUrl = null;
            } else {
              window.location = "/";
            }
          }, 1000);
        }
      });
  }

  return (
    <div className="login-wrapper">
      <div className="logo">
        <img src="https://cdn.datongbei.com/icdesign-logo.png" alt="logo" />
      </div>
      <div className="user-frame">
        <div className="user-title">登录/注册</div>
        {smsRequired ?
          <>
            <div className="user-input sms">
              <Input maxLength={6} key="sms" placeholder="请输入验证码" onChange={(e) => setSmsVerificationCode(e.currentTarget.value)} bordered={false} />
              <Countdown intervalSecond={1} duration={80} autoTrigger={true}>
                {(isCounting, restTime, startCount) => (
                  <>
                    {isCounting ? (
                      <span className="disabled-button">
                        重新发送({restTime}s)
                      </span>
                    ) : (
                      <span
                        className="button"
                        onClick={() => {
                          httpclient.getVerificationCode(getMobile()).then((response)=>{
                              console.log(response);
                            startCount();
                          });
                        }}
                      >
                        获取验证码
                      </span>
                    )}
                  </>
                )}
              </Countdown>
            </div>
            <div className="user-button-wrapper">
              <button className="user-long-button" onClick={() => doLogin()}>
                登录
              </button>
            </div>
          </>
          :
          <>
            <div className="user-input mobile">
                <Input key="mobile" addonBefore={<SmsCodeSelector value={smsCode} onChange={value=>setSmsCode(value)}/>} placeholder="请输入手机号" bordered={false} onChange={(e) => setMobile(e.currentTarget.value)} />
            </div>
            {!loading ? <button
              className="user-long-button"
              onClick={() => {
                // if(policyChecked){
                  setLoading(true);
                  httpclient.getVerificationCode(getMobile()).then(()=>{
                      setLoading(false);
                    setSmsRequired(true);
                  })
                      .catch(() => {
                          setLoading(false);
                      });
                // }
              }}
            >
              获取验证码
            </button> :
                <button
                    className="user-long-button-loading"
                >
                    获取验证码
                </button>
            }
            {/* <div className="user-policy">
              <Checkbox style={{color:'#9d9d9d'}} onChange={(e) => setPolicyChecked(e.target.checked)}>
                我已阅读并同意IC Design
                <a href="/">《用户协议》</a>
              </Checkbox>
            </div> */}
          </>
        }
        {/* <div className="third-party-login">
          —更多登录方式—
          <div className="third-party-links">
            <div className="wechat circle">
              <WechatOutlined />
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
}

export default Login;
