import React from "react";
import {LeftOutlined} from "@ant-design/icons";
import { Switch } from 'antd';
import './recruit_settings.scss';
import {NavLink} from "react-router-dom";
import httpclient from "../../../../../../service/httpclient";

function RecruitSettings(){
    return(
        <div className="recruit-settings">
            <NavLink className="recruit-back" to='/user/center/recruit' >
                <LeftOutlined />  <span>返回</span>
            </NavLink>
            <div className="recruit-settings-list">
                <div className="recruit-settings-item">
                    <span>是否关闭「我的招聘」功能</span>
                    <span className="recruit-settings-item-switch"><Switch onChange={(checked) => {if(!checked){httpclient.closeRecruit().then(() => {window.location.href = '/user/center/recruit'})}}} defaultChecked size='small'/></span>
                </div>
                {/*<div className="recruit-settings-item">*/}
                {/*    <span>是否关闭「我的招聘」中的消息功能</span>*/}
                {/*    <span className="recruit-settings-item-switch"><Switch defaultChecked size='small'/></span>*/}
                {/*</div>*/}
                {/*<div className="recruit-settings-item">*/}
                {/*    <span>是否向HR直接提供我的联系方式</span>*/}
                {/*    <span className="recruit-settings-item-switch"><Switch defaultChecked size='small'/></span>*/}
                {/*</div>*/}
            </div>
        </div>
    )
}

export default RecruitSettings;