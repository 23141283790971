import React from "react";
import './missions-list.scss';
import {NavLink} from "react-router-dom";

function MissionsList(data){
    return data.map(item => (
        <div className="mission-item-wrapper">
            <div className="mission-title-wrapper">
                <div className="mission-title">{item.title}</div>
                <div className="mission-description">{item.description}</div>
            </div>
            <NavLink to={item.link} className="mission-button">去完成</NavLink>
        </div>
    ))
}

export default MissionsList;
