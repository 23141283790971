import { Avatar } from "antd";
import { UserOutlined } from "@ant-design/icons";

function UserAvatar({ avatar }) {
  return (
    <Avatar
      size={{
        xs: 32,
        sm: 40,
        md: 40,
        lg: 48,
        xl: 48,
        xxl: 48,
      }}
      {...(avatar ? { src: avatar } : { icon: <UserOutlined /> })}
    />
  );
}

export default UserAvatar;
