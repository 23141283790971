import React, {useEffect, useState} from "react";
import "./gift.scss";
import httpclient from "../../service/httpclient";
import { useParams } from "react-router";
import { Modal} from 'antd';
import {NavLink} from "react-router-dom";



function Gift(){
    const token = useParams().token;
    const [giftData,setGiftData]  = useState(null);
    const [giftStatus,setGiftStatus] = useState(null);
    useEffect(() => {
        httpclient.getGiftData(token).then((res) => {
            if(res){
                setGiftData(res);
                setGiftStatus(res.status);
            }
        })
    },[token]);
    return(
       <div className="gift-wrapper">
           {!!giftData && <div className="gift">
                <div className="gift-box">
                    <div className="gift-zhuanlan">
                        <img className="gift-zhuanlan-img" src={giftData.zhuanlan.image}/>
                        <div className="gift-zhuanlan-info">
                            <div className="gift-presenter">
                                <span style={{color:'#5995DA'}}>{giftData.user}</span>赠送给你一篇付费文章
                            </div>
                           <div className="gift-zhuanlan-name">
                               {giftData.zhuanlan.name}
                           </div>
                            <div className="gift-chapter-name">
                                {giftData.chapter.name}
                            </div>

                        </div>
                    </div>
                    <div className="gift-noti">
                        <div style={{fontSize:'14px',color:'#ACC8DC',marginBottom:'10px'}}>规则说明</div>
                        <div>1.用户可以在登录后免费领取试读文章，每位用户只可领取相同文章一次，若已拥有该文章则无法领取。</div>
                        <div>2.领取成功后，可直接点击去阅读跳转至试读文章，或通过右上角个人中心页面找到赠送文章。</div>
                    </div>
                </div>
               {giftStatus === 'unclaimed' ?
                   <div className="gift-button-unclaimed" onClick={() => {
                       httpclient.receiveGift(token).then(() => {
                           Modal.success({
                               title:'领取成功',
                               okText: "确定",
                                onOk: function (){
                                    setGiftStatus('claimed');
                                }
                           })
                       });
               }
               }>
                   领取文章
               </div>:
                   <div>
                       <div className="gift-button-claimed">礼物已领取</div>
                       <span className="reading">直接<NavLink to={'/chapters/'+giftData.chapter.id} style={{color:'#5995DA'}} >去阅读</NavLink></span>
                   </div>


               }
            </div>}
        </div>
    )
}

export default Gift;