import React from "react";
import './discuss-draft-list.scss';
import {NavLink} from "react-router-dom";
import httpclient from "../../service/httpclient";
import {notification} from "antd";

function DiscussDraftList(discussDraftData){
    return discussDraftData.map((item) => (
      <div className="discuss-draft-item-wrapper">
        <div className="discuss-draft-top-wrapper">
          <div className="discuss-draft-title">
            {item.title}
              {item.tag && <span className="discuss-draft-tag">#{item.tag}</span>}
            <span style={{fontSize:"14px",color:"darkgrey"}}>(草稿)</span>
          </div>
            <div style={{display:'flex'}}>
                <div className="discuss-draft-action">
                    <NavLink to={`/discuss-submit/edit/draft/${item.id}`} className="discuss-draft-edit">编辑</NavLink>
                </div>
                <div className="discuss-draft-action">
                    <div className="discuss-draft-delete" onClick={() => {httpclient.deleteDiscussDraft(item.id).then(res =>{
                        if(res){
                            notification.success({
                                message: "删除成功",
                            });
                            setTimeout(() => {
                                window.location.href = "/user/center/my-discuss?selected=draft";
                            }, 1000);
                        }
                    })}}>删除</div>
                </div>
            </div>

        </div>
        <div
          className="discuss-draft-content"
          dangerouslySetInnerHTML={{
            __html: item.html,
          }}
        />
      </div>
    ));
}

export default DiscussDraftList;
