import React, {useContext, useEffect, useState} from "react";
import "./checkout.scss";
import { useParams } from "react-router";
import httpclient from "../../service/httpclient";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { faAlipay, faWeixin } from "@fortawesome/free-brands-svg-icons";
import {Input, Modal, notification, Tooltip} from "antd";
import OrderService from "../../service/orderservice";
import CouponCard from "../../components/CouponCard/coupon-card";
import {CouponStatus} from "../user_center/contents/my_coupons/coupon-enums";
import {LoadingOutlined, QuestionCircleOutlined} from "@ant-design/icons";
import {isNull} from "lodash";

import {UserContext} from "../../App";

function CheckOut(props) {
  const {userInfo,updateUserInfo} = useContext(UserContext);
  const [testpaperData, setTestpaperData] = useState(null);
  const [testpaperCollectionData, setTestpaperCollectionData] = useState(null);
  const [zhuanlansData,setZhuanlansData] = useState(null);
  const [categoriesData,setCategroiesData] = useState(null);
  const [paymentChoice, setPaymentChoice] = useState("wechat");
  const [renewSelect,setRenewSelect] = useState(0);
  const [couponsData,setCouponsData] = useState(null);
  const [selectedCoupons,setSelectedCoupons] = useState([]);
  const [calculatedPrice,setCalculatedPrice] = useState(null);
  const [isFirstPurchase,setIsFirstPurchase] = useState(false);
  const [recommendCode,setRecommendCode] = useState(null);
  const [isRecommend,setIsRecommend] = useState(null);
  const dataid = useParams().dataid;
  const datatype = useParams().datatype;
  const mode = useParams().mode;
  const orderservice = new OrderService();
  const [priceData,setPriceData] = useState(null);
  const renewMode={
    '0': 1,
    '1': 2,
    '2': 3
  }
  const handleSelectCoupon = (array) => {
    setSelectedCoupons(array)
  }

  const checkRecommendCode = (code) =>{
    if(code.length === 6 && isFirstPurchase){
     setRecommendCode(code)
    }
    if(code.length === 0){
      setRecommendCode(null)
      setIsRecommend(null)
    }
  }
  useEffect(() => {
    setCalculatedPrice(null)
    httpclient.getCalculatedPrice(dataid,datatype,mode === 'renew' ? renewMode[renewSelect] : null,selectedCoupons,recommendCode).then((res) => {
      if(res?.price !== null){
        setCalculatedPrice(res?.price)
      }
      if(res?.isRecommend !== null){
        setIsRecommend(res?.isRecommend)
        if(res?.isRecommend === true && isFirstPurchase && selectedCoupons.length > 0){
          setSelectedCoupons([]);
        }
      }
    })
  },[selectedCoupons,dataid,renewSelect,recommendCode])
  useEffect(() => {
    httpclient.getCoupons().then((res) => {
      if(res){
        setCouponsData(res)
      }
    })
    httpclient.checkIsFirstPurchase().then(res => {
      setIsFirstPurchase(res?.result)
    })
  },[])
  useEffect(() => {
    if (datatype === "testpaper_collection") {
      httpclient.getTestPaperCollectionData(dataid).then((response) => {
        setTestpaperCollectionData(response);
        let pricedata = [
          response.price_1_month,
          response.price_6_month,
          response.price_12_month
        ]
        setPriceData(pricedata);
      });
    } else if(datatype === "testpaper"){
      httpclient.getTestPaperData(dataid).then((response) => {
        setTestpaperData(response.testpaper);
      });
    }else if(datatype === "zhuanlans"){
      httpclient.getZhuanlansData(dataid).then((response) => {
        setZhuanlansData(response);
        let pricedata = [
          response.zhuanlan.price_1_month,
          response.zhuanlan.price_6_month,
          response.zhuanlan.price_12_month
        ]
        setPriceData(pricedata);
      })
    }else {
      httpclient.getCategoriesData(dataid).then((response) => {
        setCategroiesData(response);
        let pricedata = [
            response.category.price_1_month,
            response.category.price_6_month,
            response.category.price_12_month
        ]
        setPriceData(pricedata);
      })
    }
  }, [dataid]);
  function doBuy(id) {
      if (datatype === "testpaper_collection") {
      httpclient.possessTestpaperCollection(id,false).then((response) => {
        if(response){
          if (response.result === true && mode !== 'renew') {
            Modal.info({
              content: "您已购买此试卷集合",
              okType: "ghost",
              okText: "确定",
              onOk: function () {
                window.location = `/${datatype}/${dataid}`;
              },
            });
          }else if((mode === 'renew' && (response.time_expired || response.result === true)) || mode !== 'renew'){
            httpclient.deleteOpenid().then(() => {
              orderservice.order({id: id, type: datatype, renew_price_mode: mode === 'renew' ? renewMode[renewSelect] : null,coupons:selectedCoupons,recommend_code:isRecommend ? recommendCode : null},{jumpBackUrl:`/${datatype}/${dataid}`})
                  .then()
            })
          }else{
            Modal.info({
              content: "您尚未购买过此试卷集合，没有续费资格",
              okType: "ghost",
              okText: "确定",
              onOk: function () {
                window.location = `/checkout/${datatype}/${dataid}`;
              },
            });
          }
        }

      });
    } else if(datatype === "testpaper"){
      httpclient.possessTestpaper(id).then((response) => {
        if(response){
          if (response.result === true && mode !== 'renew') {
            Modal.info({
              content: "您已购买此试卷",
              okType: "ghost",
              okText: "确定",
              onOk: function () {
                window.location = `/${datatype}/${dataid}`;
              },
            });
          } else {
            httpclient.deleteOpenid().then(() => {
              orderservice.order({id: id, type: datatype,coupons:selectedCoupons,recommend_code:isRecommend ? recommendCode : null},{jumpBackUrl:`/${datatype}/${dataid}`})
                  .then()
            })
          }
        }
      });
    }else if(datatype === "zhuanlans"){
      httpclient.possessZhuanlan(id,false).then((response) => {
        if(response){
          if (response.result === true && mode !== 'renew') {
            Modal.info({
              content: "您已购买此专栏",
              okType: "ghost",
              okText: "确定",
              onOk: function () {
                window.location = `/${datatype}/${dataid}`;
              },
            });
          }else if((mode === 'renew' && (response.time_expired || response.result === true)) || mode !== 'renew'){
            httpclient.deleteOpenid().then(() => {
              orderservice.order({id: id, type: datatype, renew_price_mode: mode === 'renew' ? renewMode[renewSelect] : null,coupons:selectedCoupons,recommend_code:isRecommend ? recommendCode : null},{jumpBackUrl:`/${datatype}/${dataid}`})
                  .then()
            })
          }else{
            Modal.info({
              content: "您尚未购买过此专栏，没有续费资格",
              okType: "ghost",
              okText: "确定",
              onOk: function () {
                window.location = `/checkout/${datatype}/${dataid}`;
              },
            });
          }
        }

      });
    }else if(datatype === "categories"){
      httpclient.possessCategory(id,false).then((response) => {
        if(response){
          if (response.result === true && mode !== 'renew') {
            Modal.info({
              content: "您已购买此合集",
              okType: "ghost",
              okText: "确定",
              onOk: function () {
                window.location = `/${datatype}/${dataid}`;
              },
            });
          }else if((mode === 'renew' && (response.time_expired || response.result === true)) || mode !== 'renew'){
            httpclient.deleteOpenid().then(() => {
              orderservice.order({id: id, type: datatype, renew_price_mode: mode === 'renew' ? renewMode[renewSelect] : null,coupons:selectedCoupons,recommend_code:isRecommend ? recommendCode : null},{jumpBackUrl:`/${datatype}/${dataid}`})
                  .then()
            })
          }else{
            Modal.info({
              content: "您尚未购买过此合集，没有续费资格",
              okType: "ghost",
              okText: "确定",
              onOk: function () {
                window.location = `/checkout/${datatype}/${dataid}`;
              },
            });
          }
        }

      });
    }
  }
  return (
    <div className="checkout-wrapper">
      <img className="fab fa-alipay"></img>
      <div className="checkout">
        <div className="checkout-title">支付{mode === 'renew' && <span>（续费）</span>}</div>
        <div className="section">
          {!!testpaperData && (
            <div className="testpaper-info">
              <div className="info-title">试卷信息</div>
              <span className="testpaper-title">{testpaperData.title}</span>
              <span className="testpaper-price">价格: {testpaperData.price/100}</span>
            </div>
          )}
          {!!testpaperCollectionData && (
            <div className="testpaper-collection-info">
              <div className="info-title">试卷集合信息</div>
              <span className="testpaper-title">
                {testpaperCollectionData.name}
              </span>
              <span className="testpaper-price">
                {mode === 'renew' ? <span>原</span> : <span>总</span>}价: {testpaperCollectionData.price/100}
              </span>
              <div className="testpaper-collection-intro">
                {testpaperCollectionData.introduction_text}
              </div>
            </div>
          )}
          {!!zhuanlansData &&
          <div className="zhuanlan-info">
            <div className="info-title">专栏信息</div>
            <span className="testpaper-title">
                {zhuanlansData.zhuanlan.name}
              </span>
            <span className="testpaper-price">
                {mode === 'renew' ? <span>原</span> : <span>总</span>}价: {zhuanlansData.zhuanlan.price/100}
              </span>
            <div className="testpaper-collection-intro">
              {zhuanlansData.zhuanlan.introduction_html}
            </div>
          </div>}
          {!!categoriesData &&
          <div className="category-info">
            <div className="info-title">合集信息</div>
            <span className="testpaper-title">
                {categoriesData.category.name}
              </span>
            <span className="testpaper-price">
                 {mode === 'renew' ? <span>原</span> : <span>总</span>}价: {categoriesData.category.price/100}
              </span>
            <div className="testpaper-collection-intro">
              {categoriesData.category.introduction_html}
            </div>
          </div>}
          {mode === 'renew' && priceData &&
            <div className="renew-select">
              <div className="renew-select-title">请选择续费时长</div>
              <div className="renew-select-item-wrapper">
                <div className="renew-select-item" onClick={() => {setRenewSelect(0)}}>一个月 {priceData[0]/100} {renewSelect === 0 && <FontAwesomeIcon icon={faCheck}/>}</div>
                <div className="renew-select-item" onClick={() => {setRenewSelect(1)}}>六个月 {priceData[1]/100} {renewSelect === 1 && <FontAwesomeIcon icon={faCheck}/>}</div>
                <div className="renew-select-item" onClick={() => {setRenewSelect(2)}}>一年 {priceData[2]/100} {renewSelect === 2 && <FontAwesomeIcon icon={faCheck}/>}</div>
              </div>
            </div>
          }
          {mode === 'renew' ? <div className="attention">注意：续费商品有效期为选择的使用期限时长，期限内可反复观看</div>:<div className="attention">
            注意：商品有效期为购买日起之后的一年，一年内可反复观看
          </div>}
        </div>
        <div className="section">
          <div className="payment-wrapper">
            <div className="payment-title">选择支付方式</div>
            <span
              className="wechat-button"
              onClick={() => {
                setPaymentChoice("wechat");
              }}
            >
              微信
              <FontAwesomeIcon className="pay-icon" icon={faWeixin} />
              {paymentChoice === "wechat" && <FontAwesomeIcon icon={faCheck} />}
            </span>
            {/*<span*/}
            {/*  className="alipay-button"*/}
            {/*  onClick={() => {*/}
            {/*    setPaymentChoice("alipay");*/}
            {/*  }}*/}
            {/*>*/}
            {/*  支付宝 <FontAwesomeIcon className="pay-icon" icon={faAlipay} />*/}
            {/*  {paymentChoice === "alipay" && <FontAwesomeIcon icon={faCheck} />}*/}
            {/*</span>*/}
           <div><div className="payment-title" style={{marginTop:'20px',marginBottom:'0'}}>选择优惠券</div>
            {!!couponsData && <CouponCard disable={isRecommend && isFirstPurchase} price={calculatedPrice} dataType={datatype} dataId={dataid} handleClick={handleSelectCoupon} type="checkbox" cardData={couponsData?.filter(item => item.status === CouponStatus.AVAILABLE)}/>}
            {!!isFirstPurchase && <div>
              <div className="payment-title" style={{marginBottom:'10px',display:'flex'}}>引荐优惠 <div style={{marginLeft:'5px'}}><Tooltip title="成功引荐，被引荐人可享5折优惠，引荐人可获得50元抵用券"><QuestionCircleOutlined /></Tooltip></div></div>
              <div  style={{display:'flex'}}>
                引荐码：<Input style={{width:'100px'}} maxLength={6} placeholder='请输入引荐码' onChange={(e) => {checkRecommendCode(e.target.value)}} size='small' />
                {!isNull(isRecommend) && (isRecommend === true ? <div style={{marginLeft:'10px',color:'green',fontSize:'12px',opacity:'0.6'}}>合法引荐码</div> : <div style={{marginLeft:'10px',color:'red',fontSize:'12px',opacity:'0.6'}}>无效引荐码</div>)}
              </div>
              <div style={{fontSize:'12px',color:'darkgrey',marginTop:'10px'}}>注：引荐优惠仅限引荐人已购买过网站商品，且被引荐人为首次购买时才可生效；引荐优惠不可与优惠券同时使用</div>
            </div>}</div>
            <div className="payment-info">
              {calculatedPrice !== null ? `您需要支付￥${calculatedPrice/100}` : <span><LoadingOutlined style={{color:'#5995DA',marginRight:'10px'}}/>正在为您计算价格...</span>}
              {(isFirstPurchase && isRecommend) && <span style={{marginLeft:'10px',color:'green',fontSize:'12px',opacity:'0.6'}}>已享5折优惠，不可使用其他优惠券</span>}
             </div>
            <span
              className="buy-button button-primary"
              onClick={() => {
                doBuy(dataid);
              }}
            >
              确认购买
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CheckOut;
