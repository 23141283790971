import { useModal } from "@ebay/nice-modal-react";
import PlayVideo from "../../modals/PlayVideo";

export default function () {
  const playVideoModal = useModal(PlayVideo);

  return {
    play: function (videoTitle, videoAccessId) {
      playVideoModal.show({
        title: videoTitle,
        videoAccessId: videoAccessId,
      });
    },
  };
}
