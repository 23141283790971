import React from "react";
import {NavLink} from "react-router-dom";
import routeUrls from "../../route/routeurls";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faLock} from "@fortawesome/free-solid-svg-icons";
import "./testpaperlist.scss";

function TestpaperList(TestPaperCollectionData){
    if (TestPaperCollectionData)
        return TestPaperCollectionData.testpapers.map((content) => {
            return (
                <div className="testpaper-item">
                    <NavLink
                        className="testpaper-item-title"
                        to={routeUrls("testpaper", content.id)}
                    >
                        {content.title}
                    </NavLink>
                    {(!content.accessible && content.id !== 2) && (
                        <span
                            className="lock-icon"
                        >
                            <FontAwesomeIcon icon={faLock} />
                        </span>
                    )}
                </div>
            );
        });
}

export default TestpaperList;