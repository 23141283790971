import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleUp, faLock} from "@fortawesome/free-solid-svg-icons";
import Tarray from "../../definition/two-demensional array";
import {Modal} from "antd";
import "./question-list.scss";
import usePlayVideoService from "../../service/hooks/usePlayVideoService";
import httpclient from "../../service/httpclient";

function QuestionList(data) {
  const [answerArray, setAnswerArray] = useState(Tarray);
  const playVideoService = usePlayVideoService();

  if (data) {
    return data.content.map((content, index_type) => {
      const questionitem = content.questions.map((content, index_item) => {
        return (
          <li className="question-item">
            <div
              className="question-content"
              dangerouslySetInnerHTML={{
                __html: content.content,
              }}
            />

            {answerArray[index_type][index_item] ? (
              <div>
                <span
                  className="answer-button"
                  onClick={() => {
                    setAnswerArray((prevState) => {
                      let newState = [...prevState];
                      newState[index_type][index_item] = false;
                      return newState;
                    });
                  }}
                >
                  解答
                  <span className="angle-down">
                    <FontAwesomeIcon icon={faAngleUp} />
                  </span>
                </span>
                  {(content.video_access_id && content.answer) ?
                      <span
                          className="video-button"
                          onClick={() => {
                              playVideoService.play("视频讲解", content.video_access_id);
                          }}
                      >
                  视频讲解
                </span> :
                      <span  onClick={() => {
                          Modal.confirm({
                              content: "请登录并购买后解锁视频讲解",
                              okType: "primary",
                              okText: "购买", cancelText:"取消",
                              onOk: function () {
                                  window.location = data.testpaper_collection_id === -1 ? `/checkout/testpaper/${data.id}` : `/checkout/testpaper_collection/${data.testpaper_collection_id}`;
                              },
                          });}} className="video-button-lock" style={{cursor:"pointer"}}>
                          视频讲解 <FontAwesomeIcon icon={faLock}/>
                      </span>
                  }
                <div className="answer-wrapper">

                    {(content.video_access_id && content.answer) ?
                        <div
                        className="answer"
                        dangerouslySetInnerHTML={{__html: content.answer}}
                    /> :
                        <div>
                            请登录并购买后解锁<strong style={{color:"#5995DA"}}>视频讲解</strong>和<strong style={{color:"#5995DA"}}>解答</strong>
                        </div>
                    }
                </div>
              </div>
            ) : (
              <div>
                <span
                  className="answer-button"
                  onClick={() => {
                    setAnswerArray((prevState) => {
                      let newState = [...prevState];
                      newState[index_type][index_item] = true;
                      return newState;
                    });
                  }}
                >
                  解答
                  <span className="angle-down">
                    <FontAwesomeIcon icon={faAngleDown} />
                  </span>
                </span>
                  {(content.video_access_id && content.answer) ?
                      <span
                  className="video-button"
                  onClick={() => {
                    playVideoService.play("视频讲解", content.video_access_id);
                  }}
                >
                  视频讲解
                </span> :
                      <span  onClick={() => {
                          Modal.confirm({
                          content: "请登录并购买后解锁视频讲解",
                          okType: "primary",
                          okText: "购买", cancelText:"取消",
                          onOk: function () {
                              window.location = data.testpaper_collection_id === -1 ? `/checkout/testpaper/${data.id}` : `/checkout/testpaper_collection/${data.testpaper_collection_id}`;
                          },
                      });}} className="video-button-lock" style={{cursor:"pointer"}}>
                          视频讲解 <FontAwesomeIcon icon={faLock}/>
                      </span>
                  }
              </div>
            )}
            <div className="question-item-line"></div>
          </li>
        );
      });
      return (
        <div className="question-compilation">
          {/*<div className="questions-type">{content.question_type_name}</div>*/}
          {/*<div className="questions-type-line"></div>*/}
          <ul className="questions">{questionitem}</ul>
        </div>
      );
    });
  }
}

export default QuestionList;
