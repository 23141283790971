import React from "react";
import './discuss-notice.scss';
import {UserOutlined,RightOutlined} from "@ant-design/icons";
import {Avatar} from "antd";
import {NavLink} from "react-router-dom";

function DiscussNotice({discussItem}){
    return(
        <NavLink to={`/discuss/detail/${discussItem.id}`} className="discuss-notice">
            <div style={{display:'flex',alignItems:'center'}}>
            <Avatar
                src={discussItem.avatar}
                size={40}
                icon={<UserOutlined />}
            />
            <div className="discuss-notice-title">{discussItem?.title}</div>
            <div className="discuss-notice-right-icon">
                <RightOutlined />
                <RightOutlined />
            </div>
            </div>
            <div className="discuss-notice-tags">
                <div className="discuss-notice-tag">公告</div>
                <div className="discuss-select-tag">置顶</div>
            </div>
        </NavLink>
    )
}

export default DiscussNotice;
