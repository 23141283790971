import { Route, Switch, useParams } from "react-router";
import EducationBackgroundFrom from "../education_background_form/EducationBackgroundForm";
import PersonalInfoFrom from "../personal_info_form/PersonalInfoFrom";
import ProjectExperienceForm from "../project_experience_form/ProjectExperienceForm";
import SelfAssessmentForm from "../self_assessment_form/SelfAssessmentForm";
import './ResumeForm.scss'

function ResumeForm() {
  const part = useParams().part;

  return(
  <>
    <div className="resume-form-title">
      <div className="resume-form-title-label">
        {
          part == 'info' ? '个人信息' : 
          part == 'education' ? '教育背景' : 
          part == 'project' ? '项目经历' : 
          part == 'assessment' ? '个人评价' : 
          null
        }
      </div>
      <div className="resume-form-title-progress">
        <span style={{color: 'rgb(34, 62, 139)'}}>
          {
            part == 'info' ? 1 : 
            part == 'education' ? 2 : 
            part == 'project' ? 3 : 
            part == 'assessment' ? 4 : 
            null
          }
        </span>
        /4
      </div>
    </div>
    <div className="resume-form-content" style={{height: '80%', overflowY: 'auto'}}>
      <Switch>
        <Route path='/user/center/my-resume/info' exact children={<PersonalInfoFrom/>} />
        <Route path='/user/center/my-resume/education' exact children={<EducationBackgroundFrom/>} />
        <Route path='/user/center/my-resume/project' exact children={<ProjectExperienceForm/>} />
        <Route path='/user/center/my-resume/assessment' exact children={<SelfAssessmentForm/>} />
      </Switch>
    </div>
  </>
  );
}

export default ResumeForm;