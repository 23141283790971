import { useEffect, useState } from "react";
import katex from "katex";

function useKaTeX(classname, dep, ref) {
  const [array, setArray] = useState(null);
  useEffect(() => {
    if (!ref) setArray(document.getElementsByClassName(classname));
    else if (!!ref.current)
      setArray(ref.current.getElementsByClassName(classname));
  }, [classname, dep, ref]);
  useEffect(() => {
    if (array) {
      for (let i = 0, len = array.length; i < len; i++) {
        katex.render(
          array[i].innerText
            .replace("\\(", "")
            .replace("\\)", "")
            .replace("\\[", "")
            .replace("\\]", ""),
          array[i],
          {
            throwOnError: false,
          }
        );
      }
    }
  }, [array, dep]);
}

export default useKaTeX;
