export function urlSafeBase64Decode(value) {
  return atob(
    value.split(".").join("=").split("-").join("/").split("_").join("+")
  );
}

export function urlSafeBase64Encode(value) {
  return btoa(value)
    .split("=")
    .join(".")
    .split("/")
    .join("-")
    .split("+")
    .join("_");
}
