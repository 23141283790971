import React, {useEffect, useMemo, useState} from "react";
import "./home.scss";
import httpclient from "../../service/httpclient";
import List from "../../components/lists/list";
import SimpleScrollBar from "../../components/ScrollBar/SimpleScrollBar";

function Home() {
  const [CategoriesData, setCategoriesData] = useState(null);
  const categoriesid = 1;
  useEffect(() => {
    httpclient.getCategoriesData(categoriesid).then((data) => {
      setCategoriesData(data);
    });
  }, []);
  const categorieslist = useMemo(() => {
    if (CategoriesData) return List(CategoriesData.children);
  }, [CategoriesData]);
  return (
    <div className="home">
     <SimpleScrollBar>
      <div className="home-wrapper">
        <img className="home-logo" src="https://cdn.datongbei.com/icdesign-logo.png" alt="icdesign" />
        <div className="slogan-wrapper">
          <div className="line"></div>
          <div className="slogan">
            &#123; upon this rock, we will build our world &#125;
          </div>
        </div>
      </div>
      <div className="lists-wrapper">{categorieslist}</div>
     </SimpleScrollBar>
    </div>
  );
}

export default Home;
